import moment from "moment";
import { RootState } from "../../rootReducers";
import { ScheduleReducerType } from "./reducers";
import { getDoctorScheduleAsync } from "./actions";
import paramsFromObject from "../../../helpers/paramsFromObject";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { Schedule, ScheduleDayTime } from "../../../interfaces/Schedule";
import { ErrorResponse, ResponseBasic } from "../../../interfaces/Response";
import { apiGet, API_URL_SCHEDULE, retrieveToken } from "../../../helpers/api";

interface asyncResponse extends ResponseBasic { data: Schedule[] };

function* func(action: ReturnType<typeof getDoctorScheduleAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    const searchQuery = `${API_URL_SCHEDULE.GET_SCHEDULE}?${paramsFromObject(action.payload)}`;
    const result: Response = yield call(apiGet, searchQuery, token);
    
    if (result.status === 200) {
      const newScheduleDayTime: ScheduleDayTime[] = [];
      const obtainedScheduleDayTime: ScheduleDayTime[] = [];
      const scheduleStateGetter = (state: RootState) => state.schedule;
      const scheduleState: ScheduleReducerType = yield select(scheduleStateGetter);
      const parsed: asyncResponse = yield call(result.json.bind(result));
      const { selectedYear, selectedMonth } = scheduleState;

      // group data by date
      parsed.data.forEach((item) => {
        const { start_date, end_date } = item;
        const date = parseInt(moment(start_date).format('DD'));
        if (date) {
          const timestamp = {
            start: moment(start_date).format('HH:mm:ss'),
            end: moment(end_date).format('HH:mm:ss')
          };
          const existingDay = obtainedScheduleDayTime.findIndex(i => i.day === date);
          if (existingDay > -1) {
            obtainedScheduleDayTime[existingDay].timestamp.push(timestamp)
          } else {
            const tempScheduleDayTime: ScheduleDayTime = {
              day: date,
              timestamp: [
                {...timestamp}
              ]
            }
            obtainedScheduleDayTime.push(tempScheduleDayTime);
          }
        }
      });

      // parse into correct format
      if (!selectedYear) return;
      if (!selectedMonth) return;
      const month = parseInt(selectedMonth.value);
      const todayYear = parseInt(selectedYear.value);
      const maxDateThisMonth = new Date(todayYear, month, 0).getDate();
      for (let day = 1; day <= maxDateThisMonth; day++) {
        const item = obtainedScheduleDayTime.find(i => i.day === day);
        if (item) {
          newScheduleDayTime.push({...item});  
        } else {
          newScheduleDayTime.push({
            day: day,
            timestamp: []
          });
        }
      }

      yield put(getDoctorScheduleAsync.success({ data: [...newScheduleDayTime] }));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(getDoctorScheduleAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(getDoctorScheduleAsync.failure(parsed));
  }
}

export default function* sagaGetDoctorSchedule() {
  yield takeEvery(getDoctorScheduleAsync.request, func);
}
