import React from "react";
import styled from "styled-components";

const TopNavigation = () => {
  return (
    <Root>
      
    </Root>
  );
};

export const Root = styled.div`
  /* background: #fff;
  width: 100%;
  padding: 15px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1); */
`;

export default TopNavigation;
