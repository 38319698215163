import React, { useEffect, useState } from "react";
import XrButtonAv from "../../components/XrButtonAv";
import { RootState } from "../../redux/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import paramsToObject from "../../helpers/paramsToObject";
import Pagination from "../../components/data/Pagination";
import PageWrapper from "../../components/layouts/PageWrapper";
import { useHistory, useLocation } from "react-router-dom";
import { DataTableWrapper } from "../../components/data/DataTable";
import { BootstrapGrid } from "../../components/layouts/BootstrapGrid";
import { parseAge, parseGender, parseStringToSeries } from "../../helpers/fieldHelper";
import { getPatientAsync, searchPatientAsync } from "../../redux/reducers/models/patients/patients.actions";
import styled from "styled-components";
import XrLoadingPlaceholder from "../../components/XrLoadingPlaceholder";
import XrInputAv from "../../components/XrInputAv";
import moment from "moment";
import XrAvatarAv from "../../components/XrAvatarAv";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { trackPageVisit } from "../../helpers/tracker";

const PatientsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const meta = useSelector((state: RootState) => state.patient.meta);
  const patients = useSelector((state: RootState) => state.patient.patients);
  const isLoading = useSelector((state: RootState) => state.patient.isLoading);

  const [txtSearch , setTxtSearch] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const populateData = () => {
    const params = paramsToObject(location.search);
    const pageNumber = parseInt(params && params.page ? params.page : 1);
    if (pageNumber && !isNaN(pageNumber)) {
      if (txtSearch.length >= 3 ) {
        dispatch(searchPatientAsync.request({ page: pageNumber, name: txtSearch }));
      } else {
        dispatch(getPatientAsync.request({ page: pageNumber }));
      }
    } else {
      history.push(`/treatments`);
    }
  }

  useEffect(() => {
    trackPageVisit('patients');
  }, []);

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, searchQuery]);

  const onChangeTxtSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(e.target.value);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchQuery(txtSearch);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [txtSearch]);

  const renderPatientList = () => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
        </tr>
      );
    }

    if (patients.length > 0) {
      return patients.map(patient => {
        return (
          <tr key={patient.id}>
            <td>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <XrAvatarAv name={patient.name} size='small' />&nbsp;
                <div>
                  <p>{patient.name}</p>
                  <p style={{ color: 'var(--color-detail)' }}>{parseGender(patient.gender)}</p>
                </div>
              </div>
              <p></p>
            </td>
            <td>
              <p>{parseAge(patient.bod)} tahun</p>
              <p style={{ color: 'var(--color-detail)' }}>{moment(patient.bod).format('D MMMM YYYY')}</p>
            </td>
            <td>{parseStringToSeries(patient.phone_number, 4)}</td>
            <td>
              <XrButtonAv
                noBlock
                size='small'
                disabled={isLoading}
                href={`/patients/${patient.id}`}
                isLoading={isLoading}
                icon={faPen}
              />
            </td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td colSpan={7}>Tidak ada pasien yang ditemukan</td>
        </tr>
      );
    }
  }

  return (
    <PageWrapper>
      <PatientsPageWrapper>
        <BootstrapGrid>
          <div className="row">
            <div className="col-lg-12">
              
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <DataTableWrapper>
                <div className="tablewrapper--title">
                  <div>
                    Daftar Pasien
                  </div>
                  <div style={{ width: '350px' }}>
                    <XrInputAv
                      type='text'
                      label='Cari Nama Pasien'
                      inline={true}
                      required={true}
                      value={txtSearch}
                      disabled={isLoading}
                      isLoading={isLoading}
                      onChange={onChangeTxtSearch}
                    />
                  </div>
                  <div>
                    <XrButtonAv noBlock size='medium' color='primary' href="/patients/create" caption="Pasien Baru" />
                  </div>
                </div>
                <div className="tablewrapper--table">
                  <table>
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Umur</th>
                        <th>Nomor Telepon</th>
                        <th>Aksi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderPatientList()}
                    </tbody>
                  </table>
                </div>
                {meta ? (
                  <Pagination
                    isLoading={isLoading}
                    totalItem={meta.total}
                    listPerPage={meta.per_page}
                    currentIndex={meta.current_page}
                    maxIndex={meta.last_page}
                    url='patients?page='
                  />
                ) : null}
              </DataTableWrapper>
            </div>
            <div className="col-lg-4">
            </div>
          </div>
        </BootstrapGrid>
      </PatientsPageWrapper>
    </PageWrapper>
  );
}

const PatientsPageWrapper = styled.div`
  padding: 20px;
`;

export default PatientsPage;