export const parseDay = (day: number) => {
  const dayEnums = ['', 'Senin', 'Selasa', 'Rabu', 'Kamis', 'Jumat', 'Sabtu', 'Minggu'];
  if (dayEnums[day]) return dayEnums[day];
  return '-';
};

export const parseMonth = (day: number) => {
  const monthEnums = ['Januari', 'Februari', 'Maret', 'April', 'Mei', 'Juni', 'Juli', 'Agustus', 'September', 'Oktober', 'November', 'Desember'];
  if (monthEnums[day - 1]) return monthEnums[day - 1];
  return '-';
};

export const pad = (val: number) => {
  return (val < 10) ? `0${val}` : val;
}

export const padTime = (time: string) => {
  const timeString = time.split(':');
  const hour = parseInt(timeString[0]);
  const min = parseInt(timeString[1]);
  const sec = parseInt(timeString[2]);
  return `${pad(hour)}:${pad(min)}:${pad(sec)}`;
};

export const getToday = () => {
  return new Date();
}

export const getThisWeekMonday = () => {
  const today = getToday();
  const first = today.getDate() - today.getDay() + 1;
  const monday = new Date(today.setDate(first));
  return monday;
}

export const getThisWeekSunday = () => {
  const today = getToday();
  const first = today.getDate() - today.getDay() + 7;
  const sunday = new Date(today.setDate(first));
  return sunday;
}

export const getFirstDateThisMonth = () => {
  const today = getToday();
  today.setDate(1);
  return today;
}

export const getLastDateThisMonth = () => {
  const today = getToday();
  const month = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  return month;
}

export const getFirstDateThisYear = () => {
  const today = getToday();
  const year = new Date(today.getFullYear(), 0, 1);
  return year;
}

export const getLastDateThisYear = () => {
  const today = getToday();
  const year = new Date(today.getFullYear(), 12, 0);
  return year;
}