import { createAppointmentAsyncResponse } from "./types";
import * as appointmentActions from "./actions";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { ErrorResponse } from "../../../interfaces/Response";
import { apiPost, API_URL_APPOINTMENT, retrieveToken } from "../../../helpers/api";
import { toast } from "react-toastify";
import { RootState } from "../../rootReducers";
import validate from "./validate";
import { toastifyProps } from "../../../helpers/config";

export function* func(action: ReturnType<typeof appointmentActions.createAppointmentAsync.request>) {
  try {
    const errors = validate(action.payload);
    if (errors.errors && Object.keys(errors.errors).length > 0) {
      toast(`Gagal membuat janji temu, mohon periksa kembali formulir Anda.`, {
        ...toastifyProps,
        type: 'error',
      });
      yield put(appointmentActions.createAppointmentAsync.failure(errors));
      return;
    }

    const token: string = yield retrieveToken();
    const result: Response = yield call(apiPost, API_URL_APPOINTMENT.CREATE_APPOINTMENTS, token, action.payload);
    if (result.status === 200) {
      toast(`Berhasil membuat janji temu untuk ${action.payload.patient_detail?.name} dengan ${action.payload.doctor_detail?.name}`, {
        ...toastifyProps,
        type: 'success',
      });
      const parsed: createAppointmentAsyncResponse = yield call(result.json.bind(result));
      yield put(appointmentActions.createAppointmentAsync.success(parsed));
      const appointmentDateGetter = (state: RootState) => state.appointment.selectedDate;
      const appointmentDate: string = yield select(appointmentDateGetter);
      yield put(appointmentActions.getAppointmentAsync.request({ page: 1, visit_date: appointmentDate }));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(appointmentActions.createAppointmentAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(appointmentActions.createAppointmentAsync.failure(parsed));
  }
}

export default function* sagaCreateAppointment() {
  yield takeEvery(appointmentActions.createAppointmentAsync.request, func);
}
