import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Card from "../../components/layouts/Card";
import { RootState } from "../../redux/rootReducers";
import XrTextAv from "../../components/XrTextAv";

const AuthenticationLoadingPage = () => {
  const [theDots, setDots] = useState<string>("");

  const isLoading = useSelector((state: RootState) => state.authorization.isLoading);

  useEffect(() => {
    if (isLoading) {
      if (theDots === '.....') {
        setDots('');
      } else {
        setTimeout(() => {
          setDots(theDots + ".");
        }, 250);
      }
    }
  }, [theDots, isLoading]);

  if (!isLoading) return <div />
  
  return (
    <Card style={{maxWidth: '400px'}}>
      <div className='card--header'>
        <div className='card--header--title'>
          <XrTextAv tag='h1' variant='title'>
            {`Memuat Data${theDots}`}
          </XrTextAv>
        </div>
      </div>
      <div className="card--body">
        Mohon menunggu sebentar, kami sedang menyiapkan sesuatu yang keren!
      </div>
    </Card>
  )
}

export default AuthenticationLoadingPage; 