import moment from 'moment'
import styled from 'styled-components'
import { SingleValue } from 'react-select'
import { COLOR } from '../../helpers/theme'
import Card from '../../components/layouts/Card'
import XrTextAv from '../../components/XrTextAv'
import { Patient } from '../../interfaces/Patient'
import React, { useEffect, useState } from 'react'
import XrInputAv from '../../components/XrInputAv'
import { EnumGender } from '../../interfaces/Enums'
import 'react-datepicker/dist/react-datepicker.css'
import { RootState } from '../../redux/rootReducers'
import XrButtonAv from '../../components/XrButtonAv'
import { useDispatch, useSelector } from 'react-redux'
import { OptionsGender } from '../../interfaces/Options'
import { useHistory, useLocation } from 'react-router-dom'
import PageWrapper from '../../components/layouts/PageWrapper'
import { fieldErrorMessage } from '../../helpers/formErrorHandler'
import { BootstrapGrid } from '../../components/layouts/BootstrapGrid'
import PatientsManageMedicalRecord from './medical-record/PatientsManageMedicalRecord'
import { getMedicalRecordAsync } from '../../redux/reducers/models/medical-records/medicalRecords.actions'
import {
  createPatientAsync,
  selectPatientAsync,
  setError,
} from '../../redux/reducers/models/patients/patients.actions'
import PatientsManageMedicalTreatment from './medical-treatment/PatientsManageMedicalTreatment'

const PatientsManagePage = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const location = useLocation()

  const errors = useSelector((state: RootState) => state.patient.error)
  const isLoading = useSelector((state: RootState) => state.patient.isLoading)
  const selectedPatient = useSelector(
    (state: RootState) => state.patient.selectedPatient
  )

  const [formTitle, setFormTitle] = useState<string>('Tambah')
  const [patient, setPatient] = useState<Patient>({
    job: '',
    nik: '',
    name: '',
    address: '',
    phone_number: '',
    kartu_keluarga: '',
    gender: EnumGender.MALE,
    bod: moment(new Date()).format('YYYY-MM-DD'),
  })

  const [optionGender, setOptionGender] = useState<{
    label: string
    value: number
  } | null>(OptionsGender[0])

  const populateData = () => {
    const paths = location.pathname.split('/')
    if (paths[2] !== 'create') {
      const patientId = parseInt(paths[2])
      if (patientId && !isNaN(patientId)) {
        dispatch(selectPatientAsync.request({ id: patientId }))
        dispatch(getMedicalRecordAsync.request({ patient_id: patientId }))
        setFormTitle('Ubah')
      } else {
        history.push(`/patients/create`)
      }
    }
  }

  const isCreatePage = () => {
    const paths = location.pathname.split('/')
    return paths[2] === 'create'
  }

  useEffect(() => {
    populateData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  useEffect(() => {
    if (selectedPatient) {
      setPatient(selectedPatient)
    } else {
      setPatient({
        job: '',
        nik: '',
        name: '',
        address: '',
        phone_number: '',
        kartu_keluarga: '',
        gender: EnumGender.MALE,
        bod: moment(new Date()).format('YYYY-MM-DD'),
      })
    }
  }, [selectedPatient])

  useEffect(() => {
    if (optionGender) {
      setPatient({
        ...patient,
        gender: optionGender?.value,
      })
    } else {
      setPatient({
        ...patient,
        gender: 1,
      })
      setOptionGender(OptionsGender[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionGender])

  const clearError = (fieldName: string) => {
    if (errors) {
      dispatch(
        setError({
          ...errors,
          errors: {
            ...errors.errors,
            [`${fieldName}`]: [],
          },
        })
      )
    }
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('name')
    setPatient({
      ...patient,
      name: e.target.value,
    })
  }

  const onChangeNik = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('nik')
    setPatient({
      ...patient,
      nik: e.target.value,
    })
  }

  const onChangeDateOfBirth = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatient({
      ...patient,
      bod: e.target.value,
    })
  }

  const onChangeGender = (e: SingleValue<any>) => {
    clearError('gender')
    setOptionGender(e)
  }

  const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('address')
    setPatient({
      ...patient,
      address: e.target.value,
    })
  }

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('phone_number')
    setPatient({
      ...patient,
      phone_number: e.target.value,
    })
  }

  const actionCreate = () => {
    dispatch(createPatientAsync.request(patient))
  }

  const checkNIK = () => {
    // TODO: refactor ini ditambah logic hit ke be
  }

  const renderForm = () => {
    return (
      <BootstrapGrid>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <XrInputAv
                type="text"
                label="Nama Pasien (Sesuai KTP)"
                required={true}
                isLoading={isLoading}
                value={patient.name}
                disabled={isLoading}
                onChange={onChangeName}
                errorMessage={fieldErrorMessage('name', errors)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <XrInputAv
                type="text"
                label="Nomor Induk Kependudukan (NIK)"
                required={true}
                isLoading={isLoading}
                value={patient.nik}
                disabled={isLoading}
                onChange={onChangeNik}
                errorMessage={fieldErrorMessage('nik', errors)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <XrInputAv
                type="text"
                label="Alamat"
                required={false}
                isLoading={isLoading}
                value={patient.address}
                disabled={isLoading}
                onChange={onChangeAddress}
                errorMessage={fieldErrorMessage('address', errors)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <XrInputAv
                type="date"
                label="Tanggal Lahir"
                required={true}
                isLoading={isLoading}
                value={patient.bod}
                disabled={isLoading}
                onChange={onChangeDateOfBirth}
                errorMessage={fieldErrorMessage('bod', errors)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <XrInputAv
                type="text"
                label="Nomor Telepon Pasien"
                required={true}
                isLoading={isLoading}
                value={patient.phone_number}
                disabled={isLoading}
                onChange={onChangePhoneNumber}
                errorMessage={fieldErrorMessage('phone_number', errors)}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group">
              <XrInputAv
                label="Jenis Kelamin"
                required={true}
                disabled={isLoading}
                isLoading={isLoading}
                selectProps={{
                  options: OptionsGender,
                  value: optionGender,
                  onChange: onChangeGender,
                  placeholder: 'Jenis Kelamin Pasien',
                }}
                errorMessage={fieldErrorMessage('doctor_id', errors)}
              />
            </div>
          </div>
        </div>

        <div className="formSubmit">
          <XrButtonAv
            size="medium"
            noBlock
            disabled={isLoading}
            isLoading={isLoading}
            onClick={actionCreate}
            caption={`${formTitle} Pasien`}
          />
        </div>
      </BootstrapGrid>
    )
  }

  return (
    <PageWrapper>
      <PatientsManagePageWrapper>
        <BootstrapGrid>
          <div className="row">
            <div className="col-lg-4">
              <Card>
                <div className="card--header">
                  <div className="card--header--title">
                    <XrTextAv tag="h2" variant="title">
                      <span>{formTitle} Pasien</span>
                    </XrTextAv>
                  </div>
                </div>
                <div className="card--body">{renderForm()}</div>
              </Card>
            </div>
            <div className="col-lg-8">
              {!isCreatePage() ? (
                <React.Fragment>
                  <PatientsManageMedicalRecord />
                  <PatientsManageMedicalTreatment />
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </BootstrapGrid>
      </PatientsManagePageWrapper>
    </PageWrapper>
  )
}

const PatientsManagePageWrapper = styled.div`
  width: 100%;
  padding: 20px;
  min-height: 100vh;

  & .formTitle {
    margin-bottom: 10px;
  }

  & .form-group {
    margin-bottom: 10px;
  }

  .formSubmit {
    margin-top: 30px;
    text-align: center;

    & > p {
      margin-top: 5px;
      & > .formSubmit--update {
        cursor: pointer;
        color: ${COLOR.SECONDARY};
      }
    }
  }
`

export default PatientsManagePage
