import Select from "react-select";
import { useEffect, useState } from "react";
import Card from "../../../components/layouts/Card";
import XrButtonAv from "../../../components/XrButtonAv";
import { Patient } from "../../../interfaces/Patient";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducers";
import { InputWrapper, SelectStyle } from "../../../components/forms/FormComponents";
import { BootstrapGrid } from "../../../components/layouts/BootstrapGrid";
import { setCurrentStep } from "../../../redux/reducers/appointments/actions";
import { getPatientAsync } from "../../../redux/reducers/models/patients/patients.actions";
import XrTextAv from "../../../components/XrTextAv";

interface Option {
  value: Patient;
  label: string;
}
interface AppointmentFormPatientProps {
  selectedPatient: Patient | null;
  setSelectedPatient: (value: Patient | null) => void;
}

const AppointmentFormPatient = (props: AppointmentFormPatientProps) => {
  const dispatch = useDispatch();
  
  const patients = useSelector((state: RootState) => state.patient.patients);
  
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [patientOptions, setPatientOptions] = useState<Option[]>([]);

  const actionSelectSchedule = () => {
    dispatch(setCurrentStep('doctor'));
  }

  const actionPickPatient = () => {
    if (props.selectedPatient) {
      dispatch(setCurrentStep('confirm'));
      return;
    }
    setErrorMessage('Pasien harus dipilih');
  }
  
  useEffect(() => {
    const newOptions: Option[] = [];
    patients.forEach(item => {
      if (item.id) {
        newOptions.push({
          value: item,
          label: item.name
        });
      }
    });
    setPatientOptions(newOptions);
  }, [patients])

  const populateData = () => {
    dispatch(getPatientAsync.request({ page: 0 }));
  };

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectedPatient = () => {
    if (props.selectedPatient) {
      return {
        label: props.selectedPatient.name,
        value: props.selectedPatient
      }
    }
    return null;
  }

  const onChangeSelectedPatient = (e: Option | null) => {
    if (e) {
      setErrorMessage('');
      props.setSelectedPatient(e.value);
    } else {
      props.setSelectedPatient(null);
    }
  }

  const renderSelectedPatient = () => {
    if (props.selectedPatient) {
      return <div />
      // return (
      //   <Card>
      //     <div className="card--title">
      //       <h2 className="card--title-title">Hasil Pencarian Pasien</h2>
      //       <p className="card--title-description">Pastikan data pasien tersebut sudah benar</p>
      //     </div>
      //     <div className="card--body">
      //       <Card>
      //         <div className="card--body">
      //           <div className="appointment">
      //             <h4 className="appointment--doctor">Jeremias Rama Wijaya</h4>
      //             <h5 className="appointment--time">0813-4567-8900</h5>
      //           </div>
      //         </div>
      //       </Card>
      //       <div>
      //         <XrButtonAv onClick={actionCheckAvailability} noBlock={true} caption="Pilih Pasien" />
      //         &nbsp;
      //         <XrButtonAv onClick={actionCheckAvailability} noBlock={true} caption="Ubah Pasien" />
      //       </div>
      //     </div>
      //   </Card>
      // )
    } else {
      return <div />
    }
  }
  
  return (
    <BootstrapGrid>
      <div className="row">
        <div className="col-lg-12">
          <Card>
            <div className='card--header'>
              <div className='card--header--title'>
                <XrTextAv tag='h2' variant='title'>
                  Pasien    
                </XrTextAv>
                <XrTextAv tag='p' variant='description'>
                  Cari atau tambah pasien baru
                </XrTextAv>
              </div>
            </div>
            <div className="card--body">
              <InputWrapper hasErrors={Boolean(errorMessage)}>
                <div className="input--field select">
                  <label>Nama Pasien</label>
                  <Select
                    styles={SelectStyle}
                    options={patientOptions}
                    value={getSelectedPatient()}
                    placeholder="Cari nama pasien"
                    onChange={(e: any) => onChangeSelectedPatient(e)}
                    menuPortalTarget={document.querySelector("body")}
                  />
                </div>
                <div className="input--errors">
                  { errorMessage }
                </div>
              </InputWrapper>
              <div>
                <XrButtonAv noBlock color='primary' size='medium' onClick={actionPickPatient} caption="Pilih Pasien" />
                &nbsp;
                <XrButtonAv noBlock size='medium' onClick={actionSelectSchedule} caption="Ubah Jadwal" />
              </div>
            </div>
          </Card>
        </div>
        <div className="col-md-4">
          { renderSelectedPatient() }
        </div>
      </div>
    </BootstrapGrid>
  );
}

export default AppointmentFormPatient;