export const RESET_STATE = "@treatments/RESET_STATE";
export const SET_ERROR = "@treatments/SET_ERROR";

export const GET_TREATMENT_REQUEST = "@treatments/GET_TREATMENT_REQUEST";
export const GET_TREATMENT_SUCCESS = "@treatments/GET_TREATMENT_SUCCESS";
export const GET_TREATMENT_FAILURE = "@treatments/GET_TREATMENT_FAILURE";

export const UPDATE_TREATMENT_REQUEST = "@treatments/UPDATE_TREATMENT_REQUEST";
export const UPDATE_TREATMENT_SUCCESS = "@treatments/UPDATE_TREATMENT_SUCCESS";
export const UPDATE_TREATMENT_FAILURE = "@treatments/UPDATE_TREATMENT_FAILURE";

export const SELECT_TREATMENT_REQUEST = "@treatments/SELECT_TREATMENT_REQUEST";
export const SELECT_TREATMENT_SUCCESS = "@treatments/SELECT_TREATMENT_SUCCESS";
export const SELECT_TREATMENT_FAILURE = "@treatments/SELECT_TREATMENT_FAILURE";

export const CREATE_TREATMENT_REQUEST = "@treatments/CREATE_TREATMENT_REQUEST";
export const CREATE_TREATMENT_SUCCESS = "@treatments/CREATE_TREATMENT_SUCCESS";
export const CREATE_TREATMENT_FAILURE = "@treatments/CREATE_TREATMENT_FAILURE";

export const SEARCH_TREATMENT_REQUEST = "@treatments/SEARCH_TREATMENT_REQUEST";
export const SEARCH_TREATMENT_SUCCESS = "@treatments/SEARCH_TREATMENT_SUCCESS";
export const SEARCH_TREATMENT_FAILURE = "@treatments/SEARCH_TREATMENT_FAILURE";