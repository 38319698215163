import * as actions from "./authorization.actions";
import * as types from "./authorization.actionTypes";
import { ActionType, createReducer } from "typesafe-actions";
import { AuthorizationReducerType } from "./types";

export type UserActions = ActionType<typeof actions>;

export const initialState: AuthorizationReducerType = {
  token: '',
  error: null,
  isLoading: false,
  authorizedUser: null
};

export const AuthorizationReducer = createReducer<
  AuthorizationReducerType,
  UserActions
>(initialState)
  .handleAction(actions.authenticateAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.authenticateAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    token: action.payload.data.token,
    authorizedUser: action.payload.data.data
  }))
  .handleAction(actions.authenticateAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(actions.getUserAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true
  }))
  .handleAction(actions.getUserAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    authorizedUser: action.payload.data
  }))
  .handleAction(actions.getUserAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(types.SET_TOKEN as any, (state, action) => ({
    ...state,
    token: action.payload.value
  }))
  .handleAction(types.SET_USER as any, (state, action) => ({
    ...state,
    token: action.payload.token,
    authorizedUser: action.payload.data,
  }))
  .handleAction(types.SET_ERROR as any, (state, action) => ({
    ...state,
    error: action.payload.value
  }))
  .handleAction(types.SET_MESSAGE as any, (state, action) => ({
    ...state,
    message: action.payload.value
  }));
