import * as types from "./users.actionTypes";
import { ErrorResponse } from "../../../../interfaces/Response";
import { action, createAsyncAction } from "typesafe-actions";
import { createUserAsyncRequest, createUserAsyncResponse, getUserAsyncRequest, getUserAsyncResponse, searchUserAsyncRequest, searchUserAsyncResponse, selectUserAsyncRequest, selectUserAsyncResponse, updateUserAsyncRequest, updateUserAsyncResponse } from "./types";

export const resetState = () => action(types.RESET_STATE, {});

export const setLoading = (value: boolean) => action(types.SET_LOADING, { value });

export const setError = (value: ErrorResponse) => action(types.SET_ERROR, { value });

export const getUserAsync = createAsyncAction(
  types.GET_USER_REQUEST,
  types.GET_USER_SUCCESS,
  types.GET_USER_FAILURE
)<getUserAsyncRequest, getUserAsyncResponse, ErrorResponse>();

export const updateUserAsync = createAsyncAction(
  types.UPDATE_USER_REQUEST,
  types.UPDATE_USER_SUCCESS,
  types.UPDATE_USER_FAILURE
)<updateUserAsyncRequest, updateUserAsyncResponse, ErrorResponse>();

export const createUserAsync = createAsyncAction(
  types.CREATE_USER_REQUEST,
  types.CREATE_USER_SUCCESS,
  types.CREATE_USER_FAILURE
)<createUserAsyncRequest, createUserAsyncResponse, ErrorResponse>();

export const selectUserAsync = createAsyncAction(
  types.SELECT_USER_REQUEST,
  types.SELECT_USER_SUCCESS,
  types.SELECT_USER_FAILURE
)<selectUserAsyncRequest, selectUserAsyncResponse, ErrorResponse>();

export const searchUserAsync = createAsyncAction(
  types.SEARCH_USER_REQUEST,
  types.SEARCH_USER_SUCCESS,
  types.SEARCH_USER_FAILURE
)<searchUserAsyncRequest, searchUserAsyncResponse, ErrorResponse>();
