import React from "react";
import XrTextAv from "../XrTextAv";
import styled from "styled-components";
import XrAvatarMv from "../XrAvatarMv";
import { useSelector } from "react-redux";
import { COLOR } from "../../helpers/theme";
import { Link, useLocation } from "react-router-dom";
import { RootState } from "../../redux/rootReducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import imgLogo from '../../static/images/logo.png';
import imgIconArrowNormal from "../../static/icons/menu/next-normal.svg";
import { faArrowRight, faBriefcaseMedical, faCalendarAlt, faCashRegister, faPeopleArrows, faStethoscope, faTooth, faUserCog } from "@fortawesome/free-solid-svg-icons";

const navigationItems = [
  {
    icon: faPeopleArrows,
    target: "/appointments",
    name: "Aktivitas",
    role: ['Root Admin', 'Owner', 'Kasir', 'Doctor'],
    subMenu: [
      {
        icon: faCalendarAlt,
        target: "/appointments",
        name: "Janji Temu",
        role: ['Root Admin', 'Owner', 'Kasir']
      },
      {
        icon: faCashRegister,
        target: "/payment",
        name: "Pembayaran",
        role: ['Root Admin', 'Owner', 'Kasir']
      },
      {
        icon: faStethoscope,
        target: "/consultation",
        name: "Konsultasi",
        role: ['Root Admin', 'Owner', 'Doctor']
      },
      {
        icon: faTooth,
        target: "/patients",
        name: "Pasien & Rekam Medis",
        role: ['Root Admin', 'Owner', 'Doctor']
      },
    ]
  },
  {
    icon: faPeopleArrows,
    target: "/",
    name: "Data & Admin",
    role: ['Root Admin', 'Owner'],
    subMenu: [
      {
        icon: faBriefcaseMedical,
        target: "/treatments",
        name: "Tindakan",
        role: ['Root Admin', 'Owner'],
      },
      {
        icon: faUserCog,
        target: "/users",
        name: "Staff & Dokter",
        role: ['Root Admin', 'Owner'],
      },
    ]
  },
  {
    icon: faPeopleArrows,
    target: "/",
    name: "Sistem",
    role: ['Root Admin', 'Owner', 'Kasir', 'Doctor'],
    subMenu: [
      {
        icon: faPeopleArrows,
        target: "/change-user",
        name: "Ganti Pengguna",
        role: ['Root Admin', 'Owner', 'Kasir', 'Doctor'],
      },
      {
        icon: faArrowRight,
        target: '/logout',
        name: 'Keluar',
        role: ['Root Admin', 'Owner', 'Kasir', 'Doctor'],
      },
    ]
  },
];

const SidebarNavigation = () => {
  const location = useLocation();

  const authorizedUser = useSelector((state: RootState) => state.authorization.authorizedUser);

  const isNavigationActive = (target: string) => {
    const urls = location.pathname.split('/');
    const current = urls[1];
    if (!current && location.pathname !== '/') return "";
    if (`/${current}` === target) {
      return "active";
    }
    return "";
  }

  const generateNavItems = (
    navigation: typeof navigationItems
  ) => {
    const filterFunc = (f: any) => {
      if (!authorizedUser || !authorizedUser.roles) return false;
      const roles = authorizedUser.roles.map(r => r.name);
      return f.role.some((s: any) => roles.includes(s));
    }
    return navigation.filter(f => filterFunc(f)).map((item, nIdx) => (
      <React.Fragment key={nIdx}>
        <XrTextAv tag='p' className='SidebarNavigation__menu-items' variant='description'>
          {item.name}
        </XrTextAv>
        {item.subMenu.filter(f => filterFunc(f)).map((subMenu, sIdx) => (
          <Link
            key={sIdx}
            className={`navButton ${isNavigationActive(subMenu.target)}`}
            to={subMenu.target}
          >
            <div className="icon">
              <FontAwesomeIcon icon={subMenu.icon} />
            </div>
            <span>{subMenu.name}</span>
            <div className="icon-next">
              <img className="icon-hover" src={imgIconArrowNormal} alt="open-link" />
            </div>
          </Link>
        ))}
      </React.Fragment> 
    ));
  };
  return (
    <SidebarNavigationWrapper>
      <div className='logo'>
        <img src={imgLogo} alt='One Dental Clinic' />
      </div>
      <div className='avatarWrapper'>
        <XrAvatarMv name={authorizedUser?.name || ''} description={authorizedUser?.email || ''} image={authorizedUser?.avatar || ''}/>
      </div>
      <div className="navWrapper">
        {generateNavItems(navigationItems)}
      </div>
    </SidebarNavigationWrapper>
  );
};

export const SidebarNavigationWrapper = styled.div`
  z-index: 1;
  width: 300px;
  min-width: 300px;
  min-height: 100vh;
  position: relative;
  background-color: #fff;
  border-right: 1px solid #ebebeb;

  & .logo {
    padding: 25px;
    text-align: center;
    & > img { height: 50px; };
    border-bottom: 1px solid ${COLOR.BORDER};
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
  }

  & .avatarWrapper {
    padding: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${COLOR.BORDER};
  }

  & .SidebarNavigation__menu-items {
    margin-top: 25px !important;
    padding-left: 25px !important;
  }

  & .navWrapper {
    .navButton {
      display: flex;
      font-size: 16px;
      font-weight: 600;
      line-height: 100%;
      position: relative;
      align-items: center;
      text-decoration: none;
      transition: 0.15s all;
      color: ${COLOR.BUTTON_TEXT};
      padding: 15px 10px 15px 25px;
      border: 1px solid transparent;

      & > .icon-next {
        opacity: 0;
        right: 80px;
        position: absolute;
        transition: 0.15s all;
      }

      & > .icon-next > * {
        width: 12px;
        height: 12px;
      }

      & > .icon > * {
        display: block;
        width: 17px;
        height: 17px;
        margin-right: 15px;
      }

      & > .icon > .icon-hover {
        display: none;
      }

      &:hover {
        background-color: ${COLOR.BUTTON_FACE_HOVER};
      }

      &:hover,
      &.active {
        color: ${COLOR.PRIMARY};

        & > .icon-next {
          right: 20px;
          opacity: 1;
        }
      }
    }
  }
`;

export default SidebarNavigation;