import { fork, all } from "redux-saga/effects";
import usersSagaGetUser from "./reducers/models/users/users.saga.getUsers";
import usersSagaCreateUser from "./reducers/models/users/users.saga.createUser";
import usersSagaSelectUser from "./reducers/models/users/users.saga.selectUser";
import usersSagaUpdateUser from "./reducers/models/users/users.saga.updateUser";
import patientsSagaGetPatient from "./reducers/models/patients/patients.saga.getPatients";
import patientsSagaSearchPatient from "./reducers/models/patients/patients.saga.searchPatients";
import patientsSagaCreatePatient from "./reducers/models/patients/patients.saga.createPatient";
import patientsSagaSelectPatient from "./reducers/models/patients/patients.saga.selectPatient";
import authorizationSagaAuthenticate from "./reducers/models/authorization/authorization.saga.authenticate";
import treatmentsSagaGetTreatment from "./reducers/models/treatments/treatments.saga.getTreatments";
import treatmentsSagaCreateTreatment from "./reducers/models/treatments/treatments.saga.createTreatment";
import treatmentsSagaSelectTreatment from "./reducers/models/treatments/treatments.saga.selectTreatment";
import treatmentsSagaUpdateTreatment from "./reducers/models/treatments/treatments.saga.updateTreatment";
import medicalRecordsSagaGetRecord from "./reducers/models/medical-records/medicalRecords.saga.getRecords";
import medicalRecordsSagaCreateRecord from "./reducers/models/medical-records/medicalRecords.saga.createRecord";
import medicalRecordsSagaSelectRecord from "./reducers/models/medical-records/medicalRecords.saga.selectRecord";
import medicalRecordsSagaUpdateRecord from "./reducers/models/medical-records/medicalRecords.saga.updateRecord";
import authorizationSagaGetUser from "./reducers/models/authorization/authorization.saga.getUser";
import appointmentSaga from "./reducers/appointments/appointment.saga";
import schedulesSaga from "./reducers/schedules/schedules.saga";
import consultationSaga from "./reducers/consultation/consultation.saga";
import treatmentsSagaSearchTreatment from "./reducers/models/treatments/treatments.saga.searchTreatments";
import usersSagaSearchUser from "./reducers/models/users/users.saga.searchUsers";
import paymentSaga from "./reducers/payment/payment.saga";
import patientsSagaMedicalTreatment from "./reducers/models/patients/patients.saga.getMedicalTreatment";

export function* rootSaga() {
  yield all([
    ...appointmentSaga,
    ...schedulesSaga,
    ...consultationSaga,
    ...paymentSaga,
    fork(usersSagaGetUser),
    fork(usersSagaSearchUser),
    fork(usersSagaCreateUser),
    fork(usersSagaSelectUser),
    fork(usersSagaUpdateUser),
    fork(treatmentsSagaGetTreatment),
    fork(treatmentsSagaSearchTreatment),
    fork(treatmentsSagaCreateTreatment),
    fork(treatmentsSagaSelectTreatment),
    fork(treatmentsSagaUpdateTreatment),
    fork(medicalRecordsSagaGetRecord),
    fork(medicalRecordsSagaCreateRecord),
    fork(medicalRecordsSagaSelectRecord),
    fork(medicalRecordsSagaUpdateRecord),
    fork(patientsSagaGetPatient),
    fork(patientsSagaSearchPatient),
    fork(patientsSagaCreatePatient),
    fork(patientsSagaSelectPatient),
    fork(patientsSagaMedicalTreatment),
    fork(authorizationSagaAuthenticate),
    fork(authorizationSagaGetUser)
  ]);
}
