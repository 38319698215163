import styled from "styled-components";
import { parseMoney } from "../helpers/fieldHelper";
import { TreatmentBilling } from "../redux/reducers/consultation/types";
import XrCardAv from "./XrCardAv";
import XrTextAv from "./XrTextAv";

interface Props {
  items: TreatmentBilling[];
  onClickShowCartModal: (index: number) => void;
}

const XrTransactionCartMv = (props: Props) => {

  const cartItems = () => {
    return props.items.map((i, index) => {
      return (
        <XrCardAv onClick={() => props.onClickShowCartModal(index)}>
          <div className='XrTransactionCartMv__item'>
            <div className='XrTransactionCartMv__item-left'>
              <XrTextAv lineHeightPercent={125} tag='p' variant='body'>
                {i.treatment_detail?.name || ''}
              </XrTextAv>
              <XrTextAv lineHeightPercent={125} tag='p' variant='description'>
                {parseMoney(i.treatment_detail?.price || 0)} <span>x {i.quantity}</span>
              </XrTextAv>
            </div>
            <div className='XrTransactionCartMv__item-left'>
              <XrTextAv tag='p' variant='body'>
                <b>{parseMoney(i.price * i.quantity)}</b>
              </XrTextAv>
            </div>
          </div>
        </XrCardAv>
      );
    });
  }

  return (
    <Wrapper>
      {cartItems().length > 0 ? cartItems() : (
        <XrCardAv>
          <div className='XrTransactionCartMv__item'>
            <XrTextAv lineHeightPercent={125} tag='p' variant='body'>
              Belum ada tindakan, tambahkan dengan menekan F1 atau tekan isian nama tindakan.
            </XrTextAv>
          </div>
        </XrCardAv>
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .XrTransactionCartMv {
    &__item {
      display: flex;
      justify-content: space-between;
      &-left {
        max-width: 185px;
      }
    }
  }
`;

export default XrTransactionCartMv;