import { toast } from "react-toastify";
import * as paymentActions from "./actions";
import { toastifyProps } from "../../../helpers/config";
import { ErrorResponse } from "../../../interfaces/Response";
import { put, call, takeEvery, all } from "redux-saga/effects";
import paramsFromObject from "../../../helpers/paramsFromObject";
import { apiGet, API_URL_REPORT_DAILY, API_URL_TRANSACTION, retrieveToken } from "../../../helpers/api";
import { getReportDailyAsyncResponse, getTransactionsAsyncResponse, getTransactionsAggregateResponse } from "./types";

function* func(action: ReturnType<typeof paymentActions.getTransactionsAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    let payloadTransactions: any = {
      page: action.payload.page ? action.payload.page : 1
    }
    const payloadDate = {
      end_date: action.payload.endDate,
      start_date: action.payload.startDate,
    }
    if (payloadDate.end_date && payloadDate.start_date) {
      payloadTransactions = {
        ...payloadTransactions,
        ...payloadDate
      }
    }
    if (action.payload.doctorId) {
      payloadTransactions.doctor_id = action.payload.doctorId;
    }
    const parsed: getTransactionsAggregateResponse = {
      transaction: [],
      reportDaily: null,
      transactionMeta: {
        total: 0,
        per_page: 0,
        last_page: 0,
        current_page: 0,
      }
    };
    const searchQuery = `${API_URL_TRANSACTION.GET_TRANSACTION}?${paramsFromObject(payloadTransactions)}`;
    const [resultTransactionList, resultReportDaily ]: Response[] = yield all([
      call(apiGet, searchQuery, token),
      call(apiGet, API_URL_REPORT_DAILY.GET_REPORT_DAILY(payloadTransactions), token)
    ]);

    if (resultReportDaily.status === 200) {
      try {
        const parsedReportDaily: getReportDailyAsyncResponse = yield call(resultReportDaily.json.bind(resultReportDaily));
        parsed.reportDaily = parsedReportDaily.data;
      } catch (err) {
        toast(`Gagal mendapatkan data laporan harian ${err}`, {
          ...toastifyProps,
          type: 'error',
        });
      }
    }
    if (resultTransactionList.status === 200) {
      try {
        const parsedTransactions: getTransactionsAsyncResponse = yield call(resultTransactionList.json.bind(resultTransactionList));
        parsed.transaction = parsedTransactions.data;
      } catch (err) {
        toast(`Gagal mendapatkan data daftar transaksi ${err}`, {
          ...toastifyProps,
          type: 'error',
        });
      }
    }
    yield put(paymentActions.getTransactionsAsync.success(parsed));
    return;
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(paymentActions.getTransactionsAsync.failure(parsed));
  }
}

export default function* sagaGetTransactions() {
  yield takeEvery(paymentActions.getTransactionsAsync.request, func);
}
