/**
 * Convert params into object.
 * @param url example: http://something.com/?param1=value&param2=value
 * @returns object from params
 */
const paramsToObject = (url: string): any => {
  try {
    return JSON.parse('{"' + decodeURI(url.substring(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}');
  } catch (ex) {
    return null;
  }
}

export default paramsToObject;