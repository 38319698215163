import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import Card from '../../../components/layouts/Card';
import XrTextAv from '../../../components/XrTextAv';
import { pad } from '../../../helpers/calendarUtils';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducers';
import XrButtonAv from '../../../components/XrButtonAv';
import { Option, OptionsMonth } from '../../../interfaces/Options';
import { InputWrapper, SelectStyle } from '../../../components/forms/FormComponents';
import { getDoctorScheduleAsync, setManageScheduleStep, setSelectedMonth, setSelectedYear } from '../../../redux/reducers/schedules/actions';
import { useEffect } from 'react';

const ScheduleManageSelectTime = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const step = useSelector((state: RootState) => state.schedule.step);
  const isLoading = useSelector((state: RootState) => state.schedule.isLoading);
  const selectedMonth = useSelector((state: RootState) => state.schedule.selectedMonth);
  const selectedYear = useSelector((state: RootState) => state.schedule.selectedYear);
  const scheduleMonthly = useSelector((state: RootState) => state.schedule.scheduleMonthly);

  const checkShouldNavigatePage = () => {
    if (isLoading || step !== 'STEP_1_CHOOSE_MONTH' || !selectedMonth || !selectedYear) return;
    const isScheduleEmpty = scheduleMonthly.findIndex(i => i.timestamp.length > 0) < 0;
    if (isScheduleEmpty) {
      dispatch(setManageScheduleStep('STEP_2_MANAGE_WEEKLY'));
    } else {
      dispatch(setManageScheduleStep('STEP_3_MANAGE_CALENDAR'));      
    }
  }

  useEffect(() => {
    checkShouldNavigatePage();
  }, [isLoading, scheduleMonthly])

  const onChangeYear = (e: Option | null) => {
    dispatch(setSelectedYear(e));
  }

  const onChangeMonth = (e: Option | null) => {
    dispatch(setSelectedMonth(e));
  }

  const actionManageSchedule = () => {
    if (!selectedMonth || !selectedYear) return;
    const paths = location.pathname.split('/');
    const doctorId = parseInt(paths[2]);
    const month = parseInt(selectedMonth.value);
    const year = parseInt(selectedYear.value);
    const maxDateThisMonth = new Date(year, month, 0).getDate();
    dispatch(getDoctorScheduleAsync.request({
      doctor_id: doctorId,
      start_date: `${year}-${pad(month)}-01`,
      end_date: `${year}-${pad(month)}-${pad(maxDateThisMonth)}`,
    }));
  }
  
  const OptionsYear = (): Option[] => {
    const today = new Date();
    return [
      { value: today.getFullYear().toString(), label: today.getFullYear().toString()},
      { value: (today.getFullYear() + 1).toString(), label: (today.getFullYear() + 1).toString()}
    ]
  }

  return (
    <Card>
      <div className='card--header'>
        <div className='card--header--title'>
          <XrTextAv tag='h2' variant='title'>
            Konfigurasi Kalender
          </XrTextAv>
          <XrTextAv tag='p' variant='description'>
            Pilih bulan yang akan dibuatkan jadwalnya.
          </XrTextAv>
        </div>
      </div>
      <div className='card--body'>
        <div className='row'>
          <div className='col-md-12'>
            <InputWrapper>
              <div className='input--field select'>
                <label>Tahun</label>
                <Select
                  styles={SelectStyle}
                  value={selectedYear}
                  options={OptionsYear()}
                  placeholder='Pilih tahun target jadwal'
                  onChange={(e: any) => onChangeYear(e)}
                  menuPortalTarget={document.querySelector('body')}
                />
              </div>
            </InputWrapper>
            <InputWrapper>
              <div className='input--field select'>
                <label>Bulan</label>
                <Select
                  styles={SelectStyle}
                  value={selectedMonth}
                  options={OptionsMonth}
                  placeholder='Pilih bulan target jadwal'
                  onChange={(e: any) => onChangeMonth(e)}
                  menuPortalTarget={document.querySelector('body')}
                />
              </div>
            </InputWrapper>
            <XrButtonAv
              size='medium'
              color='primary'             
              caption='Atur Jadwal'
              isLoading={isLoading}
              onClick={actionManageSchedule}
              disabled={!selectedYear || !selectedMonth || isLoading}
            />
          </div>
        </div>
      </div>
    </Card>
  );
}

export default ScheduleManageSelectTime;