import { fork } from "redux-saga/effects";
import sagaCreateSchedule from "./saga.createSchedule";
import sagaGetDoctor from "./saga.getDoctor";
import sagaGetDoctorSchedule from "./saga.getDoctorSchedule";

const schedulesSaga = [
  fork(sagaCreateSchedule),
  fork(sagaGetDoctor),
  fork(sagaGetDoctorSchedule),
];

export default schedulesSaga;