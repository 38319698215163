import React, { useState } from "react";
import parseTime from "../../../../helpers/parseTime";
import Card from "../../../../components/layouts/Card";
import XrTextAv from "../../../../components/XrTextAv";
import { Option } from '../../../../interfaces/Options';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../redux/rootReducers";
import XrButtonAv from "../../../../components/XrButtonAv";
import { ScheduleDayTime } from "../../../../interfaces/Schedule";
import ScheduleManageWeeklyModal from "./FormWeeklyScheduleModal";
import { parseDay, parseMonth } from "../../../../helpers/calendarUtils";
import { DataTableWrapper } from "../../../../components/data/DataTable";
import { setManageScheduleStep, setScheduleMonthly, setSelectedMonth } from "../../../../redux/reducers/schedules/actions";

const ScheduleManageWeekly = () => {
  const dispatch = useDispatch();

  const scheduleDaily = useSelector((state: RootState) => state.schedule.scheduleDaily);
  const selectedMonth = useSelector((state: RootState) => state.schedule.selectedMonth);
  const selectedYear = useSelector((state: RootState) => state.schedule.selectedYear);

  const [selectedDay, setSelectedDay] = useState<Option | null>(null);
  const [modalActive, setModalActive] = useState<boolean>(false);

  const actionSelectMonth = () => {
    dispatch(setSelectedMonth(null));
    dispatch(setManageScheduleStep('STEP_1_CHOOSE_MONTH'));
  }

  const actionSetScheduleMonthly = () => { 
    if (!selectedYear) return;
    if (!selectedMonth) return;
    const newMonthlySchedule: ScheduleDayTime[] = [];
    const month = parseInt(selectedMonth.value);
    const todayYear = parseInt(selectedYear.value);
    const maxDateThisMonth = new Date(todayYear, month, 0).getDate();
    const thisMonthStartDay = new Date(todayYear, month - 1, 1).getDay();
    let dayCounter = thisMonthStartDay;
    for (let day = 1; day <= maxDateThisMonth; day++) {
      // eslint-disable-next-line no-loop-func
      const daySchedule = scheduleDaily.find(i => i.day === dayCounter);
      if (daySchedule) {
        const mutatedDayObj = {...daySchedule};
        mutatedDayObj.day = day;
        newMonthlySchedule.push({...mutatedDayObj});
      } else {
        const dayObj: any =  { day: day, timestamp: [] }; 
        newMonthlySchedule.push({...dayObj});
      }
      dayCounter++;
      if (dayCounter > 7) dayCounter = 1;
    }
    dispatch(setScheduleMonthly([...newMonthlySchedule]));
    dispatch(setManageScheduleStep('STEP_3_MANAGE_CALENDAR'));
  }

  const actionOpenScheduleDialog = (day: number) => {
    setSelectedDay({
      value: day.toString(),
      label: parseDay(day)
    });
    setModalActive(true);
  }

  const renderMonthName = () => {
    try {
      if (selectedMonth && selectedYear) {
        return `${parseMonth(parseInt(selectedMonth.value))} ${parseInt(selectedYear.value)}`;
      }
      return '-';
    } catch {
      return '-';
    }
  }

  const renderDaySchedule = (day: number) => {
    const selectedSchedule = scheduleDaily.find(i => i.day === day);
    if (selectedSchedule && selectedSchedule.timestamp.length > 0) {
      return selectedSchedule.timestamp.map((item) => {
        return (
          <Card className="hoverable calendar-item" onClick={() => actionOpenScheduleDialog(day)}>
            <div className="card--body">
              <p><b>Jam Praktek</b></p>
              <p>{`${parseTime(item.start)} - ${parseTime(item.end)}`}</p>
            </div>
          </Card>
        )
      });
    }
    return (
      <Card className="hoverable calendar-item" onClick={() => actionOpenScheduleDialog(day)}>
        <div className="card--body">
          <p><b>Tidak ada jadwal</b></p>
          <p>Tambah jadwal baru</p>
        </div>
      </Card>
    )
  }

  return (
    <React.Fragment>
      <ScheduleManageWeeklyModal
        modalActive={modalActive}
        selectedDay={selectedDay}
        setModalActive={setModalActive}
        setSelectedDay={setSelectedDay}
      />
      <Card>
        <div className="card--header">
          <div className="card--header--title">
            <XrTextAv tag="h2" variant="title">
              <span>Atur Jadwal Mingguan Bulan {renderMonthName()}</span>
            </XrTextAv>
            <XrTextAv tag="p" variant="description">
              Tetapkan jadwal untuk setiap minggu. Apabila ada halangan pada tanggal tertentu, dapat ditentukan pada form berikutnya.
            </XrTextAv>
          </div>
          <div className="card--header--action">
            <XrButtonAv
              size="medium"
              noBlock={true}
              disabled={false}
              isLoading={false}
              onClick={() => actionSelectMonth()}
              caption={`Ganti Kalender`}
            />
            &nbsp;
            <XrButtonAv
              size="medium"
              color="primary"
              noBlock={true}
              disabled={false}
              isLoading={false}
              onClick={() => actionSetScheduleMonthly()}
              caption={`Buat Kalender Bulanan`}
            />
          </div>
        </div>
        <div className="card--body">
          <DataTableWrapper style={{ marginTop: 0 }}>
            <div className="tablewrapper--table">
              <table>
                <thead>
                  <tr>
                    <th>Senin</th>
                    <th>Selasa</th>
                    <th>Rabu</th>
                    <th>Kamis</th>
                    <th>Jumat</th>
                    <th>Sabtu</th>
                    <th>Minggu</th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="calendar-grid">
                    <td>{renderDaySchedule(1)}</td>
                    <td>{renderDaySchedule(2)}</td>
                    <td>{renderDaySchedule(3)}</td>
                    <td>{renderDaySchedule(4)}</td>
                    <td>{renderDaySchedule(5)}</td>
                    <td>{renderDaySchedule(6)}</td>
                    <td>{renderDaySchedule(7)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DataTableWrapper>
        </div>
      </Card>
    </React.Fragment>
  );
}

export default ScheduleManageWeekly;