import { createPatientAsyncResponse } from "./types";
import * as patientActions from "./patients.actions";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../../interfaces/Response";
import { apiPost, API_URL_PATIENT, retrieveToken } from "../../../../helpers/api";

export function* createPatient(action: ReturnType<typeof patientActions.createPatientAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    const result: Response = yield call(apiPost, API_URL_PATIENT.CREATE_PATIENTS, token, action.payload);
    if (result.status === 200) {
      const parsed: createPatientAsyncResponse = yield call(result.json.bind(result));
      yield put(
        patientActions.getPatientAsync.request({})
      );
      yield put(patientActions.createPatientAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(patientActions.createPatientAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(patientActions.createPatientAsync.failure(parsed));
  }
}

export default function* patientsSagaCreatePatient() {
  yield takeEvery(patientActions.createPatientAsync.request, createPatient);
}
