import React from 'react';
import Timeform from '../time-form/Timeform';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../../../components/layouts/Card';
import XrModalMv from "../../../../components/XrModalMv";
import XrButtonAv from '../../../../components/XrButtonAv';
import { RootState } from '../../../../redux/rootReducers';
import { ScheduleTime } from '../../../../interfaces/Schedule';
import { setScheduleMonthly } from '../../../../redux/reducers/schedules/actions';

interface FormMonthlyScheduleModalProps {
  selectedDay: number;
  selectedTime: ScheduleTime[];
  modalActive: boolean;
  setModalActive: (value: boolean) => void;
  setSelectedTime: (value: ScheduleTime[]) => void;
}

const FormMonthlyScheduleModal = (props: FormMonthlyScheduleModalProps) => {
  const dispatch = useDispatch();

  const scheduleMonthly = useSelector((state: RootState) => state.schedule.scheduleMonthly);
  
  const parseSelectedDay = () => {
    return {
      label: '',
      value: props.selectedDay.toString()
    };
  }

  const actionCloseScheduleDialog = () => {
    props.setModalActive(false);
  }

  const actionUpdateSchedule = () => {
    try {
      if (props.selectedTime.length === 0) {
        actionCloseScheduleDialog();
      }
      const newSchedule = [...scheduleMonthly];
      newSchedule[props.selectedDay] = {
        day: props.selectedDay,
        timestamp: [...props.selectedTime]
      }
      props.setSelectedTime([]);
      actionCloseScheduleDialog();
      dispatch(setScheduleMonthly([...newSchedule]));
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <XrModalMv
      title='Tambah Jadwal Pengecualian'
      description='Jadwal rutin mingguan yang akan diterapkan dalam 1 bulan. Dapat memilih dari jadwal sebelumnya atau membuat baru.'
      isVisible={props.modalActive} actionClose={actionCloseScheduleDialog}
    >
      <React.Fragment>
        <Card>
          <div className='card--body'>
            <Timeform
              selectedDay={parseSelectedDay()}
              selectedTime={props.selectedTime}
              setSelectedTime={props.setSelectedTime}
            />
          </div>
        </Card>

        <div style={{ textAlign: 'center' }}>
          <XrButtonAv
            noBlock
            size='medium'
            color='primary'
            disabled={false}
            isLoading={false}
            onClick={actionUpdateSchedule}
            caption={`Perbaharui Jadwal`}
          />
        </div>
      </React.Fragment>
    </XrModalMv>
  );
}

export default FormMonthlyScheduleModal;