import Select from 'react-select';
import React, { useEffect, useState } from "react";
import parseTime from '../../../../helpers/parseTime';
import XrButtonAv from '../../../../components/XrButtonAv';
import { ScheduleTime } from "../../../../interfaces/Schedule";
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Option, OptionsTime } from '../../../../interfaces/Options';
import NotificationBar from "../../../../components/NotificationBar";
import { DataTableWrapper } from "../../../../components/data/DataTable";

interface TimeformProps {
  selectedDay: Option | null;
  selectedTime: ScheduleTime[];
  setSelectedTime: (value: ScheduleTime[]) => void;
}

const Timeform = (props: TimeformProps) => {
  const [selectedEndTime, setSelectedEndTime] = useState<Option | null>();
  const [selectedStartTime, setSelectedStartTime] = useState<Option | null>();
  
  useEffect(() => {
    setSelectedEndTime(null);
    setSelectedStartTime(null);
  }, [props.selectedDay, props.selectedTime])

  const onChangeStartTime = (e: Option | null) => {
    setSelectedStartTime(e);
  }

  const onChangeEndTime = (e: Option | null) => {
    setSelectedEndTime(e);
  }

  const actionRemoveTime = (index: number) => {
    const newSelectedTime = [...props.selectedTime];
    newSelectedTime.splice(index, 1);
    props.setSelectedTime([...newSelectedTime]);
  }

  const actionAddTime = () => {
    try {
      if (!selectedStartTime || !selectedEndTime) return;
      const procStartTime = selectedStartTime.value.split(':');
      const procEndTime = selectedStartTime.value.split(':');
      const startTime = new Date();
      const endTime = new Date();
      startTime.setHours(parseInt(procStartTime[0]), parseInt(procStartTime[1]), parseInt(procStartTime[2]));
      endTime.setHours(parseInt(procEndTime[0]), parseInt(procEndTime[1]), parseInt(procEndTime[2]));
      if (startTime > endTime) return;
      props.selectedTime.forEach(item => {
        const procItemStartTime = item.start.split(':');
        const procItemEndTime = item.end.split(':');
        const itemStartTime = new Date();
        const itemEndTime = new Date();
        itemStartTime.setHours(parseInt(procItemStartTime[0]), parseInt(procItemStartTime[1]), parseInt(procItemStartTime[2]));
        itemEndTime.setHours(parseInt(procItemEndTime[0]), parseInt(procItemEndTime[1]), parseInt(procItemEndTime[2]));
        if (
          (startTime <= itemStartTime && itemStartTime <= endTime)
          || (startTime <= itemEndTime && itemEndTime <= endTime)
          || (itemStartTime < startTime && endTime < itemEndTime)
        ) {
          throw new Error('Waktu tidak boleh tumpang tindih dengan yang sudah ada');
        };
      });


      const newSelectedTime = [...props.selectedTime];
      newSelectedTime.push({
        start: selectedStartTime.value,
        end: selectedEndTime.value,
      })
    
      setSelectedEndTime(null);
      setSelectedStartTime(null);
      props.setSelectedTime(newSelectedTime);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <React.Fragment>
      { props.selectedDay ? (
        <React.Fragment>
          <DataTableWrapper style={{ marginTop: 0 }}>
            <div className='tablewrapper--title'>
              Jam Aktif
            </div>
            <div className='tablewrapper--table'>
              <table>
                <thead>
                  <tr>
                    <th>Mulai</th>
                    <th>Selesai</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {props.selectedTime.map((item, index) => (
                    <tr>
                      <td>{parseTime(item.start)}</td>
                      <td>{parseTime(item.end)}</td>
                      <td>
                        <XrButtonAv
                          noBlock
                          color='danger'
                          size='small'
                          icon={faTrash}
                          disabled={false}
                          isLoading={false}
                          onClick={() => actionRemoveTime(index)}
                        />
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td>
                      <Select
                        options={OptionsTime}
                        value={selectedStartTime}
                        placeholder='Pilih'
                        onChange={(e: any) => onChangeStartTime(e)}
                      />
                    </td>
                    <td>
                      <Select
                        options={OptionsTime}
                        value={selectedEndTime}
                        placeholder='Pilih'
                        onChange={(e: any) => onChangeEndTime(e)}
                      />
                    </td>
                    <td>
                      <XrButtonAv
                        noBlock
                        size='small'
                        icon={faPlus}
                        disabled={false}
                        isLoading={false}
                        onClick={() => actionAddTime()}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DataTableWrapper>
        </React.Fragment>
      ) : (
        <NotificationBar type='info' style={{ marginBottom: '10px' }}>
          <p>Mohon memilih hari terlebih dahulu sebelum memasukan jadwal.</p>
        </NotificationBar>
      ) }
    </React.Fragment>
  );
}

export default Timeform;