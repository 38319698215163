import React from "react";
import { Root } from "./@styles";

const AuthenticationForgotPasswordPage = () => {
  return (
    <Root>
      {/* <auth--form>
        <h3 style={{ textAlign: "center", color: "var(--color-detail)" }}>
          Lupa Password
        </h3>
        <TextFieldContainer>
          <label>Email Address</label>
          <TextField
            type="text"
            name="email"
            placeholder="Enter registered email address here"
          />
        </TextFieldContainer>
        <ButtonContainer>
          <ButtonSolid>Reset Kata Sandi</ButtonSolid>
          <SignupLink to="/authentication">atau Masuk Sekarang</SignupLink>
        </ButtonContainer>
      </auth--form>
      <Footer>
        <p>
          <b>AIS Indonesia</b> — Jl. Bengkulu No. 19, Pamulang, Cibedug,
          Indonesia. 15416. (021) 74101234
        </p>
        <p>
          © 2019 <b>AIS Indonesia</b> all rights reserved.{" "}
          <Link to="/terms-of-service">Terms of Service</Link> |{" "}
          <Link to="/privacy-policy">Privacy Policy</Link> |{" "}
          <Link to="/site-map">Sitemap</Link>
        </p>
      </Footer> */}
    </Root>
  );
};

export default AuthenticationForgotPasswordPage;