import { Dispatch } from "react";
import { ErrorResponse } from "../interfaces/Response";

export const fieldHasErrors = (fieldName: string, errors: ErrorResponse | null) => {
  if (errors && errors.errors && Array.isArray(errors.errors)) {
    const errorMessage = errors.errors.find(i => i.field === fieldName);
    if (errorMessage && errorMessage.message) return true;
  }
  return false;
}

export const fieldErrorMessage = (fieldName: string, errors: ErrorResponse | null) => {
  if (errors && errors.errors && errors.errors[fieldName]) {
    return errors.errors[fieldName];
  }
  return [];
}

export const clearError = (fieldName: string, errors: ErrorResponse | null, setError: any, dispatch: Dispatch<any>) => {
  if (errors) {
    dispatch(setError({
      ...errors,
      errors: {
        ...errors.errors,
        [`${fieldName}`]: []
      }
    }));
  }
}