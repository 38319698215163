import moment from "moment";
import styled from "styled-components";
import { COLOR } from "../helpers/theme";

interface XrDatePillAvProps {
  date: moment.Moment;
}

const XrDatePillAv = (props: XrDatePillAvProps) => {
  return (
    <XrDatePillAvWrapper>
      <div className='date__date'>
        { props.date.format('DD') }
      </div>
      <div className='date__month'>
        { props.date.format('MMM') }
      </div>
    </XrDatePillAvWrapper>
  );
}

const XrDatePillAvWrapper = styled.div`
  display: inline-flex;
  border-radius: 5px;
  background-color: #FFFFFF;
  border: 1px solid #DADADA;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);

  & > .date__date,
  & > .date__month {
    padding: 5px 10px 5px 10px;
  }    

  & > .date__date {
    width: 35px;
  }

  & > .date__month {
    width: 45px;
    background-color: ${COLOR.BUTTON_FACE_HOVER};
    border-left: 1px solid ${COLOR.BORDER};
  }
`;

export default XrDatePillAv;