import Spinner from "./Spinner";
import styled from "styled-components";
import { COLOR } from "../helpers/theme";
import { Link } from "react-router-dom";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

type ButtonColorType = 'default' | 'primary' | 'secondary' | 'danger' | 'success';
type ButtonSizeType = 'small' | 'medium' | 'large';

interface ButtonProps {
  href?: string;
  icon?: IconProp;
  size?: ButtonSizeType;
  caption?: string;
  noBlock?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  active?: boolean;
  color?: ButtonColorType;
  submit?: boolean;
  tooltip?: string;
  onClick?: () => void;
}

interface ButtonWrapperProps {
  noBlock?: boolean;
  active?: boolean;
  size?: ButtonSizeType;
  color?: ButtonColorType;
  hasCaption: boolean;
}

const XrButtonAv = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref ) => {
  const actionButtonClick = () => {
    if (props.disabled || !props.onClick) return;
    props.onClick();
  }

  const renderLoadingIndicator = () => {
    if (!props.isLoading) return null;
    return (
      <span className="icon">
        <Spinner size="small" />
      </span>
    );
  }

  const renderIcon = () => {
    if (props.isLoading) return null;
    if (props.icon) return (
      <span className="icon">
        <FontAwesomeIcon icon={props.icon} />
      </span>
    )
    return null;
  }

  const renderButtonNormal = () => {
    return (
      <ButtonWrapper
        ref={ref}
        size={props.size}
        color={props.color}
        noBlock={props.noBlock}
        disabled={props.disabled}
        onClick={actionButtonClick}
        hasCaption={Boolean(props.caption)}
        type={props.submit ? 'submit' : 'button'}
      >
        <div className={`button ${props.active ? 'active' : ''}`} data-tip={props.tooltip}>
          {renderLoadingIndicator()}
          {renderIcon()}
          {props.caption ? <span className="caption">{props.caption}</span> : ''}
        </div>
      </ButtonWrapper>
    );
  }

  const renderButtonLink = () => {
    return (
      <Link to={props.href ?? '/'}>
        {renderButtonNormal()}
      </Link>
    );
  }

  return props.href ? renderButtonLink() : renderButtonNormal();
});

const ButtonWrapper = styled.button<ButtonWrapperProps>`
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  background-color: none;

  display: ${(props) =>
    (() => {
      if (props.noBlock) {
        return "inline-block";
      } else {
        return "block";
      }
    })()};
  width: ${(props) =>
    (() => {
      if (props.noBlock) {
        return "auto";
      } else {
        return "100%";
      }
    })()};
  
  & > .button {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    border-radius: 5px;
    align-items: center;  
    transition: 0.15s all;
    justify-content: center;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
    cursor: ${(props) =>
      (() => {
        if (props.disabled) {
          return 'not-allowed';
        }
        return 'pointer';
      })()
    };
    padding: ${(props) =>
    (() => {
      if (!props.hasCaption) {
        return '5px 10px 5px 10px';
      }
      if (props.size) {
        switch (props.size) {
          case 'small':
            return '5px 25px 5px 25px';
          case 'medium':
            return '8px 25px 8px 25px';
          case 'large':
            return '10px 25px 10px 25px';
          default:
            return '5px 25px 5px 25px';
        }
      } else {
        return '5px 25px 5px 25px';
      }
    })()};
    color: ${(props) =>
    (() => {
      if (props.disabled) {
        return COLOR.TEXT_DESCRIPTIVE
      }
      if (props.color) {
        switch (props.color) {
          case 'primary':
            return '#fff';
          case 'secondary':
            return '#fff';
          case 'danger':
            return '#fff';
          case 'success':
            return '#fff';
          default:
            return COLOR.BUTTON_TEXT;
        }
      } else {
        return COLOR.BUTTON_TEXT;
      }
    })()};
    background-color: ${(props) =>
    (() => {
      if (props.disabled) { 
        return COLOR.BUTTON_FACE;
      }
      if (props.color) {
        switch (props.color) {
          case 'primary':
            return COLOR.PRIMARY;
          case 'secondary':
            return COLOR.SECONDARY;
          case 'danger':
            return COLOR.DANGER;
          case 'success':
            return COLOR.SUCCESS;
          default:
            return COLOR.BUTTON_FACE;
        }
      } else {
        return COLOR.BUTTON_FACE;
      }
    })()};
    border: 1px solid ${(props) =>
    (() => {
      if (props.disabled) {
        return COLOR.BORDER;
      }
      if (props.color) {
        switch (props.color) {
          case 'primary':
            return COLOR.PRIMARY_HOVER;
          case 'secondary':
            return COLOR.SECONDARY_HOVER;
          case 'danger':
            return COLOR.DANGER_HOVER;
          case 'success':
            return COLOR.SUCCESS_HOVER;
          default:
            return COLOR.BORDER;
        }
      } else {
        return COLOR.BORDER;
      }
    })()};

    & > .icon {
      margin-top: 1px;
      margin-right: ${(props) =>
      (() => props.hasCaption ? '8px' : '0px' )()};;
      font-size: 12px;
    }

    &:hover {
      color: ${(props) =>
      (() => {
        if (props.disabled) {
          return COLOR.TEXT_DESCRIPTIVE
        }
        if (props.color) {
          switch (props.color) {
            case 'primary':
              return '#fff';
            case 'secondary':
              return '#fff';
            case 'danger':
              return '#fff';
            case 'success':
              return '#fff';
            default:
              return COLOR.PRIMARY;
          }
        } else {
          return COLOR.PRIMARY;
        }
      })()};
      background-color: ${(props) =>
      (() => {
        if (props.disabled) {
          return COLOR.BUTTON_FACE
        }
        if (props.color) {
          switch (props.color) {
            case 'primary':
              return COLOR.PRIMARY_HOVER;
            case 'secondary':
              return COLOR.SECONDARY_HOVER;
            case 'danger':
              return COLOR.DANGER_HOVER;
            case 'success':
              return COLOR.SUCCESS_HOVER;
            default:
              return COLOR.BUTTON_FACE;
          }
        } else {
          return COLOR.BUTTON_FACE_HOVER;
        }
      })()};
    }

    &.active {
      color: #fff;
      background-color: ${COLOR.PRIMARY};
    }
  }
`;

export default XrButtonAv;