import * as actions from "./actions";
import * as types from "./actionTypes";
import { ActionType, createReducer } from "typesafe-actions";
import { ConsultationReducerType } from "./types";

export type ConsultationActions = ActionType<typeof actions>;

export const initialState: ConsultationReducerType = {
  error: null,
  report: null,
  treatments: [],
  isLoading: false,
  transactions: [],
  createdTransaction: null,
  selectedTransaction: null,
  selectedEndDate: new Date(),
  selectedStartDate: new Date(),
  meta: {
    total: 0,
    per_page: 0,
    last_page: 0,
    current_page: 0,
  }
};

export const PaymentReducer = createReducer<
  ConsultationReducerType,
  ConsultationActions
>(initialState)
  /**
   * Handles get transactions
   */
  .handleAction(actions.getTransactionsAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    transactions: [],
  }))
  .handleAction(actions.getTransactionsAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    report: action.payload.reportDaily,
    meta: action.payload.transactionMeta,
    transactions: action.payload.transaction,
  }))
  .handleAction(actions.getTransactionsAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Handles get treatment
   */
   .handleAction(actions.getTreatmentsAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    treatments: [],
  }))
  .handleAction(actions.getTreatmentsAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    treatments: action.payload.data,
  }))
  .handleAction(actions.getTreatmentsAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Handles select transactions
   */
   .handleAction(actions.selectTransactionAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    selectedTransaction: null
  }))
  .handleAction(actions.selectTransactionAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    selectedTransaction: action.payload.data
  }))
  .handleAction(actions.selectTransactionAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
    selectedTransaction: null
  }))
  /**
   * Handles update transaction
   */
   .handleAction(actions.updateTransactionAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.updateTransactionAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdTransaction: action.payload.data
  }))
  .handleAction(actions.updateTransactionAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Handles other reducer setters
   */
  .handleAction(types.SET_SELECTED_DATE as any, (state, action) => ({
    ...state,
    selectedStartDate: action.payload.start,
    selectedEndDate: action.payload.end
  }))
  /**
   * Handles other reducer setters
   */
  .handleAction(types.SET_ERROR as any, (state, action) => ({
    ...state,
    error: action.payload.value
  }))
  .handleAction(types.SET_TRANSACTION as any, (state, action) => ({
    ...state,
    selectedTransaction: action.payload.value
  }))
  .handleAction(types.RESET_STATE as any, (state, action) => ({
    error: null,
    report: null,
    treatments: [],
    isLoading: false,
    transactions: [],
    createdTransaction: null,
    selectedTransaction: null,
    selectedEndDate: new Date(),
    selectedStartDate: new Date(),
    meta: {
      total: 0,
      per_page: 0,
      last_page: 0,
      current_page: 0,
    }
  }));
