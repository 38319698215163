export const RESET_STATE = "@schedules/RESET_STATE";
export const SET_ERROR = "@schedules/SET_ERROR";
export const SET_SCHEDULE_DAILY = "@schedules/SET_SCHEDULE_DAILY";
export const SET_SELECTED_YEAR = '@schedules/SET_SELECTED_YEAR';
export const SET_SELECTED_MONTH = '@schedules/SET_SELECTED_MONTH';
export const SET_SCHEDULE_MONTHLY = '@schedules/SET_SCHEDULE_MONTHLY';
export const SET_MANAGE_SCHEDULE_STEP = '@schedules/SET_MANAGE_SCHEDULE_STEP';
export const CREATE_SCHEDULE_REQUEST = "@schedules/CREATE_SCHEDULE_REQUEST";
export const CREATE_SCHEDULE_SUCCESS = "@schedules/CREATE_SCHEDULE_SUCCESS";
export const CREATE_SCHEDULE_FAILURE = "@schedules/CREATE_SCHEDULE_FAILURE";
export const GET_DOCTOR_SCHEDULE_REQUEST = "@schedules/GET_DOCTOR_SCHEDULE_REQUEST";
export const GET_DOCTOR_SCHEDULE_SUCCESS = "@schedules/GET_DOCTOR_SCHEDULE_SUCCESS";
export const GET_DOCTOR_SCHEDULE_FAILURE = "@schedules/GET_DOCTOR_SCHEDULE_FAILURE";
export const GET_DOCTOR_REQUEST = "@schedules/GET_DOCTOR_REQUEST";
export const GET_DOCTOR_SUCCESS = "@schedules/GET_DOCTOR_SUCCESS";
export const GET_DOCTOR_FAILURE = "@schedules/GET_DOCTOR_FAILURE";