import { selectUserAsyncResponse } from "./types";
import * as userActions from "./users.actions";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../../interfaces/Response";
import { apiGet, API_URL_USER, retrieveToken } from "../../../../helpers/api";

export function* selectUser(action: ReturnType<typeof userActions.selectUserAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    const result: Response = yield call(apiGet, API_URL_USER.SELECT_USER(action.payload.id), token);
    if (result.status === 200) {
      const parsed: selectUserAsyncResponse = yield call(result.json.bind(result));
      yield put(userActions.selectUserAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(userActions.selectUserAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(userActions.selectUserAsync.failure(parsed));
  }
}

export default function* usersSagaSelectUser() {
  yield takeEvery(userActions.selectUserAsync.request, selectUser);
}
