import { ErrorResponse } from "../../../interfaces/Response";
import { createAppointmentAsyncRequest } from "./types";

const validate = (payload: createAppointmentAsyncRequest): ErrorResponse => {
  let errors: ErrorResponse = {
    message: '',
    status: 422,
    errors: {}
  };

  if (!payload.agenda) {
    errors.errors.agenda = [ 'Agenda wajib diisi' ];
  }

  if (!payload.doctor_id) {
    errors.errors.doctor_id = [ 'Dokter wajib diisi' ];
  }

  if (!payload.patient_id) {
    errors.errors.patient_id = [ 'Pasien wajib diisi' ];
  }
  
  if (!payload.visit_date) {
    errors.errors.visit_date = [ 'Waktu kunjungan wajib diisi' ];
  }
  
  return errors;
}

export default validate;