import { useState } from "react";
import { Link } from "react-router-dom";
import { RootState } from "../../redux/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import { Root, AuthenticationContainer } from "./@styles";
import { authenticateAsync } from "../../redux/reducers/models/authorization/authorization.actions";

import imgBackground from "../../static/images/jonathan-borba-hl6uG9cHW5A-unsplash.jpg";
import XrButtonAv from "../../components/XrButtonAv";
import AuthenticationLoadingPage from "./AuthenticationLoadingPage";

interface formData {
  email: string;
  password: string;
};

const AuthenticationLoginPage = () => {
  const dispatch = useDispatch();
  const error = useSelector((state: RootState) => state.authorization.error);
  const isLoading = useSelector((state: RootState) => state.authorization.isLoading);

  const [formData, setFormData] = useState<formData>({
    email: "",
    password: "",
  });

  const onTextChange = (field: keyof typeof formData, value: string) => {
    setFormData({ ...formData, [field]: value });
  };

  const attemptLogin = () => {
    const { email, password }  = formData;
    dispatch(authenticateAsync.request({ email, password }));
  };

  return (
    <Root>
      <AuthenticationContainer className={`${isLoading ? '' : 'show'}`}>
        <div className="auth--form">
          <div className="auth--form--bg">
            <img src={imgBackground} alt="" />
            <div className="auth--form--content">
              <div className="auth--form--content--logo">
                {/* <img src={imgLogo} alt="Candyd" /> */}
              </div>
              <div className="auth--form--content--form">
                <div className="auth--form--content--form--title">
                  <h3>Selamat Datang,</h3>
                  <p>
                    Masukan alamat email dan kata sandi Anda untuk masuk. Apabila Anda belum mendaftar, silahkan <a href="/">mendaftar disini</a>.
                  </p>
                </div>
                <div className={`auth--form--content--form--input ${isLoading ? 'disabled' : ''}`}>
                  <label>Alamat Email</label>
                  <input
                    type="text"
                    name="email"
                    disabled={isLoading}
                    value={formData.email}
                    onChange={(e) => onTextChange("email", e.target.value)}
                    placeholder="Masukkan email Anda disini"
                  />
                </div>
                <div className={`auth--form--content--form--input ${isLoading ? 'disabled' : ''}`}>
                  <label>Kata Sandi</label>
                  <input
                    type="password"
                    name="password"
                    disabled={isLoading}
                    value={formData.password}
                    onChange={(e) => onTextChange("password", e.target.value)}
                    placeholder="Masukkan kata sandi Anda disini"
                  />
                </div>
                { error ? (
                  <div className="auth--form--content--form-error">
                    #{ error?.status } — { error?.message }
                  </div>
                ) : null}
                <div className="auth--form--content--form--button">
                  <XrButtonAv isLoading={isLoading} onClick={attemptLogin} caption={ isLoading ? 'Memproses Autentikasi' : 'Masuk'} />
                  <Link to="/forgot-password">Lupa kata sandi?</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="auth--info" >
          <img src={imgBackground} alt="" />
          <div className="auth--info--content">
            <AuthenticationLoadingPage />
          </div>
        </div>
      </AuthenticationContainer>
    </Root>
  );
};

export default AuthenticationLoginPage;