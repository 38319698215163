import * as actions from "./actions";
import * as types from "./actionTypes";
import { ActionType, createReducer } from "typesafe-actions";
import { NotificationReducerType } from "./types";

export type NotificationActions = ActionType<typeof actions>;

export const initialState: NotificationReducerType = {
  modal: null
};

export const NotificationReducer = createReducer<
  NotificationReducerType,
  NotificationActions
>(initialState)
  .handleAction(types.SHOW_MODAL as any, (state, action) => ({
    ...state,
    modal: action.payload.value
  }));
