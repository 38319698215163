import styled from "styled-components";

export const Root = styled.div`
  min-width: 100%;
  min-height: 100vh;

  a {
    color: var(--color-primary);
  }
`;

export const AuthenticationContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  &.show {
    & > .auth--info {
      width: calc(100% - 400px);
    }

    & > .auth--form {
      margin-left: 0;
    }
  }

  & > .auth--info {
    display: flex;
    position: relative;
    align-items: center;
    transition: 0.25s all;
    flex-direction: column;
    justify-content: center;
    width: calc(100% + 400px);

    & > img {
      width: 100%;
      height: 100%;
      max-height: 100vh;
      object-fit: cover;
      position: absolute;
      z-index: 0;
    }

    & > .auth--info--content {
      z-index: 1;
    }
  }

  & > .auth--form {
    width: 400px;
    min-width: 400px;
    margin-left: -500px;
    transition: 0.25s all;  

    & > .auth--form--bg {
      position: relative;
      height: 100%;
      width: 100%;

      & > img {
        position: absolute;
        object-fit: cover;
        width: 100%;
        height: 100%;
        z-index: 0;
      }

      & > .auth--form--content {
        height: 100%;
        width: 100%;
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        z-index: 1;
        background: var(--color-primary-transparent);

        & .auth--form--content--form {
          & .auth--form--content--form-error {
            color: red;
            font-size: 16px;
            font-weight: bold;
            background: #fff;
            margin-bottom: 30px;
            padding: 10px 20px 10px 20px;
          }

          & .auth--form--content--form--title {
            margin-bottom: 40px;

            & > * {
              color: #fff;
            }
          }

          & .auth--form--content--form--title > * {
            margin-top: 0px;
            margin-bottom: 5px;
          }

          & .auth--form--content--form--title > p {
            font-size: 16px;
          }

          & > .auth--form--content--form--input {
            width: 100%;
            display: block;
            background: #fff;
            margin-top: -1px;
            padding: 10px 15px 10px 15px;
            border: 1px solid rgb(226, 226, 226);

            & > * {
              display: block;
              width: 100%;
            }

            & > label {
              padding-left: 1px;
              font-size: 10px;
              padding-bottom: 5px;
              color: var(--color-detail);
            }

            & > input {
              border: none;
              padding-top: 5px;
              padding-bottom: 5px;
              background-color: #fff;
            }

            &.disabled {
              background: #f0f0f0;
              & > input {
                background-color: #f0f0f0;
              }
            }
          }

          & > .auth--form--content--form--button {
            margin-top: 10px;
            & > * {
              margin-right: 5px;
            }
            & > a {
              color: #fff;
              display: block;
              width: 100%;
              font-size: 16px;
              margin-top: 10px;
            }
          }
        }

        & .auth--form--content--logo {
          top: 0px;
          padding-top: 50px;
          position: absolute;

          & img {
            width: 150px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    flex-direction: column;

    & > .auth--form {
      width: 100%;
      min-width: 0;

      & > .auth--form--bg {
        & > .container {
          padding-top: 200px;
        }
      }
    }
  }

  @media only screen and (max-width: 992px) {
    & > .auth--info {
      width: 100%;
      & > .infoCard {
        width: 100%;
        min-width: 0;
      }
    }
  }
`;
