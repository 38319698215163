import * as actions from "./patients.actions";
import * as types from "./patients.actionTypes";
import { ActionType, createReducer } from "typesafe-actions";
import { PatientReducerType } from "./types";

export type UserActions = ActionType<typeof actions>;

export const initialState: PatientReducerType = {
  meta: {
    total: 0,
    per_page: 0,
    last_page: 0,
    current_page: 0,
  },
  error: null,
  patients: [],
  isLoading: false,
  createdPatient: null,
  selectedPatient: null,
  selectedMedicalRecord: null,
  medicalTreatments: [],
};

export const PatientReducer = createReducer<
  PatientReducerType,
  UserActions
>(initialState)
  // select patient async
  .handleAction(actions.selectPatientAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    createdPatient: null,
    selectedPatient: null,
    medicalTreatments: [],
    selectedMedicalRecord: null
  }))
  .handleAction(actions.selectPatientAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    selectedPatient: action.payload.data,
  }))
  .handleAction(actions.selectPatientAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  // select medical treatment
  .handleAction(actions.getMedicalTreatmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    medicalTreatments: [],
  }))
  .handleAction(actions.getMedicalTreatmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    medicalTreatments: action.payload.data
  }))
  .handleAction(actions.getMedicalTreatmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  // get patient async
  .handleAction(actions.getPatientAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    meta: {
      total: 0,
      per_page: 0,
      last_page: 0,
      current_page: 0,
    },
  }))
  .handleAction(actions.getPatientAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    patients: action.payload.data,
    meta: action.payload.meta
  }))
  .handleAction(actions.getPatientAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  // create patient async
  .handleAction(actions.createPatientAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.createPatientAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdPatient: action.payload.data
  }))
  .handleAction(actions.createPatientAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  // search patient async
  .handleAction(actions.searchPatientAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.searchPatientAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    patients: action.payload.data,
    meta: action.payload.meta
  }))
  .handleAction(actions.searchPatientAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(types.SET_ERROR as any, (state, action) => ({
    ...state,
    error: action.payload.value
  }))
  .handleAction(types.SET_MEDICAL_RECORD as any, (state, action) => ({
    ...state,
    selectedMedicalRecord: action.payload.value
  }))

