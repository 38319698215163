import { useEffect } from "react";
import styled from "styled-components";
import { COLOR } from "../../helpers/theme";
import XrTextAv from "../../components/XrTextAv";
import Card from "../../components/layouts/Card";
import "react-datepicker/dist/react-datepicker.css";
import { RootState } from "../../redux/rootReducers";
import XrButtonAv from "../../components/XrButtonAv";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import PageWrapper from "../../components/layouts/PageWrapper";
import { BootstrapGrid } from "../../components/layouts/BootstrapGrid";
import { getDoctorAsync, resetState } from "../../redux/reducers/schedules/actions";
import ScheduleManageCreateSchedule from './schedules-manage/ScheduleManageCreateSchedule';

const SchedulesManagePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const managedSchedule = useSelector((state: RootState) => state.schedule.managedSchedule);
  const selectedDoctor = useSelector((state: RootState) => state.schedule.selectedDoctor);

  const populateData = () => {
    const paths = location.pathname.split("/");
    if (paths[2] !== 'create') {
      const userId = parseInt(paths[2]);
      if (userId && !isNaN(userId)) {
        dispatch(getDoctorAsync.request({ id: userId }));
      } else {
        history.push(`/schedules/create`);
      }
    }
  }

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const actionEditSchedule = () => {
    dispatch(resetState());
    populateData();
  }

  const renderFormSuccess = () => {
    return (
      <BootstrapGrid>
        <div className='row'>
          <div className='col-md-4'></div>
          <div className='col-md-4'>
            <Card>
              <div className='card--header'>
                <div className='card--header--title'>
                  <XrTextAv tag='h2' variant='title'>
                    <span>Jadwal {selectedDoctor?.name} berhasil diperbaharui!</span>
                  </XrTextAv>
                  <XrTextAv tag='p' variant='description'>
                    <span>Anda dapat mencari dokter lainnya pada halaman daftar dokter atau tetap di halaman ini untuk memperbaharui jadwal {selectedDoctor?.name}</span>
                  </XrTextAv>
                </div>
              </div>
              <div className="card--body">
                <div className="formSubmit">
                  <XrButtonAv noBlock color='primary' href='/schedules' caption='Kembali ke Daftar Dokter' />
                  &nbsp;
                  <XrButtonAv noBlock onClick={actionEditSchedule} caption='Ubah Jadwal Dokter' />
                </div>
              </div>
            </Card>
          </div>
          <div className='col-md-4'></div>
        </div>
      </BootstrapGrid>
    );
  }

  return (
    <PageWrapper>
      <SchedulesManagePageWrapper>
        <div className="schedule--user">
          <XrTextAv variant="description">
            Memperbaharui Jadwal Dokter untuk
          </XrTextAv>
          <XrTextAv variant="title" tag="h2">
            {selectedDoctor?.name || ''}
          </XrTextAv>
        </div>
        <div className="schedule--form">
          { managedSchedule.length > 0 ? renderFormSuccess() : <ScheduleManageCreateSchedule /> }
        </div>
      </SchedulesManagePageWrapper>
    </PageWrapper>
  );
}

const SchedulesManagePageWrapper = styled.div`
  width: 100%;
  min-height: 100vh;

  & .schedule--user {
    background: #fff;
    border-top: 1px solid ${COLOR.BORDER};
    border-bottom: 1px solid ${COLOR.BORDER};
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
  }

  & .schedule--form,
  & .schedule--user {
    padding: 20px;
  }
`;

export default SchedulesManagePage;