import moment from "moment";
import { useEffect } from "react";
import styled from "styled-components";
import XrInputAv from "../../components/XrInputAv";
import XrButtonAv from "../../components/XrButtonAv";
import { RootState } from "../../redux/rootReducers";
import XrAvatarAv from "../../components/XrAvatarAv";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/data/Pagination";
import paramsToObject from "../../helpers/paramsToObject";
import { useHistory, useLocation } from "react-router-dom";
import PageWrapper from "../../components/layouts/PageWrapper";
import AppointmentFormPage from "./manage/AppointmentFormPage";
import { DataTableWrapper } from "../../components/data/DataTable";
import AppointmentManagePage from "./manage/AppointmentManagePage";
import XrLoadingPlaceholder from "../../components/XrLoadingPlaceholder";
import { getAppointmentAsync, resetState, selectAppointmentAsync, setSelectedDate, showAppointmentForm } from "../../redux/reducers/appointments/actions";
import AppointmentStatus from "./molecules/AppointmentStatus";
import { faCartPlus, faPen } from "@fortawesome/free-solid-svg-icons";

const AppointmentsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const meta = useSelector((state: RootState) => state.appointment.meta);
  const isLoading = useSelector((state: RootState) => state.appointment.isLoading);
  const selectedDate = useSelector((state: RootState) => state.appointment.selectedDate);
  const appointments = useSelector((state: RootState) => state.appointment.appointments);
  const isLoadingSelection = useSelector((state: RootState) => state.appointment.isLoadingSelection);
  const selectedAppointmentId = useSelector((state: RootState) => state.appointment.selectedAppointmentId);

  const populateData = () => {
    const params = paramsToObject(location.search);
    const pageNumber = parseInt(params && params.page ? params.page : 1);
    if (pageNumber && !isNaN(pageNumber)) {
      dispatch(getAppointmentAsync.request({ page: pageNumber, visit_date: selectedDate }));
    } else {
      history.push(`/appointments`);
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetState());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, selectedDate]);

  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSelectedDate(e.target.value));
  }

  const actionEditAppointment = (id: number | undefined) => {
    if (id) {
      dispatch(selectAppointmentAsync.request({ id }));
    }
  }

  const actionCreateAppointment = () => {
    dispatch(showAppointmentForm());
  }

  const renderAppointmentList = () => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
        </tr>
      );
    }

    if (appointments.length > 0) {
      return appointments.map(appointment => {
        return (
          <tr>
            <td>
              {moment(appointment.visit_date).format('HH:mm')}
            </td>
            <td>
              <div style={{ display: 'flex' }}>
                <XrAvatarAv name={appointment.doctor_detail?.name || ''} image={appointment.doctor_detail?.avatar || ''} size='small' />&nbsp;
                {appointment.doctor_detail?.name}
              </div>
            </td>
            <td>
              <div style={{ display: 'flex' }}>
                {appointment.patient_detail?.name}
              </div>
            </td>
            <td>
              <AppointmentStatus fixedWidth status={appointment.status} />
            </td>
            <td>
              <XrButtonAv
                noBlock
                size='small'
                icon={faPen}
                disabled={isLoadingSelection}
                tooltip={'Buat janji temu baru'}
                href={`/appointments/${appointment.id}`}
                onClick={() => actionEditAppointment(appointment.id)}
                color={selectedAppointmentId === appointment.id ? 'primary' : 'default'}
                isLoading={isLoadingSelection && selectedAppointmentId === appointment.id}
              />
              {/* &nbsp;
              <XrButtonAv
                noBlock
                size='small'
                color={'default'}
                icon={faCartPlus}
                isLoading={isLoading}
                disabled={isLoadingSelection}
                href={`/payment/create/${appointment.id}`}
                tooltip={'Buat transaksi dari janji temu'}
                onClick={() => actionEditAppointment(appointment.id)}
              /> */}
            </td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td colSpan={7}>Tidak ada janji temu yang ditemukan</td>
        </tr>
      );
    }
  }

  return (
    <PageWrapper>
      <AppointmentPageWrapper>
        <AppointmentFormPage />
        <AppointmentManagePage />
        <div>
          <DataTableWrapper>
            <div className="tablewrapper--title">
              <div>
                Daftar Janji Temu
              </div>
              <div style={{ width: '300px' }}>
                <XrInputAv
                  type='date'
                  label='Tanggal'
                  inline={true}
                  value={selectedDate}
                  disabled={isLoading}
                  isLoading={isLoading}
                  onChange={onChangeDate}
                />
              </div>
              <div>
                <XrButtonAv noBlock size='medium' color='primary' onClick={actionCreateAppointment} caption="Buat Janji Baru" />
              </div>
            </div>
            <div className="tablewrapper--table">
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Dokter</th>
                    <th>Pasien</th>
                    <th>Status</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {renderAppointmentList()}
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              totalItem={meta.total}
              listPerPage={meta.per_page}
              currentIndex={meta.current_page}
              maxIndex={meta.last_page}
              url='appointments?page='
            />
          </DataTableWrapper>
        </div>
      </AppointmentPageWrapper>
    </PageWrapper>
  );
}

const AppointmentPageWrapper = styled.div`
  margin: auto;
  padding: 20px;
  max-width: 1300px;
`;

export default AppointmentsPage;
