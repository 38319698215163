import * as actions from "./users.actions";
import * as types from "./users.actionTypes";
import { ActionType, createReducer } from "typesafe-actions";
import { UserReducerType } from "./types";

export type UserActions = ActionType<typeof actions>;

export const initialState: UserReducerType = {
  error: null,
  users: [],
  isLoading: false,
  createdUser: null,
  selectedUser: null,
  meta: {
    total: 0,
    per_page: 0,
    last_page: 0,
    current_page: 0,
  }
};

export const UserReducer = createReducer<
  UserReducerType,
  UserActions
>(initialState)
/**
 * Reducer handler for get user async
 **/
  .handleAction(actions.getUserAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.getUserAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    meta: action.payload.meta,
    users: action.payload.data,
  }))
  .handleAction(actions.getUserAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
/**
 * Reducer handler for search user async
 **/
  .handleAction(actions.searchUserAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.searchUserAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    meta: action.payload.meta,
    users: action.payload.data,
  }))
  .handleAction(actions.searchUserAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
/**
 * Reducer handler for select user async
 **/
  .handleAction(actions.selectUserAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    createdUser: null,
    selectedUser: null,
  }))
  .handleAction(actions.selectUserAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    selectedUser: action.payload.data,
  }))
  .handleAction(actions.selectUserAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
/**
 * Reducer handler for update user async
 **/
  .handleAction(actions.updateUserAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.updateUserAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdUser: action.payload.data,
  }))
  .handleAction(actions.updateUserAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
/**
 * Reducer handler for create user async
 **/
  .handleAction(actions.createUserAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.createUserAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdUser: action.payload.data
  }))
  .handleAction(actions.createUserAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
/**
 * Other reducer handlers
 **/
  .handleAction(types.SET_ERROR as any, (state, action) => ({
    ...state,
    error: action.payload.value
  }))
  .handleAction(types.SET_LOADING as any, (state, action) => ({
    ...state,
    isLoading: action.payload.value
  }))
  .handleAction(types.RESET_STATE as any, (state, action) => ({
    ...state,
    error: null,
    createdUser: null,
    selectedUser: null
  }))
