import { FunctionComponent } from "react";
import styled from "styled-components";
import { COLOR } from "../helpers/theme";

interface Props {
  padding?: number;
  onClick?: () => void;
  children: JSX.Element | JSX.Element[] | string | any;
}

interface PropsHeader {
  title?: string;
  children?: JSX.Element | JSX.Element[] | string | any;
}

const XrCardAv: FunctionComponent<Props> = (props: Props) => {
  const padding = props.padding ?? 8;

  return (
    <Wrapper onClick={props.onClick} style={{ padding: `${padding}px`, cursor: props.onClick ? 'pointer' : 'auto' }}>
      {props.children}
    </Wrapper>
  )
}

export const XrCardHeaderAv: FunctionComponent<PropsHeader> = (props: PropsHeader) => {
  return (
    <div className='XrCardAv__header'>
      <div className='XrCardAv__header--title'>
        <b>{props.title}</b>
      </div>
      {props.children}
    </div>
  );
}

export const XrCardFooterAv: FunctionComponent<PropsHeader> = (props: PropsHeader) => {
  return (
    <div className='XrCardAv__footer'>
      <div className='XrCardAv__footer--title'>
        <b>{props.title}</b>
      </div>
      {props.children}
    </div>
  );
}

export default XrCardAv;

const Wrapper = styled.div`
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #DADADA;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);
  
  & .XrCardAv {
    &__header,
    &__footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      color: ${COLOR.BUTTON_TEXT};
      padding: 0px 16px 0px 16px;
      &--title {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }
    &__header {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border-bottom: 1px solid #e2e2e2;
    }
    &__footer {
      border-top: 1px solid #e2e2e2;
    }
  }
`