import * as types from "./treatments.actionTypes";
import { ErrorResponse } from "../../../../interfaces/Response";
import { action, createAsyncAction } from "typesafe-actions";
import { createTreatmentAsyncRequest, createTreatmentAsyncResponse, getTreatmentAsyncRequest, getTreatmentAsyncResponse, searchTreatmentAsyncRequest, searchTreatmentAsyncResponse, selectTreatmentAsyncRequest, selectTreatmentAsyncResponse, updateTreatmentAsyncRequest, updateTreatmentAsyncResponse } from "./types";

export const resetState = () => action(types.RESET_STATE, {});

export const setError = (value: ErrorResponse) => action(types.SET_ERROR, { value });

export const getTreatmentAsync = createAsyncAction(
  types.GET_TREATMENT_REQUEST,
  types.GET_TREATMENT_SUCCESS,
  types.GET_TREATMENT_FAILURE
)<getTreatmentAsyncRequest, getTreatmentAsyncResponse, ErrorResponse>();

export const updateTreatmentAsync = createAsyncAction(
  types.UPDATE_TREATMENT_REQUEST,
  types.UPDATE_TREATMENT_SUCCESS,
  types.UPDATE_TREATMENT_FAILURE
)<updateTreatmentAsyncRequest, updateTreatmentAsyncResponse, ErrorResponse>();

export const createTreatmentAsync = createAsyncAction(
  types.CREATE_TREATMENT_REQUEST,
  types.CREATE_TREATMENT_SUCCESS,
  types.CREATE_TREATMENT_FAILURE
)<createTreatmentAsyncRequest, createTreatmentAsyncResponse, ErrorResponse>();

export const selectTreatmentAsync = createAsyncAction(
  types.SELECT_TREATMENT_REQUEST,
  types.SELECT_TREATMENT_SUCCESS,
  types.SELECT_TREATMENT_FAILURE
)<selectTreatmentAsyncRequest, selectTreatmentAsyncResponse, ErrorResponse>();

export const searchTreatmentAsync = createAsyncAction(
  types.SEARCH_TREATMENT_REQUEST,
  types.SEARCH_TREATMENT_SUCCESS,
  types.SEARCH_TREATMENT_FAILURE
)<searchTreatmentAsyncRequest, searchTreatmentAsyncResponse, ErrorResponse>();