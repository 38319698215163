import styled from "styled-components";
import { COLOR } from "../helpers/theme";
import XrAvatarAv from "./XrAvatarAv";
import XrLoadingPlaceholder from "./XrLoadingPlaceholder";

interface XrAvatarMvProps {
  name: string;
  image?: string;
  description: string;
  onClick?: () => void;
}

const XrAvatarMv = (props: XrAvatarMvProps) => {
  return (
    <XrAvatarMvWrapper onClick={props.onClick} className={`${props.onClick ? 'clickable' : ''}`}>
      <div className="profileDetail">
        <div className="avatar">
          <XrAvatarAv name={props.name} image={props.image} />
        </div>
        <div className="content">
          <XrLoadingPlaceholder width={150} height={24} isLoading={!props.name}>
            <h1>{props.name}</h1>
          </XrLoadingPlaceholder>
          <XrLoadingPlaceholder width={125} height={21} isLoading={!props.description}>
            <h2>{props.description}</h2>
          </XrLoadingPlaceholder>
        </div>
      </div>
    </XrAvatarMvWrapper>
  )
}

const XrAvatarMvWrapper = styled.div`
  &.clickable { cursor: pointer }

  & > .profileDetail {
    display: flex;
    align-items: center;

    & > .content {
      & > * {
        color: ${COLOR.BUTTON_TEXT};
      }

      h1 {
        font-size: 16px;
      }
      h2 {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
`;

export default XrAvatarMv;