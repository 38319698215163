import moment from "moment";
import { useState } from "react";
import styled from "styled-components";
import XrTabsMv from "../../../components/XrTabsMv";
import Card from "../../../components/layouts/Card";
import XrTextAv from "../../../components/XrTextAv";
import { Patient } from "../../../interfaces/Patient";
import { useDispatch, useSelector } from "react-redux";
import XrButtonAv from "../../../components/XrButtonAv";
import XrAvatarMv from "../../../components/XrAvatarMv";
import { RootState } from "../../../redux/rootReducers";
import { Schedule } from "../../../interfaces/Schedule";
import XrDatePillAv from "../../../components/XrDatePillAv";
import AppointmentFormDoctor from "./AppointmentFormDoctor";
import AppointmentFormPatient from "./AppointmentFormPatient";
import AppointmentFormSummary from "./AppointmentFormSummary";
import AppointmentFormSchedule from "./AppointmentFormSchedule";
import PageWrapper from "../../../components/layouts/PageWrapper";
import { parseStringToSeries } from "../../../helpers/fieldHelper";
import { BootstrapGrid } from "../../../components/layouts/BootstrapGrid";
import { AppointmentSteps } from "../../../redux/reducers/appointments/types";
import { resetState, setCurrentStep } from "../../../redux/reducers/appointments/actions";


const AppointmentCreatePage = () => {
  const dispatch = useDispatch();

  const currentStep = useSelector((state: RootState) => state.appointment.currentStep);
  const createdAppointment = useSelector((state: RootState) => state.appointment.createdAppointment);

  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(null);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

  const actionEditAppointment = () => {
    dispatch(resetState());
  }

  const renderFormSuccess = () => {
    return (
      <BootstrapGrid>
        <div className='row'>
          <div className='col-md-4'></div>
          <div className='col-md-4'>
            <Card>
              <div className='card--header'>
                <div className='card--header--title'>
                  <XrTextAv tag='h2' variant='title'>
                    Berhasil membuat janji dokter!
                  </XrTextAv>
                  <XrTextAv tag='p' variant='description'>
                    Anda dapat keluar dari halaman ini atau tetap di halaman ini untuk memperbaharui janji dokter yang baru Anda buat.
                  </XrTextAv>
                </div>
              </div>
              <div className="card--body">
                <div className="formSubmit">
                  <XrButtonAv noBlock color='primary' href='/appointments' caption='Kembali ke Daftar Janji Dokter' />
                  &nbsp;
                  <XrButtonAv noBlock onClick={actionEditAppointment} caption='Ubah Janji Temu' />
                </div>
              </div>
            </Card>
          </div>
          <div className='col-md-4'></div>
        </div>
      </BootstrapGrid>
    );
  }

  const renderForm = () => {
    if (createdAppointment) return renderFormSuccess();
    
    switch (currentStep) {
      case 'schedule':
        return <AppointmentFormSchedule />;
      case 'doctor':
        return <AppointmentFormDoctor 
          setSelectedSchedule={setSelectedSchedule}
        />;
      case 'patient':
        return <AppointmentFormPatient
          selectedPatient={selectedPatient}
          setSelectedPatient={setSelectedPatient}
        />;
      case 'confirm':
        return <AppointmentFormSummary
          selectedPatient={selectedPatient}
          selectedSchedule={selectedSchedule}
        />;
    }
  }

  const renderSteps = () => {
    const steps: {key: AppointmentSteps; title: string}[] = [
      {
        title: 'Pilih Jadwal',
        key: 'schedule'
      },
      {
        title: 'Pilih Dokter',
        key: 'doctor'
      },
      {
        title: 'Pilih Pasien',
        key: 'patient'
      },
      {
        title: 'Konfirmasi Janji',
        key: 'confirm'
      },
    ]

    const goTo = (step: AppointmentSteps) => {
      dispatch(setCurrentStep(step));
    }

    return <XrTabsMv tabs={steps} activeKey={currentStep} onChangeKey={(key: string) => goTo(key as AppointmentSteps)} />;
  }

  const renderPatientName = () => {
    if (!selectedPatient)
      return (
        <XrTextAv tag='p' variant='body'>
          Pasien belum dipilih
        </XrTextAv>
      );
    return <XrAvatarMv name={selectedPatient.name} description={parseStringToSeries(selectedPatient.phone_number, 4)} />
  }

  const renderDoctorName = () => {
    if (!selectedSchedule)
      return (
        <XrTextAv tag='p' variant='body'>
          Dokter belum dipilih
        </XrTextAv>
      );
    return <XrAvatarMv name={selectedSchedule.doctor_detail?.name || ''} description={parseStringToSeries(selectedSchedule.doctor_detail?.phone_number, 4) || ''} image={selectedSchedule.doctor_detail?.avatar || ''} />
  }

  const renderSelectedSchedule = () => {
    if (!selectedSchedule)
      return (<XrTextAv tag='p' variant='body'>
        Jadwal belum dipilih
      </XrTextAv>
    );
    return (
      <div className='selected-schedule'>
        <XrDatePillAv date={moment(selectedSchedule.start_date)}/>
        <XrTextAv tag='p' variant='body'>
          {`${moment(selectedSchedule?.start_date).format('HH:mm')}:00 — ${moment(selectedSchedule?.end_date).format('HH:mm')}:00`}
        </XrTextAv>
      </div>
    )
  }

  return (
    <PageWrapper>
      <AppointmentCreatePageWrapper>
        <div className='appointment-nav'>
          {renderSteps()}
        </div>
        <BootstrapGrid className='appointment-content'>
          <div className='row'>
            <div className='col-md-3'>
              <Card>
                <div className='card--header'>
                  <div className='card--header--title'>
                    <XrTextAv tag='h2' spaceBottom={5} variant='title'>
                      Detail Janji Temu
                    </XrTextAv>
                  </div>
                </div>
                <div className="card--body">
                  <XrTextAv tag='p' spaceBottom={5} variant='description'>
                    Pasien
                  </XrTextAv>
                  {renderPatientName()}

                  <br />
                  <XrTextAv tag='p' spaceBottom={5} variant='description'>
                    Akan bertemu dengan dokter
                  </XrTextAv>
                  {renderDoctorName()}

                  <br />
                  <XrTextAv tag='p' spaceBottom={5} variant='description'>
                    Pada
                  </XrTextAv>
                  {renderSelectedSchedule()}
                </div>
              </Card>
            </div>
            <div className='col-md-6'>
              {renderForm()}
            </div>
          </div>
        </BootstrapGrid>
      </AppointmentCreatePageWrapper>
    </PageWrapper>
  );
}

const AppointmentCreatePageWrapper = styled.div`
  & .appointment-content {
    padding: 20px;
  }

  & .appointment-nav {
    padding-top: 20px;
    background-color: #fff;
  }

  & .selected-schedule {
    display: flex;
    align-items: center;
    & > *:first-child {
      margin-right: 15px;
    }
  }
`;

export default AppointmentCreatePage;