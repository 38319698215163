import moment from "moment";
import { useEffect } from "react";
import styled from "styled-components";
import XrButtonAv from "../../components/XrButtonAv";
import { RootState } from "../../redux/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../components/data/Pagination";
import paramsToObject from "../../helpers/paramsToObject";
import { useHistory, useLocation } from "react-router-dom";
import PageWrapper from "../../components/layouts/PageWrapper";
import { DataTableWrapper } from "../../components/data/DataTable";
import XrLoadingPlaceholder from "../../components/XrLoadingPlaceholder";
import { getAppointmentAsync, resetState } from "../../redux/reducers/consultation/actions";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import XrAvatarAv from "../../components/XrAvatarAv";

const ConsultationsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const meta = useSelector((state: RootState) => state.consultation.meta);
  const isLoading = useSelector((state: RootState) => state.consultation.isLoading);
  const appointments = useSelector((state: RootState) => state.consultation.appointments);
  
  const populateData = () => {
    const params = paramsToObject(location.search);
    const pageNumber = parseInt(params && params.page ? params.page : 1);
    if (pageNumber && !isNaN(pageNumber)) {
      dispatch(getAppointmentAsync.request({ page: pageNumber }));
    } else {
      history.push(`/consultation`);
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetState());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const renderConsultationList = () => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
        </tr>
      );
    }

    if (appointments.length > 0) {
      return appointments.map((appointment, index) => {
        return (
          <tr key={index}>
            <td>{moment(appointment.visit_date).format('dddd, DD MMMM YYYY, HH:MM')}</td>
            <td>
              <div style={{ display: 'flex' }}>
                <XrAvatarAv name={appointment.doctor_detail?.name || ''} image={appointment.doctor_detail?.avatar || ''} size='small' />
                {appointment.doctor_detail?.name}
              </div>
            </td>
            <td>{appointment.patient_detail?.name}</td>
            <td>
              <XrButtonAv
                noBlock
                size='small'
                icon={faPen}
                disabled={isLoading}
                isLoading={isLoading}
                href={`/consultation/${appointment.id}`}
              />
            </td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td colSpan={7}>Tidak ada konsultasi yang ditemukan</td>
        </tr>
      );
    }
  }

  return (
    <PageWrapper>
      <ConsultationPageWrapper>
        <div>
          <DataTableWrapper>
            <div className="tablewrapper--title">
              Daftar Konsultasi
            </div>
            <div className="tablewrapper--table">
              <table>
                <thead>
                  <tr>
                    <th>Waktu</th>
                    <th>Dokter</th>
                    <th>Pasien</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {renderConsultationList()}
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              totalItem={meta.total}
              listPerPage={meta.per_page}
              currentIndex={meta.current_page}
              maxIndex={meta.last_page}
              url='consultation?page='
            />
          </DataTableWrapper>
        </div>
      </ConsultationPageWrapper>
    </PageWrapper>
  );
}

const ConsultationPageWrapper = styled.div`
  padding: 20px;
`;

export default ConsultationsPage;