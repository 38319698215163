import validate from "./validate";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import * as appointmentActions from "./actions";
import { toastifyProps } from "../../../helpers/config";
import { ErrorResponse } from "../../../interfaces/Response";
import { Appointment } from "../../../interfaces/Appointment";
import { put, call, takeEvery } from "redux-saga/effects";
import { apiPost, API_URL_APPOINTMENT, retrieveToken } from "../../../helpers/api";
import { Transaction } from "../../../interfaces/Transaction";

interface ResponseCreateTransaction {
  data: {
    appointment: Appointment,
    transaction: Transaction
  }
}

export function* func(action: ReturnType<typeof appointmentActions.createTransactionAsync.request>) {
  try {
    const errors = validate(action.payload, true);
    if (errors.errors && Object.keys(errors.errors).length > 0) {
      toast(`Gagal membuat transaksi, mohon periksa kembali formulir Anda.`, {
        ...toastifyProps,
        type: 'error',
      });
      yield put(appointmentActions.createTransactionAsync.failure(errors));
      return;
    }

    if (!action.payload.id_appointment) {
      const parsed: ErrorResponse = {
        status: 500,
        errors: {},
        message: 'Anda belum memilih janji temu.'
      }
      yield put(appointmentActions.createTransactionAsync.failure(parsed));
      return;
    }
    const token: string = yield retrieveToken();
    const resultCreateTransaction: Response = yield call(
      apiPost, 
      API_URL_APPOINTMENT.FINISH_APPOINTMENT(action.payload.id_appointment), 
      token, 
      action.payload
    );

    if (resultCreateTransaction.status === 200) { 
      const parsedCreateTransaction: ResponseCreateTransaction = yield call(resultCreateTransaction.json.bind(resultCreateTransaction));
      yield put(appointmentActions.createTransactionAsync.success({
        appointment: parsedCreateTransaction.data.appointment,
        transaction: parsedCreateTransaction.data.transaction
      }));
      toast(`Berhasil membuat transaksi adhoc. Transaksi adhoc tidak memiliki rekam medis.`, {
        ...toastifyProps,
        type: 'success',
      });
      yield put(push(`/payment/${parsedCreateTransaction.data.transaction.id}`));
      return;
    }
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(appointmentActions.createTransactionAsync.failure(parsed));
    return;
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(appointmentActions.createTransactionAsync.failure(parsed));
  }
}

export default function* sagaCreateTransaction() {
  yield takeEvery(appointmentActions.createTransactionAsync.request, func);
}
