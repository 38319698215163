import validate from "./validate";
import { toast } from "react-toastify";
import { push } from "connected-react-router";
import * as appointmentActions from "./actions";
import { toastifyProps } from "../../../helpers/config";
import { ErrorResponse } from "../../../interfaces/Response";
import { Appointment } from "../../../interfaces/Appointment";
import { put, call, takeEvery } from "redux-saga/effects";
import { apiPost, API_URL_APPOINTMENT, retrieveToken } from "../../../helpers/api";
import { Transaction } from "../../../interfaces/Transaction";

interface ResponseFinishAppointment {
  data: {
    appointment: Appointment,
    transaction: Transaction
  }
}

export function* func(action: ReturnType<typeof appointmentActions.finishAppointmentAsync.request>) {
  try {
    const errors = validate(action.payload);
    if (errors.errors && Object.keys(errors.errors).length > 0) {
      toast(`Gagal menyelesaikan janji temu dan rekam medis, mohon periksa kembali formulir Anda.`, {
        ...toastifyProps,
        type: 'error',
      });
      yield put(appointmentActions.finishAppointmentAsync.failure(errors));
      return;
    }

    if (!action.payload.id_appointment) {
      const parsed: ErrorResponse = {
        status: 500,
        errors: {},
        message: 'Anda belum memilih janji temu.'
      }
      yield put(appointmentActions.finishAppointmentAsync.failure(parsed));
      return;
    }
    const token: string = yield retrieveToken();
    const resultFinishAppointment: Response = yield call(
      apiPost, 
      API_URL_APPOINTMENT.FINISH_APPOINTMENT(action.payload.id_appointment), 
      token, 
      action.payload
    );

    if (resultFinishAppointment.status === 200) { 
      const parsedFinishAppointment: ResponseFinishAppointment = yield call(resultFinishAppointment.json.bind(resultFinishAppointment));
      yield put(appointmentActions.finishAppointmentAsync.success({
        appointment: parsedFinishAppointment.data.appointment,
        transaction: parsedFinishAppointment.data.transaction
      }));
      toast(`Berhasil menyelesaikan janji temu dan rekam medis. Pembayaran dapat dikonfirmasi pada menu pembayaran.`, {
        ...toastifyProps,
        type: 'success',
      });
      yield put(push('/consultation'));
      return;
    }
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(appointmentActions.finishAppointmentAsync.failure(parsed));
    return;
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(appointmentActions.finishAppointmentAsync.failure(parsed));
  }
}

export default function* sagaFinishAppointment() {
  yield takeEvery(appointmentActions.finishAppointmentAsync.request, func);
}
