import moment from "moment";
import styled from "styled-components";
import Card from "../../../components/layouts/Card";
import XrTextAv from "../../../components/XrTextAv";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducers";
import XrAvatarMv from "../../../components/XrAvatarMv";
import XrButtonAv from "../../../components/XrButtonAv";
import XrDatePillAv from "../../../components/XrDatePillAv";
import AppointmentStatus from "../molecules/AppointmentStatus";
import { EnumStatusAppointment } from "../../../interfaces/Enums";
import { parseStringToSeries } from "../../../helpers/fieldHelper";
import { clearSelectedAppointment, updateAppointmentAsync } from "../../../redux/reducers/appointments/actions";
import XrLoadingPlaceholder from "../../../components/XrLoadingPlaceholder";
import React, { useEffect, useState } from "react";
import XrInputAv from "../../../components/XrInputAv";
import { OptionDoctor } from "./AppointmentFormPage";
import { getUserAsync } from "../../../redux/reducers/models/users/users.actions";
import { showModal } from "../../../redux/reducers/notifications/actions";
import XrModalMv from "../../../components/XrModalMv";
import XrSpacingAv from "../../../components/XrSpacingAv";


const AppointmentManagePage = () => {
  const dispatch = useDispatch();
  
  const users = useSelector((state: RootState) => state.user.users);
  const isLoading = useSelector((state: RootState) => state.appointment.isLoadingSelection);
  const selectedAppointment = useSelector((state: RootState) => state.appointment.selectedAppointment);
  const selectedAppointmentId = useSelector((state: RootState) => state.appointment.selectedAppointmentId);

  const [selectedDoctor, setSelectedDoctor] = useState<OptionDoctor | null>(null);
  const [userOptions, setUserOptions] = useState<OptionDoctor[]>([]);
  const [isSelectingDoctor, setIsSelectingDoctor] = useState<boolean>(false);

  const allowedToChangeDoctor = [EnumStatusAppointment.CREATED, EnumStatusAppointment.QUEUE].includes((selectedAppointment && selectedAppointment.status) || -1);

  useEffect(() => {
    dispatch(getUserAsync.request({}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const newOptions: OptionDoctor[] = [];
    users.forEach(item => {
      if (item.id) {
        newOptions.push({
          value: item,
          label: item.name
        });
      }
    });
    setUserOptions(newOptions);
  }, [users]);

  useEffect(() => {
    if (selectedAppointment && selectedAppointment.doctor_detail) {
      setSelectedDoctor({
        label: selectedAppointment.doctor_detail.name,
        value: selectedAppointment.doctor_detail
      });
    }
  }, [selectedAppointment]);

  const onChangeSelectedDoctor = (e: OptionDoctor | null) => {
    setSelectedDoctor(e);
    setIsSelectingDoctor(false);
  }

  const actionCloseManagePage = () => {
    dispatch(clearSelectedAppointment());
  }

  const actionCheckin = () => {
    if (!selectedAppointment || !selectedAppointment.id || !selectedDoctor || !selectedDoctor.value.id) return;
    dispatch(updateAppointmentAsync.request({
      ...selectedAppointment,
      doctor_id: selectedDoctor.value.id,
      status: EnumStatusAppointment.QUEUE,
      visit_date: `${moment(selectedAppointment?.visit_date).format('YYYY-MM-DD HH:mm')}:00`
    }));
  }

  const actionProcess = () => {
    if (!selectedAppointment || !selectedDoctor || !selectedDoctor.value.id) return;
    dispatch(updateAppointmentAsync.request({
      ...selectedAppointment,
      doctor_id: selectedDoctor.value.id,
      status: EnumStatusAppointment.PROCESS,
      visit_date: `${moment(selectedAppointment?.visit_date).format('YYYY-MM-DD HH:mm')}:00`
    }));
  }

  const actionCancel = () => {
    if (!selectedAppointment || !selectedAppointment.patient_detail || !selectedDoctor) return;
    const patientName = selectedAppointment.patient_detail.name || '';
    const doctorName = selectedDoctor.label
    dispatch(showModal({
      title: `Membatalkan janji temu `,
      content: `Anda akan membatalkan janji temu ${patientName} dengan ${doctorName} yang akan dilaksanakan pada ${moment(selectedAppointment.visit_date).format('DD MMM, HH:MM')}. Janji yang dibatalkan tidak dapat dikelola kembali.`,
      buttonSubmit: 'Batalkan Janji',
      buttonCancel: 'Tutup Dialog',
      actionSubmit: () => dispatch(updateAppointmentAsync.request({
          ...selectedAppointment,
          status: EnumStatusAppointment.CANCEL,
          visit_date: `${moment(selectedAppointment?.visit_date).format('YYYY-MM-DD HH:mm')}:00`
        }))
    }));
  }

  const renderActionButtons = () => {
    if (!selectedAppointment) return <div />
    switch(selectedAppointment.status) {
      case EnumStatusAppointment.CREATED:
        return (
          <XrButtonAv
            size='medium'
            color='primary'
            caption='Check-in'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={actionCheckin}
          />
        );
      case EnumStatusAppointment.QUEUE:
        return (
          <XrButtonAv
            size='medium'
            color='primary'
            disabled={isLoading}
            isLoading={isLoading}
            caption='Teruskan ke Dokter'
            onClick={actionProcess}
          />
        );
      case EnumStatusAppointment.PROCESS:
      case EnumStatusAppointment.DONE:
      default:
        return <div />
    }
  }

  const toggleChangeDoctor = () => {
    setIsSelectingDoctor(!isSelectingDoctor);
  }

  const renderButtonCancel = () => {
    if (!selectedAppointment) return <div />
    if (![EnumStatusAppointment.CREATED, EnumStatusAppointment.QUEUE].includes(selectedAppointment.status)) return;
    return (
      <XrButtonAv
        size='medium'
        color='danger'
        disabled={isLoading}
        caption='Batalkan Janji'
        onClick={actionCancel}
      />
    );
  }

  const renderDoctorName = () => {
    return (
      <XrAvatarMv
        onClick={allowedToChangeDoctor ? toggleChangeDoctor : undefined}
        name={selectedAppointment?.doctor_detail?.name || ''}
        image={selectedAppointment?.doctor_detail?.avatar || ''}
        description={parseStringToSeries(selectedAppointment?.doctor_detail?.phone_number, 4) || ''}
      />
    );
  }

  const renderChangeDoctor = () => {
    if (allowedToChangeDoctor) {
      return (
        <Card style={{padding: '10px'}}>
          {isSelectingDoctor ? (
            <React.Fragment>
              <XrInputAv
                label='Dokter yang menangani'
                required={true}
                disabled={isLoading}
                isLoading={isLoading}
                selectProps={{
                  options: userOptions,
                  value: selectedDoctor,
                  onChange: onChangeSelectedDoctor,
                  placeholder: "Cari preferensi dokter"
                }}
              />
              <XrSpacingAv height={4} />
              <XrButtonAv
                size='medium'
                disabled={isLoading}
                caption='Batalkan Perubahan'
                onClick={() => setIsSelectingDoctor(false)}
              />
            </React.Fragment>
          ) : renderDoctorName()}
        </Card>
      );
    } else {
      return renderDoctorName();
    }
  }

  return (
    <XrModalMv
      description=''
      title='Buat Janji Baru'
      isVisible={Boolean(selectedAppointmentId)} actionClose={actionCloseManagePage}
    >
      <AppointmentManagePageWrapper className={`${selectedAppointmentId ? 'active' : ''}`}>
        <Card>
          <div className='card--header'>
            <div className='card--header--title'>
              <XrTextAv tag='h2' spaceBottom={5} variant='title'>
                Detail Janji Temu
              </XrTextAv>
            </div>
          </div>
          <div className="card--body">
            <XrTextAv tag='p' variant='description' spaceBottom={5}>
              Pasien
            </XrTextAv>
            <XrAvatarMv name={selectedAppointment?.patient_detail?.name || ''} description={parseStringToSeries(selectedAppointment?.patient_detail?.phone_number, 4)} />

            <br />
            <XrTextAv tag='p' variant='description' spaceBottom={5}>
              Akan bertemu dengan dokter
            </XrTextAv>
            { renderChangeDoctor() }

            <br />
            <XrTextAv tag='p' spaceBottom={5} variant='description'>
              Pada
            </XrTextAv>
            <XrLoadingPlaceholder width={267} height={36} isLoading={isLoading}>
              <div className='selected-schedule'>
                <XrDatePillAv date={moment(selectedAppointment?.visit_date)}/>
                <XrTextAv tag='p' variant='body'>
                  {moment(selectedAppointment?.visit_date).format('HH:MM')}
                </XrTextAv>
              </div>
            </XrLoadingPlaceholder>
          </div>
        </Card>
        <AppointmentStatus status={selectedAppointment?.status || 0} />
        <div className='appointmentManagePage__button-groups'>
          <XrTextAv tag='p' spaceBottom={10} variant='description'>
            Ambil Aksi
          </XrTextAv>
          {renderActionButtons()}
          {renderButtonCancel()}
          <XrButtonAv
            size='medium'
            caption='Kembali'
            disabled={isLoading}
            onClick={actionCloseManagePage}
          />
        </div>
      </AppointmentManagePageWrapper>
    </XrModalMv>
  )
}

const AppointmentManagePageWrapper = styled.div`
  & .selected-schedule {
    display: flex;
    align-items: center;
    & > *:first-child {
      margin-right: 15px;
    }
  }

  & .appointmentManagePage__button-groups {
    margin-top: 24px;
    & > * {
      margin-bottom: 5px;
    }
  }
`;

export default AppointmentManagePage;