import { ErrorResponse } from "../../../interfaces/Response";
import { finishAppointmentAsyncRequest } from "./types";

const validate = (payload: finishAppointmentAsyncRequest, isCreate?: boolean): ErrorResponse => {
  let errors: ErrorResponse = {
    message: '',
    status: 422,
    errors: {}
  };

  if (payload.medical_treatments.length === 0) {
    errors.errors.treatments = [ 'Daftar tindakan wajib diisi' ];
  }

  if (!isCreate && !payload.record.diagnosis.replace(/<\/?[^>]+(>|$)/g, '')) {
    errors.errors.diagnosis = [ 'Rekam medis wajib diisi' ];
  }
  
  return errors;
}

export default validate;