import moment from "moment";
import styled from "styled-components";
import { useEffect, useState } from "react";
import PaymentSummary from "./PaymentSummary";
import XrTabsMv from "../../components/XrTabsMv";
import { Option } from '../../interfaces/Options';
import XrButtonAv from "../../components/XrButtonAv";
import { RootState } from "../../redux/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import { parseMoney } from "../../helpers/fieldHelper";
import XrSpacingAv from "../../components/XrSpacingAv";
import XrDatePillAv from "../../components/XrDatePillAv";
import Pagination from "../../components/data/Pagination";
import paramsToObject from "../../helpers/paramsToObject";
import { useHistory, useLocation } from "react-router-dom";
import PageWrapper from "../../components/layouts/PageWrapper";
import { DataTableWrapper } from "../../components/data/DataTable";
import { faCheck, faPen } from "@fortawesome/free-solid-svg-icons";
import XrCardAv, { XrCardHeaderAv } from "../../components/XrCardAv";
import XrLoadingPlaceholder from "../../components/XrLoadingPlaceholder";
import { getTransactionsAsync, resetState, setSelectedDate } from "../../redux/reducers/payment/actions";
import {
  getToday,
  getThisWeekMonday,
  getThisWeekSunday,
  getLastDateThisYear,
  getFirstDateThisYear,
  getLastDateThisMonth,
  getFirstDateThisMonth,
} from "../../helpers/calendarUtils";
import XrInputAv from "../../components/XrInputAv";
import { API_URL_TRANSACTION } from "../../helpers/api";
import { BootstrapGrid } from "../../components/layouts/BootstrapGrid";
import { getUserAsync } from "../../redux/reducers/models/users/users.actions";


interface Tab {
  title: string,
  key: string,
  startDate: Date | null,
  endDate: Date | null,
}

const allTabs: Tab[] = [
  {
    title: 'Hari ini',
    key: 'today',
    startDate: getToday(),
    endDate: getToday()
  },
  {
    title: 'Minggu ini',
    key: 'week',
    startDate: getThisWeekMonday(),
    endDate: getThisWeekSunday()
  },
  {
    title: 'Bulan ini',
    key: 'month',
    startDate: getFirstDateThisMonth(),
    endDate: getLastDateThisMonth()
  },
  {
    title: 'Tahun ini',
    key: 'year',
    startDate: getFirstDateThisYear(),
    endDate: getLastDateThisYear()
  },
  {
    title: 'Semua',
    key: 'all',
    startDate: null,
    endDate: null
  },
];

const PaymentPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const users = useSelector((state: RootState) => state.user.users);
  const meta = useSelector((state: RootState) => state.payment.meta);
  const isLoading = useSelector((state: RootState) => state.payment.isLoading);
  const transactions = useSelector((state: RootState) => state.payment.transactions);
  const authorizedUser = useSelector((state: RootState) => state.authorization.authorizedUser);

  const [tabs, setTabs] = useState<Tab[]>(allTabs);
  const [selectedTab, setSelectedTab] = useState<string>('today');
  const [userOptions, setUserOptions] = useState<Option[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<Option>({ value: '', label: 'Semua' });
  const token = useSelector((state: RootState) => state.authorization.token);
  const selectedEndDate = useSelector((state: RootState) => state.payment.selectedEndDate);
  const selectedStartDate = useSelector((state: RootState) => state.payment.selectedStartDate);

  useEffect(() => {
    dispatch(getUserAsync.request({}));
    return () => {
      dispatch(resetState());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  useEffect(() => {
    const params = paramsToObject(location.search);
    const pageNumber = parseInt(params && params.page ? params.page : 1);
    if (pageNumber && !isNaN(pageNumber)) {
      const payload: any = selectedStartDate && selectedEndDate ? {
        endDate: moment(selectedEndDate).format('YYYY-MM-DD'),
        startDate: moment(selectedStartDate).format('YYYY-MM-DD'),
      } : {};
      if (selectedDoctor.value) {
        payload.doctorId = selectedDoctor.value;
      }
      dispatch(getTransactionsAsync.request({ ...payload, page: pageNumber }));
    } else {
      history.push(`/payment`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStartDate, selectedEndDate, selectedDoctor, location.search]);

  useEffect(() => {
    const newOptions: Option[] = [];
    newOptions.push({
      value: '',
      label: 'Semua'
    });
    users.forEach(item => {
      if (item.id) {
        newOptions.push({
          value: item.id?.toString(),
          label: item.name
        });
      }
    });
    setUserOptions(newOptions);
  }, [users]);

  const actionEditConsultation = (id: number | undefined) => {
    dispatch(resetState());
  }

  const onChangeTab = (key: string) => {
    setSelectedTab(key);
    setTabs([...allTabs]);
  }

  const onChangeSelectedDoctor = (e: Option) => {
    setSelectedDoctor(e);
  }

  useEffect(() => {
    const currentTab = tabs.find(i => i.key === selectedTab);
    if (currentTab && selectedTab !== 'custom') {
      dispatch(setSelectedDate(currentTab.startDate, currentTab.endDate));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  const onClickDownloadReport = () => {
    if (!selectedStartDate || !selectedEndDate) return;
    const payload: any = {
      end_date: moment(selectedEndDate).format('YYYY-MM-DD'),
      start_date: moment(selectedStartDate).format('YYYY-MM-DD'),
    };
    if (selectedDoctor.value) {
      payload.doctor_id = selectedDoctor.value;
    }
    fetch(API_URL_TRANSACTION.DOWNLOAD_TRANSACTION(payload), {
        method: 'POST',
        headers: new Headers({
          "Authorization": "Bearer " + token
        })
    })
    .then(response => response.blob())
    .then(blob => {
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "Monthly Report .xlsx";
        document.body.appendChild(a);
        a.click(); 
        a.remove();
    });
  }

  const onChangeCustomDate = (startDate: Date | null, endDate: Date | null) => {
    const startDateConv = moment(startDate).format('DD MMM');
    const endDateConv = moment(endDate).format('DD MMM');
    setTabs([
      ...allTabs,
      {
        title: `${startDateConv} - ${endDateConv}`,
        key: 'custom',
        startDate: null,
        endDate: null
      },
    ])
    setSelectedTab('custom');
  }

  const onChangeStartDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = moment(e.target.value).toDate();
    dispatch(setSelectedDate(date, selectedEndDate));
    onChangeCustomDate(date, selectedEndDate);
  }

  const onChangeEndDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = moment(e.target.value).toDate();
    dispatch(setSelectedDate(selectedStartDate, date));
    onChangeCustomDate(selectedStartDate, date);
  }

  const renderAction = (transaction: any) => {
    if (transaction.is_paid) {
      return (
        <XrButtonAv
          noBlock
          size='small'
          color={'success'}
          icon={faCheck}
          href={`/payment/${transaction.id}`}
          onClick={() => actionEditConsultation(transaction.id)}
        />
      )
    } else {
      return (
        <XrButtonAv
          noBlock
          size='small'
          icon={faPen}
          href={`/payment/${transaction.id}`}
          onClick={() => actionEditConsultation(transaction.id)}
        />
      )
    }
  }

  const renderCalendarFilter = () => {
    let reportText = ''
    const startDate = moment(selectedStartDate).format('DD MMMM YYYY');
    const endDate = moment(selectedEndDate).format('DD MMMM YYYY');
    if (selectedTab === 'all') {
      reportText = 'Laporan Keuangan Keseluruhan';
    } else {
      if (startDate === endDate) reportText = `Laporan Keuangan ${startDate}`
      else reportText = `Laporan Keuangan ${startDate} hingga ${endDate}`
    }
    return (
      <XrCardAv padding={0}>
        <XrCardHeaderAv title={reportText}>
          { renderDownloadButton() }
        </XrCardHeaderAv>
        <BootstrapGrid style={{ padding: '16px' }}>
          <div className='row'>
            <div className='col-md-4'>
              <XrInputAv
                type="date"
                label="Tanggal Awal"
                isLoading={isLoading}
                onChange={onChangeStartDate}
                disabled={isLoading || selectedTab === 'all'}
                value={selectedTab === 'all' ? '' : moment(selectedStartDate).format('YYYY-MM-DD')}
              />
            </div>
            <div className='col-md-4'>
              <XrInputAv
                type="date"
                label="Tanggal Akhir"
                isLoading={isLoading}
                onChange={onChangeEndDate}
                disabled={isLoading || selectedTab === 'all'}
                value={selectedTab === 'all' ? '' : moment(selectedEndDate).format('YYYY-MM-DD')}
              />
            </div>
            <div className='col-md-4'>
              <XrInputAv
                label='Dokter yang Menangani'
                required={true}
                disabled={isLoading}
                isLoading={isLoading}
                selectProps={{
                  options: userOptions,
                  value: selectedDoctor,
                  onChange: onChangeSelectedDoctor,
                  placeholder: "Cari preferensi dokter"
                }}
              />
            </div>
          </div>
        </BootstrapGrid>
      </XrCardAv>
    );
  }

  const renderDownloadButton = () => {
    if (!authorizedUser || !authorizedUser.roles || selectedTab === 'all') return <div />;
    const permission = ['Root Admin', 'Owner'];
    const roles = authorizedUser.roles.map(r => r.name);
    if (selectedStartDate && selectedEndDate && roles.filter(i => permission.includes(i)).length > 0) {
      return (
        <XrButtonAv
          noBlock
          size='small'
          onClick={onClickDownloadReport}
          caption='Unduh Laporan'
        />
      )
    }
    return <div />
  }

  const renderPaymentSummary = () => {
    if (!authorizedUser || !authorizedUser.roles || selectedTab === 'all') return <div />;
    const permission = ['Root Admin', 'Owner'];
    const roles = authorizedUser.roles.map(r => r.name);
    if (roles.filter(i => permission.includes(i)).length > 0) {
      return <PaymentSummary />
    }
    return <div />
  }

  const renderPaymentList = () => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
        </tr>
      );
    }

    if (transactions.length > 0) {
      return transactions.map((transaction, index) => {
        return (
          <tr key={index}>
            <td>
              <XrDatePillAv date={moment(transaction.created_at)} /> &nbsp;&nbsp;
              {moment(transaction.created_at).format('HH:mm')}
            </td>
            <td>{transaction.invoice_no}</td>
            <td>{transaction.doctor_detail?.name}</td>
            <td>{transaction.patient_detail?.name}</td>
            <td>{parseMoney(transaction.total_price)}</td>
            <td>
              { renderAction(transaction) }
            </td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td colSpan={7}>Tidak ada janji temu yang ditemukan</td>
        </tr>
      );
    }
  }

  return (
    <PageWrapper>
      <Wrapper>
        <div>
          <XrCardAv padding={0}>
            <XrCardHeaderAv title='Laporan Keuangan'>
              <div className='PaymentPage__options'>
                <XrTabsMv tabs={tabs} activeKey={selectedTab} onChangeKey={onChangeTab} />
              </div>
            </XrCardHeaderAv>
            <div style={{ padding: '12px' }}>
              {renderCalendarFilter()}
              {renderPaymentSummary()}
            </div>
          </XrCardAv>
          <XrSpacingAv height={8} />
          <DataTableWrapper id="transaction-history">
            <div className="tablewrapper--title">
              <div>
                Riwayat Transaksi
              </div>
            </div>
            <div className="tablewrapper--table">
              <table>
                <thead>
                  <tr>
                    <th>Tanggal Diterbitkan</th>
                    <th>Nomor Invoice</th>
                    <th>Dokter</th>
                    <th>Pasien</th>
                    <th>Jumlah</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  {renderPaymentList()}
                </tbody>
              </table>
            </div>
            <Pagination
              isLoading={isLoading}
              totalItem={meta.total}
              listPerPage={meta.per_page}
              currentIndex={meta.current_page}
              maxIndex={meta.last_page}
              url='payment?page='
            />
          </DataTableWrapper>
        </div>
      </Wrapper>
    </PageWrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  & .PaymentPage {
    &__options {
      align-self: flex-end;
      margin-bottom: -1px;
    }
  }
`;

export default PaymentPage;