import { FunctionComponent } from "react";
import styled from "styled-components";

type XrTextAvColor = 'default' | 'washout';
type XrTextAvVariant = 'body' | 'title' | 'description';
type XrTextAvTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'a' | 'span';

interface XrTextAvProps {
  tag?: XrTextAvTag;
  color?: XrTextAvColor
  variant?: XrTextAvVariant;
  lineHeightPercent?: number;
  spaceBottom?: number;
  className?: string;
  children: JSX.Element | JSX.Element[] | string | any;
}

interface XrTextAvWrapperProps {
  color?: XrTextAvColor
  variant?: XrTextAvVariant;
}

const XrTextAv: FunctionComponent<XrTextAvProps> = (props: XrTextAvProps) => {
  const _tag = props.tag ? props.tag : 'p';

  const getLineHeight = () => {
    if (isNaN(props.lineHeightPercent || NaN)) return '100%';
    return `${props.lineHeightPercent}%`;
  }

  return (
    // eslint-disable-next-line react/jsx-pascal-case
    <_tag style={{...baseStyle, lineHeight: getLineHeight(), marginBottom: `${props.spaceBottom ? props.spaceBottom : 0}px`}} className={props.className}>
      <XrTextAvWrapper variant={props.variant}>
        {props.children}
      </XrTextAvWrapper>
    </_tag>
  );
}

const baseStyle: React.CSSProperties = {
  margin: 0,
  padding: 0,
  lineHeight: '100%',
};

const XrTextAvWrapper = styled.span<XrTextAvWrapperProps>`
  ${(props) =>
    (() => {
      if (props.variant) {
        switch (props.variant) {
          case 'title':
            return `
              font-size: 18px;
              color: rgb(44,44,44);
            `;
          case 'description':
            return `
              line-height: 125%;
              font-size: 16px;
              color: #B9B9B9;
            `;
          case 'body':
            return `
              font-size: 16px;
              color: rgb(44,44,44);
            `;
        }
      }
      return `
        font-size: 16px;
        color: rgb(44,44,44);
      `;
    })()};
`;

export default XrTextAv;