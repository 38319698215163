import { Bouncer } from "../interfaces/Bouncer";

const parseBouncer = (): Bouncer => {
  const _tempData: any = {
    token: '',
    tokens: [],
  };
  try {
    const storageString = localStorage.getItem('bouncer');
    if (storageString) {
      const storageData = JSON.parse(storageString);
      Object.keys(_tempData).forEach(key => {
        if (storageData[key]) _tempData[key] = storageData[key];
      });
      return _tempData;
    } else {
      throw Error('empty_storage');
    }
  } catch (ex) {
    return _tempData;
  }
}

export default parseBouncer;