import XrButtonAv from "../../components/XrButtonAv";
import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import paramsToObject from "../../helpers/paramsToObject";
import Pagination from "../../components/data/Pagination";
import PageWrapper from "../../components/layouts/PageWrapper";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DataTableWrapper } from "../../components/data/DataTable";
import { BootstrapGrid } from "../../components/layouts/BootstrapGrid";
import { getTreatmentAsync, searchTreatmentAsync } from "../../redux/reducers/models/treatments/treatments.actions";
import { parseMoney} from "../../helpers/fieldHelper";
import styled from "styled-components";
import XrLoadingPlaceholder from "../../components/XrLoadingPlaceholder";
import XrInputAv from "../../components/XrInputAv";

const TreatmentsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const meta = useSelector((state: RootState) => state.treatment.meta)
  const isLoading = useSelector((state: RootState) => state.treatment.isLoading);
  const treatments = useSelector((state: RootState) => state.treatment.treatments);

  const [txtSearch , setTxtSearch] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const populateData = () => {
    const params = paramsToObject(location.search);
    const pageNumber = parseInt(params && params.page ? params.page : 1);
    if (pageNumber && !isNaN(pageNumber)) {
      if (txtSearch.length >= 3 ) {
        dispatch(searchTreatmentAsync.request({ page: pageNumber, name: txtSearch }));
      } else {
        dispatch(getTreatmentAsync.request({ page: pageNumber }));
      }
    } else {
      history.push(`/treatments`);
    }
  };

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, searchQuery]);

  const onChangeTxtSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(e.target.value);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchQuery(txtSearch);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [txtSearch]);

  const renderTreatmentList = () => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
        </tr>
      );
    }

    if (treatments.length > 0) {
      return treatments.map(treatment => {
        return (
          <tr key={treatment.id}>
            <td><Link to={`/treatments/${treatment.id}`}>{treatment.name}</Link></td>
            <td>{parseMoney(treatment.price)}</td>
            <td>{treatment.duration} menit</td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td colSpan={7}>No treatment data was found</td>
        </tr>
      );
    }
  }

  return (
    <PageWrapper>
      <TreatmentPageWrapper>
        <BootstrapGrid>
          <div className="row">
            <div className="col-lg-8">
              <DataTableWrapper>
                <div className="tablewrapper--title">
                  <div>
                    Daftar Tindakan
                  </div>
                  <div style={{ width: '450px' }}>
                    <XrInputAv
                      type='text'
                      label='Cari Nama Tindakan'
                      inline={true}
                      required={true}
                      value={txtSearch}
                      disabled={isLoading}
                      isLoading={isLoading}
                      onChange={onChangeTxtSearch}
                    />
                  </div>
                  <div>
                    <XrButtonAv noBlock size='medium' color='primary' href="/treatments/create" caption="Tindakan Baru" />
                  </div>
                </div>
                <div className="tablewrapper--table">
                  <table>
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Harga</th>
                        <th>Durasi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderTreatmentList()}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  isLoading={isLoading}
                  totalItem={meta.total}
                  listPerPage={meta.per_page}
                  currentIndex={meta.current_page}
                  maxIndex={meta.last_page}
                  url='treatments?page='
                />
              </DataTableWrapper>
            </div>
            <div className="col-lg-4">
            </div>
          </div>
        </BootstrapGrid>
      </TreatmentPageWrapper>
    </PageWrapper>
  );
}

const TreatmentPageWrapper = styled.div`
  padding: 20px;
`;

export default TreatmentsPage;