interface Props {
  height: number;
}

const XrSpacingAv = (props: Props) => {
  return (
    <div style={{ height: `${props.height}px` }} />
  )
}

export default XrSpacingAv;