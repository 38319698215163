import { updateMedicalRecordAsyncResponse } from "./types";
import * as medicalRecordActions from "./medicalRecords.actions";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../../interfaces/Response";
import { apiPut, API_URL_MEDICAL_RECORD, retrieveToken } from "../../../../helpers/api";

export function* updateMedicalRecords(action: ReturnType<typeof medicalRecordActions.updateMedicalRecordAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    if (action.payload.id) {
      const result: Response = yield call(apiPut, API_URL_MEDICAL_RECORD.UPDATE_MEDICAL_RECORD(action.payload.id), token, action.payload);
      if (result.status === 200) {
        const parsed: updateMedicalRecordAsyncResponse = yield call(result.json.bind(result));
        yield put(medicalRecordActions.updateMedicalRecordAsync.success(parsed));
        return;
      } else {
        const parsed: ErrorResponse = yield call(result.json.bind(result));
        yield put(medicalRecordActions.updateMedicalRecordAsync.failure(parsed));
        return;
      }
    } else {
      const parsed: ErrorResponse = {
        status: 500,
        errors: {},
        message: 'Terjadi kesalahan pada server'
      }
      yield put(medicalRecordActions.updateMedicalRecordAsync.failure(parsed));  
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(medicalRecordActions.updateMedicalRecordAsync.failure(parsed));
  }
}

export default function* medicalRecordsSagaUpdateRecord() {
  yield takeEvery(medicalRecordActions.updateMedicalRecordAsync.request, updateMedicalRecords);
}
