import React, { useEffect, useRef } from "react";
import XrTextAv from "../XrTextAv";
import XrModalMv from "../XrModalMv";
import styled from "styled-components";
import XrButtonAv from "../XrButtonAv";
import { RootState } from "../../redux/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import { showModal } from "../../redux/reducers/notifications/actions";

const GlobalModal = () => {
  const dispatch = useDispatch();
  const btnSubmit = useRef<HTMLButtonElement>(null);
  
  const modal = useSelector((state: RootState) => state.notification.modal);

  useEffect(() => {
    if (modal && btnSubmit && btnSubmit.current) {
      btnSubmit.current.focus();
    }
  }, [modal])

  const actionCloseModal = () => {
    dispatch(showModal(null));
  }

  const actionExecute = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    modal?.actionSubmit();
    dispatch(showModal(null));
  }

  return (
    <GlobalModalWrapper>
      <XrModalMv
        title={modal?.title}
        description={''}
        isVisible={Boolean(modal !== null)} actionClose={actionCloseModal}
      >
        <React.Fragment>
          <XrTextAv tag='p' variant='body' lineHeightPercent={150}>
            {modal?.content || ''}
          </XrTextAv>

          <form onSubmit={actionExecute} className='button-wrapper'>
            <XrButtonAv
              submit
              noBlock
              size='medium'
              color='primary'
              ref={btnSubmit}
              disabled={false}
              isLoading={false}
              caption={modal?.buttonSubmit || ''}
            />
            &nbsp;
            <XrButtonAv
              noBlock
              size='medium'
              disabled={false}
              isLoading={false}
              onClick={actionCloseModal}
              caption={modal?.buttonCancel || ''}
            />
          </form>
        </React.Fragment>
      </XrModalMv>
    </GlobalModalWrapper>
  );
};

const GlobalModalWrapper = styled.div`
  & .button-wrapper {
    margin-top: 20px;
  }
`;

export default GlobalModal