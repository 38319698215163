import moment from "moment";
import styled from "styled-components";
import { useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { COLOR } from "../../../helpers/theme";
import { User } from "../../../interfaces/User";
import React, { useEffect, useState } from "react";
import XrTextAv from "../../../components/XrTextAv";
import { Patient } from "../../../interfaces/Patient";
import XrInputAv from "../../../components/XrInputAv";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducers";
import XrButtonAv from "../../../components/XrButtonAv";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import XrTextEditorMv from "../../../components/XrTextEditorMv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fieldErrorMessage } from "../../../helpers/formErrorHandler";
import { BootstrapGrid } from "../../../components/layouts/BootstrapGrid";
import { getUserAsync } from "../../../redux/reducers/models/users/users.actions";
import { getPatientAsync } from "../../../redux/reducers/models/patients/patients.actions";
import { createAppointmentAsync, hideAppointmentForm } from "../../../redux/reducers/appointments/actions";
import AppointmentPatientPage from "./AppointmentPatientPage";
import XrModalMv from "../../../components/XrModalMv";

export interface OptionDoctor { value: User; label: string; }
export interface OptionPatient { value: Patient; label: string; }

const AppointmentFormPage = () => {
  const dispatch = useDispatch();

  const users = useSelector((state: RootState) => state.user.users);
  const errors = useSelector((state: RootState) => state.appointment.errors);
  const patients = useSelector((state: RootState) => state.patient.patients);
  const appointmentDate = useSelector((state: RootState) => state.appointment.selectedDate);
  const isLoading = useSelector((state: RootState) => state.appointment.isLoadingSelection);
  const shouldShowForm = useSelector((state: RootState) => state.appointment.showAppointmentForm);
  const createdPatient = useSelector((state: RootState) => state.patient.createdPatient);

  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    content: '',
  });

  const [startTime, setStartTime] = useState<string>('');
  const [selectedDate, setSelectedDate] = useState<string>('');
  const [userOptions, setUserOptions] = useState<OptionDoctor[]>([]);
  const [patientOptions, setPatientOptions] = useState<OptionPatient[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState<OptionDoctor | null>();
  const [selectedPatient, setSelectedPatient] = useState<OptionPatient | null>();
  const [isAddingPatient, setIsAddingPatient] = useState<boolean>(false);

  useEffect(() => {
    if (createdPatient) {
      setIsAddingPatient(false);
      setSelectedPatient({
        label: createdPatient.name,
        value: createdPatient
      });
    }
  }, [createdPatient])

  useEffect(() => {
    const newOptions: OptionDoctor[] = [];
    users.forEach(item => {
      if (item.id) {
        newOptions.push({
          value: item,
          label: item.name
        });
      }
    });
    setUserOptions(newOptions);
  }, [users]);

  useEffect(() => {
    const newOptions: OptionPatient[] = [];
    patients.forEach(item => {
      if (item.id) {
        newOptions.push({
          value: item,
          label: item.name
        });
      }
    });
    setPatientOptions(newOptions);
  }, [patients]);

  const populateData = () => {
    dispatch(getUserAsync.request({ page: 0 }));
    dispatch(getPatientAsync.request({}));
  };
  
  useEffect(() => {
    setSelectedDate(appointmentDate);
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shouldShowForm) {
      setIsAddingPatient(false);
    }
  }, [shouldShowForm])
  
  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDate(e.target.value);
  }

  const onChangeSelectedDoctor = (e: OptionDoctor | null) => {
    setSelectedDoctor(e);
  }

  const onChangeSelectedPatient = (e: OptionPatient | null) => {
    setSelectedPatient(e);
  }

  const onChangeStartTime = (e:React.ChangeEvent<HTMLInputElement>) => {
    setStartTime(e.target.value);
  }

  const actionCloseManagePage = () => {
    dispatch(hideAppointmentForm());
  }

  const actionCreatePatient = () => {
    setIsAddingPatient(true);
  }

  const actionCreateAppointment = () => {
    const selectedDateFormated = moment(selectedDate).format('YYYY-MM-DD');
    const finalTime = startTime ? `${selectedDateFormated} ${startTime}:00` : ''
    dispatch(createAppointmentAsync.request({
      status: 1,
      visit_date: finalTime,
      agenda: (editor && editor.getHTML()) || '',
      doctor_id: (selectedDoctor && selectedDoctor.value.id) || undefined,
      doctor_detail: (selectedDoctor && selectedDoctor.value) || undefined,
      patient_id: (selectedPatient && selectedPatient.value.id) || undefined,
      patient_detail: (selectedPatient && selectedPatient.value) || undefined,
    }))
  }

  const actionCancelCreatePatient = () => {
    setIsAddingPatient(false);
  }

  return (
    <XrModalMv
      description=''
      size='very-large'
      title='Buat Janji Baru'
      isVisible={shouldShowForm} actionClose={actionCloseManagePage}
    >
      <AppointmentFormPageWrapper>
        <BootstrapGrid>
          <div className='row'>
            <div className='col-md-5'>
              <XrTextAv tag='h2' variant='title'>
                Detail Janji
              </XrTextAv>
              <br />
              <XrInputAv
                type='date'
                label='Tanggal'
                required={true}
                isLoading={isLoading}
                value={selectedDate}
                disabled={isLoading}
                onChange={onChangeDate}
              />
              <XrInputAv
                type='time'
                label='Waktu Mulai'
                required={true}
                value={startTime}
                disabled={isLoading}
                isLoading={isLoading}
                onChange={onChangeStartTime}
                errorMessage={fieldErrorMessage('visit_date', errors)}
              />
              <XrInputAv
                label='Dokter yang menangani'
                required={true}
                disabled={isLoading}
                isLoading={isLoading}
                selectProps={{
                  options: userOptions,
                  value: selectedDoctor,
                  onChange: onChangeSelectedDoctor,
                  placeholder: "Cari preferensi dokter"
                }}
                errorMessage={fieldErrorMessage('doctor_id', errors)}
              />
              <XrInputAv
                label='Cari nama pasien'
                required={true}
                disabled={isLoading}
                isLoading={isLoading}
                selectProps={{
                  options: patientOptions,
                  value: selectedPatient,
                  onChange: onChangeSelectedPatient,
                  placeholder: "Cari pasien"
                }}
                errorMessage={fieldErrorMessage('patient_id', errors)}
              />

              <p style={{ marginBottom: '20px' }}>Belum ada pasien? &nbsp;<span className='appointmentFormPage__clickableText' onClick={actionCreatePatient}><FontAwesomeIcon icon={faUserPlus} /> &nbsp;Buat pasien baru disini.</span></p>
            </div>
            <div className='col-md-7'>
              <XrTextAv tag='h2' variant='title'>
                Agenda & Keluhan Pasien
              </XrTextAv>
              <br />

              <XrTextEditorMv editor={editor} />
            </div>
          </div>
          {isAddingPatient ? <AppointmentPatientPage isAddingPatient={isAddingPatient} setIsAddingPatient={setIsAddingPatient} actionCancelCreatePatient={actionCancelCreatePatient} /> : null}
        </BootstrapGrid>

        <div className='appointmentFormPage__button-groups'>
          <XrButtonAv
            noBlock
            size='medium'
            color='primary'
            caption='Buat Janji'
            disabled={isLoading || isAddingPatient}
            onClick={actionCreateAppointment}
          />
          &nbsp;
          <XrButtonAv
            noBlock
            size='medium'
            caption='Kembali'
            disabled={isLoading}
            onClick={actionCloseManagePage}
          />
        </div>
      </AppointmentFormPageWrapper>
    </XrModalMv>
  )
}

const AppointmentFormPageWrapper = styled.div`
  & .input--field {
    margin-bottom: 10px;
  }

  & .selected-schedule {
    display: flex;
    align-items: center;
    & > *:first-child {
      margin-right: 15px;
    }
  }

  & .appointmentFormPage__button-groups {
    margin-top: 25px;
    & > * {
      margin-bottom: 5px;
    }
  }

  & .appointmentFormPage__clickableText {
    cursor: pointer;
    color: ${COLOR.PRIMARY};
    transition: 0.15s all;

    &:hover {
      color: ${COLOR.SECONDARY}
    }
  } 
`;

export default AppointmentFormPage;