import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../components/Spinner";
import Card from "../../../components/layouts/Card";
import { RootState } from "../../../redux/rootReducers";
import { DataTableWrapper } from "../../../components/data/DataTable";
import XrTextAv from "../../../components/XrTextAv";
import moment from "moment";
import XrAvatarAv from "../../../components/XrAvatarAv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import { setMedicalRecord } from "../../../redux/reducers/models/patients/patients.actions";
import XrLoadingPlaceholder from "../../../components/XrLoadingPlaceholder";

const PatientsManageMedicalTreatment = () => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => state.patient.isLoading);
  const selectedPatient = useSelector((state: RootState) => state.patient.selectedPatient);
  const medicalRecord = useSelector((state: RootState) => state.patient.selectedMedicalRecord);
  const medicalTreatments = useSelector((state: RootState) => state.patient.medicalTreatments);

  const actionCloseRecord = () => {
    dispatch(setMedicalRecord(null));
  }

  const renderPatientMedicalRecord = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={6} className="tablewrapper--loading">
            <div className="tablewrapper--loading--caption">
              <Spinner size="small" />
              <span>Memuat data...</span>
            </div>
          </td>
        </tr>
      );
    }

    if (!medicalRecord) return <div />

    return (
      <tr>
        <td>
          <div style={{ display: 'flex' }}>
            <XrAvatarAv name={medicalRecord.doctor_detail?.name || ''} size='small' />&nbsp;
            {medicalRecord.doctor_detail?.name}
          </div>
        </td>
        <td>{moment(medicalRecord.visited_at).format('dddd, DD MMMM YYYY, HH:mm')}</td>
        <td>
          <div dangerouslySetInnerHTML={{ __html: medicalRecord.diagnosis }} />
        </td>
      </tr>
    )
  }

  const renderPatientMedicalTreatment = () => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
        </tr>
      );
    }
    
    if (medicalTreatments.length > 0) {
      return medicalTreatments.map(record => {
        return (
          <tr key={record.treatment_id}>
            <td>{record.treatment_detail?.name}</td>
            <td>{record.quantity}</td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td colSpan={2}>Tidak ada data rekam jejak medis ditemukan</td>
        </tr>
      );
    }
  }

  if (!medicalRecord) return <div />

  return (
    <Wrapper>
      <Card>
        <div className='card--header'>
          <div className='card--header--title'>
            <XrTextAv tag='h2' variant='title'>
              <FontAwesomeIcon
                className='PatientsManageMedicalTreatment__back'
                icon={faChevronLeft}
                onClick={actionCloseRecord}
              />
              Detail Rekam Medis
            </XrTextAv>
          </div>
        </div>
        <div className="card--body">
          <DataTableWrapper style={{ marginTop: 0 }}>
            <div className="tablewrapper--title">
              Rekam Medis { selectedPatient?.name }
            </div>
            <div className="tablewrapper--table">
              <table>
                <thead>
                  <tr>
                    <th>Dokter</th>
                    <th>Tanggal Kunjungan</th>
                    <th>Diagnosis</th>
                  </tr>
                </thead>
                <tbody>
                  { renderPatientMedicalRecord() }
                </tbody>
              </table>
            </div>
          </DataTableWrapper>
          <br />
          <DataTableWrapper style={{ marginTop: 0 }}>
            <div className="tablewrapper--title">
              Layanan yang Diambil
            </div>
            <div className="tablewrapper--table">
              <table>
                <thead>
                  <tr>
                    <th>Nama Layanan</th>
                    <th>Jumlah Layanan</th>
                  </tr>
                </thead>
                <tbody>
                  { renderPatientMedicalTreatment() }
                </tbody>
              </table>
            </div>
          </DataTableWrapper>
        </div>
      </Card>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  
  & .PatientsManageMedicalTreatment {
    &__back {
      cursor: pointer;
      margin-right: 12px;
    }
  }
`;

export default PatientsManageMedicalTreatment;