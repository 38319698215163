import moment from 'moment';
import Card from "../../../components/layouts/Card";
import { Patient } from "../../../interfaces/Patient";
import React, { useEffect, useState } from "react";
import { EnumGender } from "../../../interfaces/Enums";
import "react-datepicker/dist/react-datepicker.css";
import { RootState } from '../../../redux/rootReducers';
import XrButtonAv from '../../../components/XrButtonAv';
import { useDispatch, useSelector } from "react-redux";
import { OptionsGender } from "../../../interfaces/Options";
import XrTextAv from '../../../components/XrTextAv';
import styled from 'styled-components';
import XrInputAv from '../../../components/XrInputAv';
import { fieldErrorMessage } from '../../../helpers/formErrorHandler';
import { BootstrapGrid } from '../../../components/layouts/BootstrapGrid';
import { createPatientAsync, setError } from '../../../redux/reducers/models/patients/patients.actions';

interface AppointmentPatientPageProps {
  isAddingPatient: boolean;
  setIsAddingPatient: (val: boolean) => void;
  actionCancelCreatePatient: () => void;
}

const AppointmentPatientPage = (props: AppointmentPatientPageProps) => {
  const dispatch = useDispatch();
  
  const errors = useSelector((state: RootState) => state.patient.error);
  const isLoading = useSelector((state: RootState) => state.patient.isLoading);
  const selectedPatient = useSelector((state: RootState) => state.patient.selectedPatient);

  const [patient, setPatient] = useState<Patient>({
    job: '',
    nik: '',
    name: '',
    address: '',
    phone_number: '',
    kartu_keluarga: '',
    gender: EnumGender.MALE,
    bod: moment(new Date()).format('YYYY-MM-DD'),
  });

  const [optionGender, setOptionGender] = useState<{label: string; value: number} | null>(OptionsGender[0]);

  useEffect(() => {
    setPatient({
      job: '',
      nik: '',
      name: '',
      address: '',
      phone_number: '',
      kartu_keluarga: '',
      gender: EnumGender.MALE,
      bod: moment(new Date()).format('YYYY-MM-DD'),
    });
  }, [selectedPatient]);

  useEffect(() => {
    if (optionGender) {
      setPatient({
        ...patient,
        gender: optionGender?.value
      });
    } else {
      setPatient({
        ...patient,
        gender: 1
      });
      setOptionGender(OptionsGender[0]);
    }
    // ignoring patient for deps, will cause performance issue
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [optionGender]);

  const clearError = (fieldName: string) => {
    if (errors) {
      dispatch(setError({
        ...errors,
        errors: {
          ...errors.errors,
          [`${fieldName}`]: []
        }
      }));
    }
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('name');
    setPatient({
      ...patient,
      name: e.target.value
    });
  };

  const onChangeNik = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('nik');
    setPatient({
      ...patient,
      nik: e.target.value
    });
  };

  const onChangeDateOfBirth = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('bod');
    setPatient({
      ...patient,
      bod: e.target.value
    });
  };

  const onChangeGender = (e: any) => {
    clearError('gender');
    setOptionGender(e);
  };

  const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('address');
    setPatient({
      ...patient,
      address: e.target.value
    });
  };

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('phone_number');
    setPatient({
      ...patient,
      phone_number: e.target.value
    });
  };

  const actionCreate = () => {
    dispatch(createPatientAsync.request(patient));
  }

  const actionCancelCreatePatient = () => {
    props.actionCancelCreatePatient();
  }

  const renderForm = () => {
    return (
      <React.Fragment>
        <div className='row'>
          <div className='col-md-4'>
            <XrInputAv
              type='text'
              label='Nama Pasien'
              required={true}
              value={patient.name}
              disabled={isLoading}
              isLoading={isLoading}
              onChange={onChangeName}
              errorMessage={fieldErrorMessage('name', errors)}
            />
          </div>
          <div className='col-md-4'>
            <XrInputAv
              type='text'
              label='NIK'
              required={true}
              value={patient.nik}
              disabled={isLoading}
              isLoading={isLoading}
              onChange={onChangeNik}
              errorMessage={fieldErrorMessage('nik', errors)}
            />
          </div>
          <div className='col-md-4'>
            <XrInputAv
              type='text'
              label='Alamat'
              required={true}
              value={patient.address}
              disabled={isLoading}
              isLoading={isLoading}
              onChange={onChangeAddress}
              errorMessage={fieldErrorMessage('address', errors)}
            />
          </div>
        </div>
        <BootstrapGrid>
          <div className='row'>
            <div className='col-md-4'>
              <XrInputAv
                type='date'
                label='Tanggal Lahir'
                required={true}
                value={patient.bod}
                disabled={isLoading}
                isLoading={isLoading}
                onChange={onChangeDateOfBirth}
                errorMessage={fieldErrorMessage('bod', errors)}
              />
            </div>
            <div className='col-md-4'>
              <XrInputAv
                type='text'
                label='Telepon'
                required={true}
                value={patient.phone_number}
                disabled={isLoading}
                isLoading={isLoading}
                onChange={onChangePhoneNumber}
                errorMessage={fieldErrorMessage('phone_number', errors)}
              />
            </div>
            <div className='col-md-4'>
              <XrInputAv
                label='Jenis Kelamin'
                required={true}
                disabled={isLoading}
                isLoading={isLoading}
                selectProps={{
                  options: OptionsGender,
                  value: optionGender,
                  onChange: onChangeGender,
                  placeholder: ""
                }}
                errorMessage={fieldErrorMessage('gender', errors)}
              />
            </div>
          </div>
        </BootstrapGrid>
        <div className="formSubmit">
          <XrButtonAv
            noBlock
            size='medium'
            color='primary'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={actionCreate}
            caption={`Tambah Pasien`}
          />
          &nbsp;
          <XrButtonAv
            noBlock
            size='medium'
            caption='Kembali'
            disabled={isLoading}
            onClick={actionCancelCreatePatient}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <AppointmentPatientPageWrapper>
      <Card>
        <div className='card--header'>
          <div className='card--header--title'>
            <XrTextAv tag='h2' variant='title'>
              <span>Tambah Pasien</span>
            </XrTextAv>
          </div>
        </div>
        <div className="card--body">
          { renderForm() }
        </div>
      </Card>
    </AppointmentPatientPageWrapper>
  );
}


const AppointmentPatientPageWrapper = styled.div`

`;

export default AppointmentPatientPage;