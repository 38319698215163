import { getTreatmentAsyncResponse } from "./types";
import * as treatmentActions from "./actions";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../interfaces/Response";
import { apiGet, API_URL_TREATMENT, retrieveToken } from "../../../helpers/api";

function* func(action: ReturnType<typeof treatmentActions.getTreatmentsAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    const searchQuery = `${API_URL_TREATMENT.GET_TREATMENTS}?show_all=true`;
    const result: Response = yield call(apiGet, searchQuery, token);
    if (result.status === 200) {
      const parsed: getTreatmentAsyncResponse = yield call(result.json.bind(result));
      yield put(treatmentActions.getTreatmentsAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(treatmentActions.getTreatmentsAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(treatmentActions.getTreatmentsAsync.failure(parsed));
  }
}

export default function* sagaGetTreatment() {
  yield takeEvery(treatmentActions.getTreatmentsAsync.request, func);
}
