import { getUserAsyncResponse } from "./types";
import * as userActions from "./authorization.actions";
import { apiPost, API_URL_AUTH, retrieveToken } from "../../../../helpers/api";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../../interfaces/Response";

export function* getUser(
  action: ReturnType<typeof userActions.getUserAsync.request>
) {
  try {
    const token: string = yield retrieveToken();
    const result: Response = yield call(apiPost, API_URL_AUTH.BY_TOKEN, token, {});

    if (result.status === 200) {
      const parsed: getUserAsyncResponse = yield call(result.json.bind(result));
      yield put(userActions.getUserAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(userActions.getUserAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server',
    }
    yield put(userActions.getUserAsync.failure(parsed));
  }
}

export default function* authorizationSagaGetUser() {
  yield takeEvery(userActions.getUserAsync.request, getUser);
}
