import "./App.css"  ;
import "./toastify.css"  ;
import 'moment/locale/id';
import moment from 'moment'
import { useEffect } from "react";
import styled from "styled-components";
import RoutesAuth from "./pages/RoutesAuth";
import RoutesAdmin from "./pages/RoutesAdmin";
import { useHistory, useLocation } from "react-router-dom";
import { RootState } from "./redux/rootReducers";
import parseBouncer from "./helpers/parseBouncer";
import { useDispatch, useSelector } from "react-redux";
import { setLoading } from "./redux/reducers/models/users/users.actions";
import { getUserAsync, updateToken } from "./redux/reducers/models/authorization/authorization.actions";
import { ToastContainer } from "react-toastify";
import ReactTooltip from "react-tooltip";

const App = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const authorizedUser = useSelector((state: RootState) => state.authorization.authorizedUser);

  useEffect(() => {
    if (authorizedUser && location.pathname === '/') {
      if (authorizedUser.is_doctor) {
        history.push('/appointments');
      } else {
        history.push('/consultation');
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizedUser?.id]);

  useEffect(() => {
    moment.locale('id');
    ReactTooltip.rebuild();
    const bouncer = parseBouncer();
    if (bouncer.token) {
      setTimeout(() => {
        dispatch(updateToken(bouncer.token));
        dispatch(getUserAsync.request({ token: bouncer.token }));
      }, 1000);
    } else {
      dispatch(setLoading(false));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Root>
      <ToastContainer
        draggable
        rtl={false}
        closeOnClick
        pauseOnHover
        autoClose={3000}
        pauseOnFocusLoss
        newestOnTop={false}
        position="top-center"
        hideProgressBar={false}
      />
      {(() => {
        if (authorizedUser) {
          return <RoutesAdmin />;
        } else {
          return <RoutesAuth />;
        }
      })()}
      <ReactTooltip />
    </Root>
  );
};

const Root = styled.div`
  background: var(--color-base);
  width: 100%;
  height: 100vh;
`;

export default App;
