import React from "react";
import styled from "styled-components";
import { COLOR } from "../helpers/theme";

export interface Tab {
  title: string;
  key: string;
}

interface XrTabsMvProps {
  tabs: Tab[];
  activeKey: string;
  onChangeKey: (key: string) => void;
}

const XrTabsMv = (props: XrTabsMvProps) => {
  const renderTabs = () => {
    return props.tabs.map((item, index) => {
      return (
        <button className={`tab-item ${props.activeKey === item.key ? 'active': ''}`} key={index} onClick={() => props.onChangeKey(item.key)}>
          <span className={`tab-item__text`}>
            {item.title}
          </span>
          <div className={`tab-item__border`} />
        </button>
      )
    });
  }

  return (
    <XrTabsMvWrapper>
      {renderTabs()}
    </XrTabsMvWrapper>
  )
}

const XrTabsMvWrapper = styled.div`
  border-bottom: 1px solid ${COLOR.BORDER};
  
  & .tab-item {
    border: none;
    cursor: pointer;
    font-size: 16px;
    background: none;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
    transition: 0.15s all;
    color: ${COLOR.TEXT_DESCRIPTIVE};

    & .tab-item__border {
      margin-top: 10px;
      margin-bottom: -1px;
      border-top: 4px solid transparent;
      border-top-left-radius: 54px;
      border-top-right-radius: 54px;
    }

    &.active {
      color: ${COLOR.PRIMARY};
      & .tab-item__border {
        border-color: ${COLOR.PRIMARY};
      }
    }
  }
`;

export default XrTabsMv;