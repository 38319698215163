import styled from "styled-components";
import { COLOR } from "../../helpers/theme";

export const DataTableWrapper = styled.div`
  border-radius: 5px;
  border: 1px solid #e2e2e2;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);

  & > .tablewrapper--title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 700;
    color: ${COLOR.BUTTON_TEXT};
    background-color: #fff;
    padding: 18px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #e2e2e2;
  }

  .tablewrapper--loading {
    text-align: center;

    & > .tablewrapper--loading--caption {
      display: flex;
      align-items: center;
      justify-content: center;

      & > span {
        margin-left: 5px;
      }
    }
  }

  & > .tablewrapper--table {
    width: 100%;

    & .table--actions {
      
    }

    & > table {
      border-spacing: 0;
      min-width: 100%;
      text-align: left;
      border-collapse: collapse;

      & > thead {
        background: #fff;
        border-bottom: 1px solid #e2e2e2;
      }

      & > thead.field-filter {
        & tr > th > * {
          width: 100%;
          color: #b3b3b3;
          padding: 5px 10px 5px 10px;
          border: 1px solid #e2e2e2;
          border-radius: var(--corner-radius-small);
          -webkit-appearance: none;
        }
      }

      & > thead > tr > th {
        font-size: 16px;
        cursor: pointer;
        padding: var(--table-tr-space);
      }

      & > tbody > tr {
        padding-bottom: 5px;
        border-bottom: 1px solid #e2e2e2;
      }

      & > tbody > tr:last-child {
        border-bottom: 1px solid transparent !important;
      }

      & > tbody > tr > td {
        background: #fff;
        font-size: 16px;
        padding: 15px 20px 15px 20px;

        & .broken {
          font-weight: 800;
          color: red;
        }
      }

      & > tbody > tr:last-child td:first-child {
        border-bottom-left-radius: var(--corner-radius-small);
      }

      & > tbody > tr:last-child td:last-child {
        border-bottom-right-radius: var(--corner-radius-small);
      }

      .sortArrow {
        width: 8px;
        margin-left: 5px;
      }
    }
  }
`;
