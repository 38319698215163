import { EnumStatusAppointment } from "./Enums";
import { Patient } from "./Patient";
import { User } from "./User";

export interface Appointment {
  id?: number; 
  agenda: string;
  status: number;
  doctor_id: number;
  patient_id: number;
  visit_date: string;
  doctor_detail?: User;
  patient_detail?: Patient;
}

export const OptionsStatusAppointment = [
  { value: EnumStatusAppointment.CREATED, label: 'Menunggu Kehadiran' },
  { value: EnumStatusAppointment.QUEUE, label: 'Menunggu Antrian' },
  { value: EnumStatusAppointment.PROCESS, label: 'Diperiksa Dokter' },
  { value: EnumStatusAppointment.DONE, label: 'Selesai' },
  { value: EnumStatusAppointment.CANCEL, label: 'Janji Dibatalkan' },
]