import { select } from "redux-saga/effects";
import { EnumStatusAppointment } from "../interfaces/Enums";
import { RootState } from "../redux/rootReducers";
import paramsFromObject from "./paramsFromObject";

// export const BASE_URL = 'http://localhost/candyd-server/public';
// export const BASE_URL = 'http://localhost:8000';
export const BASE_URL = 'https://api.onedentalclinicserang.com';
export const API_URL = `${BASE_URL}/api`;
export const API_URL_AUTH = {
  BY_TOKEN: `${API_URL}/user/verify`,
  BY_CREDENTIALS: `${API_URL}/user/login`
};
export const API_URL_PATIENT = {
  GET_PATIENTS: (page?: number) => `${API_URL}/patient${page ? `?page=${page}` : '?show_all=true'}`,
  CREATE_PATIENTS: `${API_URL}/patient`,
  SELECT_PATIENT: (id: number) => `${API_URL}/patient/${id}`,
  SEARCH_PATIENTS: (page: number, name: string) => `${API_URL}/patient/search?page=${page}&name=${name}`,  
};
export const API_URL_USER = {
  GET_USERS: (page: number) => `${API_URL}/user?show_all=true`,
  CREATE_USERS: `${API_URL}/user`,
  UPDATE_USER: (id: number) => `${API_URL}/user/${id}`,
  SELECT_USER: (id: number) => `${API_URL}/user/${id}`,
  SEARCH_USERS: (page: number, name: string) => `${API_URL}/user/search?page=${page}&name=${name}`,
};
export const API_URL_APPOINTMENT = {
  GET_APPOINTMENTS: `${API_URL}/appointment`,
  GET_APPOINTMENTS_DOCTOR: (page: number, doctorId: number) => `${API_URL}/appointment?page=${page}&status=${EnumStatusAppointment.PROCESS}&doctor_id=${doctorId}`,
  CREATE_APPOINTMENTS: `${API_URL}/appointment`,
  UPDATE_APPOINTMENT: (id: number) => `${API_URL}/appointment/${id}`,
  SELECT_APPOINTMENT: (id: number) => `${API_URL}/appointment/${id}`,
  FINISH_APPOINTMENT: (id: number) => `${API_URL}/appointment/${id}/finish`,
};
export const API_URL_SCHEDULE = {
  CREATE_SCHEDULE: `${API_URL}/schedule-doctor`,
  GET_SCHEDULE: `${API_URL}/schedule-doctor`,
  SEARCH_SCHEDULE: `${API_URL}/schedule-doctor/search`,
  UPDATE_SCHEDULE: (id: number) => `${API_URL}/schedule-doctor/${id}`,
  SELECT_SCHEDULE: (id: number) => `${API_URL}/schedule-doctor/${id}`,
};
export const API_URL_TREATMENT = {
  GET_TREATMENTS: `${API_URL}/treatment`,
  CREATE_TREATMENT: `${API_URL}/treatment`,
  UPDATE_TREATMENT: (id: number) => `${API_URL}/treatment/${id}`,
  SELECT_TREATMENT: (id: number) => `${API_URL}/treatment/${id}`,
  SEARCH_TREATMENTS: (page: number, name: string) => `${API_URL}/treatment/search?page=${page}&name=${name}`,
};
export const API_URL_MEDICAL_RECORD = {
  GET_MEDICAL_RECORDS: (page: number, patientId: number) => `${API_URL}/medical-record?patient_id=${patientId}&page=${page}`,
  CREATE_MEDICAL_RECORD: `${API_URL}/medical-record`,
  UPDATE_MEDICAL_RECORD: (id: number) => `${API_URL}/medical-record/${id}`,
  SELECT_MEDICAL_RECORD: (id: number) => `${API_URL}/medical-record/${id}`,
};
export const API_URL_MEDICAL_TREATMENT = {
  GET_MEDICAL_TREATMENTS: (page: number, medicalRecordId: number) => `${API_URL}/medical-treatment?medical_record_id=${medicalRecordId}&page=${page}`,
  CREATE_MEDICAL_TREATMENT: `${API_URL}/medical-treatment`,
  UPDATE_MEDICAL_TREATMENT: (id: number) => `${API_URL}/medical-treatment/${id}`,
  SELECT_MEDICAL_TREATMENT: (id: number) => `${API_URL}/medical-treatment/${id}`,
};
export const API_URL_TRANSACTION = {
  GET_TRANSACTION: `${API_URL}/transaction`,
  CREATE_TRANSACTION: `${API_URL}/transaction`,
  UPDATE_TRANSACTION: (id: number) => `${API_URL}/transaction/${id}`,
  SELECT_TRANSACTION: (id: number) => `${API_URL}/transaction/${id}`,
  DOWNLOAD_TRANSACTION: (params: { start_date: string, end_date: string, doctor_id?: number }) => `${API_URL}/report/download${objectToParams(params)}`,
};
export const API_URL_REPORT_DAILY = {
  GET_REPORT_DAILY: (payload: any) => `${API_URL}/report?${paramsFromObject(payload)}`,
};

/**
 * Execute API call with method get
 * @param url 
 * @param token 
 * @returns 
 */
export const apiGet = (url: string, token: string) => {
  const payload: any = {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json; charset=utf-8'
    },
  };
  return fetch(url, payload);
};

/**
 * Execute API call with method post
 * @param url 
 * @param token 
 * @param data 
 * @returns promise
 */
export const apiPost = (url: string, token: string, data: any) => {
  let payload: any = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': "application/json; charset=UTF-8;"
    }
  }

  if (token) {
    payload = {
      ...payload,
      headers: {
        ...payload.headers,
        'Authorization': `Bearer ${token}`,
      }
    }
  }
  return fetch(url, payload);
};

/**
 * Execute API for file upload
 * @param url 
 * @param token 
 * @param data 
 * @returns promise
 */
export const apiUpload = (url: string, token: string, data: any) => {
  return fetch(url, {
    method: 'POST',
    headers: {
      'Authorization': `Basic ${btoa(`${token}:`)}`,
    },
    body: data,
  });
};

/**
 * Execute API call with method put
 * @param url 
 * @param token 
 * @param data 
 * @returns promise
 */
export const apiPut = (url: string, token: string, data: any) => {
  return fetch(url, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': "application/json; charset=UTF-8"
    },
    body: JSON.stringify(data),
  });
};

/**
 * Convert object into url parameters.
 * @param object 
 * @returns ?param1=value&param2=value
 */
export const objectToParams = (object: any): string => {
  let str = "";
  for (var key in object) {
    if (str !== "") {
      str += "&";
    }
    str += key + "=" + encodeURIComponent(object[key]);
  }
  return "?" + str;
}

/**
 * Retrieve current user token.
 * @returns token
 */
export function* retrieveToken() {
  const tokenState = (state: RootState) => state.authorization.token;
  const tokenAuth: string = yield select(tokenState);
  const token: string = `${tokenAuth}`;
  return token;
}