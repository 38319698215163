import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { createBrowserHistory } from "history";
import { routerMiddleware } from "connected-react-router";
import { makeRootReducer } from "./rootReducers";
import { rootSaga } from "./rootSaga";

// Create a history of your choosing (we're using a browser history in this case)
const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();

// Build the middleware for intercepting and dispatching navigation actions
const routeMiddleware = routerMiddleware(history);

// Combine middleware
const middlewares = [routeMiddleware, sagaMiddleware];

const store = createStore(
  makeRootReducer(history),
  compose(applyMiddleware(...middlewares))
);

/**
 * Saga middleware
 */
sagaMiddleware.run(rootSaga);

export { history, store };
