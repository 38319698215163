import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getMedicalRecordAsync } from "../../../redux/reducers/models/medical-records/medicalRecords.actions";
import PatientsManageMedicalRecord from "./PatientsManageMedicalRecord";
import PatientsManageMedicalTreatment from "./PatientsManageMedicalTreatment";

interface Props {
  patientId: number;
}

const PatientsMedicalRecord = (props: Props) => {
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(getMedicalRecordAsync.request({ patient_id: props.patientId }));
  });

  return (
    <React.Fragment>
      <PatientsManageMedicalRecord />
      <PatientsManageMedicalTreatment />
    </React.Fragment>
  );
}

export default PatientsMedicalRecord;