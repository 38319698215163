import styled from "styled-components";
import { COLOR } from "../../helpers/theme";

const Card = styled.div`
  border-radius: 5px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: #FFFFFF;
  border: 1px solid #DADADA;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);

  & > .card--header {
    padding: 16px;
    padding-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  & > .card--title {
    
    /* border-bottom: 1px solid #DADADA; */

    & > .card--title-title {
      margin: 0;
      font-size: 18px;
      font-weight: bold;
    }
    
    & > .card--title-description {
      margin: 0;
      font-size: 16px;
      color: #B9B9B9;
      line-height: 125%;
      font-weight: normal;
    }
  }

  & > .card--body {
    padding: 16px;

    &.flex {
      display: flex;
      justify-content: space-between;
    }
  }

  & > .card--footer {
    background: #F3F3F3;
    border-top: 1px solid #DADADA;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 12px 16px 12px 16px;

    &.flex {
      display: flex;
      justify-content: space-between;
    }
  }
  
  &.hoverable {
    cursor: pointer;
    transition: 0.15s all;

    &:hover {
      border-color: ${COLOR.SECONDARY};
    }
  }
`;

export default Card;