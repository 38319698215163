import { Switch, Route } from "react-router-dom";
import AppointmentsPage from "./appointment/AppointmentPage";
import AppointmentCreatePage from "./appointment/create/AppointmentCreatePage";
import PatientsPage from "./patients/PatientsPage";
import PatientsManagePage from "./patients/PatientsManagePage";
import UsersManagePage from "./users/UsersManagePage";
import UsersPage from "./users/UsersPage";
import SchedulesManagePage from "./schedules/SchedulesManagePage";
import SchedulesPage from "./schedules/SchedulesPage";
import TreatmentsManagePage from "./treatment/TreatmentsManagePage";
import TreatmentsPage from "./treatment/TreatmentsPage";
import ChangeUserPage from "./authentication/change-user/ChangeUserPage";
import AddNewSessionPage from "./authentication/change-user/AddNewSession";
import ConsultationsPage from "./consultation/ConsultationPage";
import ConsultationManagePage from "./consultation/manage/ConsultationManagePage";
import LogoutPage from "./authentication/LogoutPage";
import PaymentPage from "./payment/PaymentPage";

const RoutesAdmin = () => {
  return (
    <Switch>
      {/* Change User */}
      <Route path="/change-user">
        <ChangeUserPage />
      </Route>

      {/* Change User */}
      <Route path="/new-session">
        <AddNewSessionPage />
      </Route>

      {/* Schedules */}
      <Route path="/schedules/:id">
        <SchedulesManagePage />
      </Route>
      <Route path="/schedules">
        <SchedulesPage />
      </Route>

      {/* Appointment */}
      <Route path="/appointments/create">
        <AppointmentCreatePage />
      </Route>
      <Route path="/appointments/:id">
        <AppointmentsPage />
      </Route>
      <Route path="/appointments">
        <AppointmentsPage />
      </Route>

      {/* Consultation */}
      <Route path="/consultation/:id">
        <ConsultationManagePage />
      </Route>
      <Route path="/consultation">
        <ConsultationsPage />
      </Route>

      {/* Payment */}
      <Route path="/payment/create/:id">
        <ConsultationManagePage transactionMode={true} />
      </Route>
      <Route path="/payment/:id">
        <ConsultationManagePage transactionMode={true} />
      </Route>
      <Route path="/payment">
        <PaymentPage />
      </Route>

      {/* Treatment */}
      <Route path="/treatments/:id">
        <TreatmentsManagePage />
      </Route>
      <Route path="/treatments">
        <TreatmentsPage />
      </Route>

      {/* Users */}
      <Route path="/users/:id">
        <UsersManagePage />
      </Route>
      <Route path="/users">
        <UsersPage />
      </Route>

      {/* Patients */}
      <Route path="/patients/:id">
        <PatientsManagePage />
      </Route>
      <Route path="/patients">
        <PatientsPage />
      </Route>

      {/* Logout */}
      <Route path="/logout">
        <LogoutPage />
      </Route>
    </Switch>
  );
};

export default RoutesAdmin;