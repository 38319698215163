import XrTextAv from './XrTextAv';
import Card from './layouts/Card';
import styled from 'styled-components';
import { LAYER } from '../helpers/theme';
import { FunctionComponent, useEffect } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type ModalSize = 'regular' | 'very-large';

export type ModalProps = {
  title?: string;
  description?: string;
  isVisible: boolean;
  children: JSX.Element;
  size?: ModalSize;
  actionClose: () => void;
};

const XrModalMv: FunctionComponent<ModalProps> = (props: ModalProps) => {
  useEffect(() => {
    if (props.isVisible) {
      document.getElementsByTagName('html')[0].style.overflow = 'hidden';  
    } else {
      document.getElementsByTagName('html')[0].style.overflow = '';
    }
  }, [props.isVisible]);

  if (!props.isVisible) return <div />

  return (
    <ModalWrapper className={`${props.isVisible ? 'visible' : ''}`} size={props.size}>
      <div className='modal--content'>
        <Card className='modal--content--inner'>
          <div className='modal--header'>
            <div className='card--header--title'>
              <XrTextAv tag='h2' variant='title' lineHeightPercent={75} spaceBottom={4}>
                {props.title || ''}
              </XrTextAv>
              <XrTextAv tag='p' variant='description'>
                {props.description || ''}
              </XrTextAv>
            </div>
            <div className='card--header--action'>
              <div className='modal-action--close' onClick={() => props.actionClose()}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>
          </div>
          <div className='card--body'>
            {props.children}
          </div>
        </Card>
      </div>
    </ModalWrapper>
  );
}

interface StyleProps {
  size?: ModalSize;
};

const ModalWrapper = styled.div<StyleProps>`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  transition: 0.25s all;
  padding-top: 75px;
  padding-bottom: 75px;
  z-index: ${LAYER.MODAL};
  background: rgba(0, 0, 0, 0.8);
  overflow-y: scroll;

  & > .modal--content {
    width: 100%;
    margin: auto;
    position: relative;
    z-index: ${LAYER.MODAL_CONTENT};
    max-width: ${props => {
      switch(props.size) {
        case 'very-large':
          return '900px';
        default:
          return '500px';
      }
    }};

    & > .modal--content--inner {
      width: 100%;
      margin: 0px;

      & > .modal--header {
        padding: 20px;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;
        padding-bottom: 0px;
      }

      & .card--title-description {
        padding-right: 60px;
      }
    }
  }

  & .modal-action--close {
    min-width: 40px;
    text-align: right;
    cursor: pointer;
  }
`;

export default XrModalMv;