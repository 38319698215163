import { put, call, takeEvery } from "redux-saga/effects";
import { createScheduleAsync } from "./actions";
import { ErrorResponse } from "../../../interfaces/Response";
import { createScheduleAsyncResponse } from "./types";
import { apiPost, API_URL_SCHEDULE, retrieveToken } from "../../../helpers/api";

function* func(action: ReturnType<typeof createScheduleAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    const result: Response = yield call(apiPost, API_URL_SCHEDULE.CREATE_SCHEDULE, token, action.payload);
    if (result.status === 200) {
      const parsed: createScheduleAsyncResponse = yield call(result.json.bind(result));
      yield put(createScheduleAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(createScheduleAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(createScheduleAsync.failure(parsed));
  }
}

export default function* sagaCreateSchedule() {
  yield takeEvery(createScheduleAsync.request, func);
}
