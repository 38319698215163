export const SET_ERROR = "@patients/SET_ERROR";
export const SET_MEDICAL_RECORD = "@patients/SET_MEDICAL_RECORD";

export const GET_PATIENT_REQUEST = "@patients/GET_PATIENT_REQUEST";
export const GET_PATIENT_SUCCESS = "@patients/GET_PATIENT_SUCCESS";
export const GET_PATIENT_FAILURE = "@patients/GET_PATIENT_FAILURE";

export const SELECT_PATIENT_REQUEST = "@patients/SELECT_PATIENT_REQUEST";
export const SELECT_PATIENT_SUCCESS = "@patients/SELECT_PATIENT_SUCCESS";
export const SELECT_PATIENT_FAILURE = "@patients/SELECT_PATIENT_FAILURE";

export const CREATE_PATIENT_REQUEST = "@patients/CREATE_PATIENT_REQUEST";
export const CREATE_PATIENT_SUCCESS = "@patients/CREATE_PATIENT_SUCCESS";
export const CREATE_PATIENT_FAILURE = "@patients/CREATE_PATIENT_FAILURE";

export const SEARCH_PATIENT_REQUEST = "@patients/SEARCH_PATIENT_REQUEST";
export const SEARCH_PATIENT_SUCCESS = "@patients/SEARCH_PATIENT_SUCCESS";
export const SEARCH_PATIENT_FAILURE = "@patients/SEARCH_PATIENT_FAILURE";

export const GET_MEDICAL_TREATMENT_REQUEST = "@patients/GET_MEDICAL_TREATMENT_REQUEST";
export const GET_MEDICAL_TREATMENT_SUCCESS = "@patients/GET_MEDICAL_TREATMENT_SUCCESS";
export const GET_MEDICAL_TREATMENT_FAILURE = "@patients/GET_MEDICAL_TREATMENT_FAILURE";