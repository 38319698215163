import { authenticateAsyncResponse } from "./types";
import * as userActions from "./authorization.actions";
import { put, call, takeEvery } from "redux-saga/effects";
import parseBouncer from "../../../../helpers/parseBouncer";
import { apiPost, API_URL_AUTH } from "../../../../helpers/api";
import { ErrorResponse } from "../../../../interfaces/Response";

export function* authenticate(
  action: ReturnType<typeof userActions.authenticateAsync.request>
) {
  try {
    const result: Response = yield call(apiPost, API_URL_AUTH.BY_CREDENTIALS, '', action.payload);
    if (result.status === 200) {
      const parsed: authenticateAsyncResponse = yield call(result.json.bind(result));
      const bouncer = parseBouncer();
      bouncer.token = parsed.data.token;
      bouncer.tokens = [parsed.data.token];
      localStorage.setItem('bouncer', JSON.stringify(bouncer));
      yield put(userActions.authenticateAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(userActions.authenticateAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(userActions.authenticateAsync.failure(parsed));
  }
}

export default function* authorizationSagaAuthenticate() {
  yield takeEvery(userActions.authenticateAsync.request, authenticate);
}
