import { FunctionComponent } from "react";
import styled from "styled-components";
import TopNavigation from "../navigation/TopNavigation";
import SidebarNavigation from "../navigation/SidebarNavigation";
import GlobalModal from "./GlobalModal";

export type PageWrapperProps = {
  children: JSX.Element;
};

const PageWrapper: FunctionComponent<PageWrapperProps> = (props: {
  children: JSX.Element;
}) => {
  return (
    <Root>
      <GlobalModal />
      <div className='Root__main'>
        <SidebarNavigation />
        <div className="Root__main-contentWrapper">
          <TopNavigation />
          <div className="mainContent">{props.children}</div>
        </div>
      </div>
    </Root>
  );
};

export const Root = styled.div`
  min-width: 100%;
  min-height: 100vh;
  .Root {
    &__main {
      display: flex;
      &-contentWrapper {
        width: 100%;
      }
    }
  }
`;

export default PageWrapper;
