import * as actions from "./actions";
import * as types from "./actionTypes";
import { ActionType, createReducer } from "typesafe-actions";
import { ConsultationReducerType } from "./types";

export type ConsultationActions = ActionType<typeof actions>;

export const initialState: ConsultationReducerType = {
  error: null,
  treatments: [],
  appointments: [],
  isLoading: false,
  createdConsultation: null,
  selectedAppointment: null,
  currentStep: 'medical-record',
  meta: {
    total: 0,
    per_page: 0,
    last_page: 0,
    current_page: 0,
  }
};

export const ConsultationReducer = createReducer<
  ConsultationReducerType,
  ConsultationActions
>(initialState)
  /**
   * Handles get appointment
   */
  .handleAction(actions.getAppointmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    appointments: [],
    createdConsultation: null,
  }))
  .handleAction(actions.getAppointmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    meta: action.payload.meta,
    appointments: action.payload.data,
  }))
  .handleAction(actions.getAppointmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Handles select appointment
   */
  .handleAction(actions.selectAppointmentAsync.request, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    selectedAppointment: null,
    createdConsultation: null,
    currentStep: 'medical-record',
  }))
  .handleAction(actions.selectAppointmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    selectedAppointment: action.payload.data,
  }))
  .handleAction(actions.selectAppointmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Handles update appointment
   */
  .handleAction(actions.updateAppointmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    createdConsultation: null,
    selectedAppointment: null,
  }))
  .handleAction(actions.updateAppointmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    selectedAppointment: action.payload.data,
  }))
  .handleAction(actions.updateAppointmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Handles get treatment
   */
   .handleAction(actions.getTreatmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    treatments: [],
    createdConsultation: null,
  }))
  .handleAction(actions.getTreatmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    treatments: action.payload.data,
  }))
  .handleAction(actions.getTreatmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Handles finish appointment
   */
   .handleAction(actions.finishAppointmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    createdConsultation: null,
  }))
  .handleAction(actions.finishAppointmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdConsultation: {
      appointment: action.payload.appointment,
      transaction: action.payload.transaction
    }
  }))
  .handleAction(actions.finishAppointmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Handles create transaction
   */
   .handleAction(actions.createTransactionAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    createdConsultation: null,
  }))
  .handleAction(actions.createTransactionAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdConsultation: {
      appointment: action.payload.appointment,
      transaction: action.payload.transaction
    }
  }))
  .handleAction(actions.createTransactionAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Handles other reducer setters
   */
  .handleAction(types.SET_ERROR as any, (state, action) => ({
    ...state,
    error: action.payload.value
  }))
  .handleAction(types.RESET_STATE as any, (state, action) => ({
    error: null,
    treatments: [],
    appointments: [],
    isLoading: false,
    createdConsultation: null,
    selectedAppointment: null,
    currentStep: 'medical-record',
    meta: {
      total: 0,
      per_page: 0,
      last_page: 0,
      current_page: 0,
    }
  }))
  .handleAction(types.CLEAR_SELECTED_APPOINTMENT as any, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    availableSchedule: [],
    currentStep: 'medical-record',
  }))
  .handleAction(types.SET_CURRENT_STEP as any, (state, action) => ({
    ...state,
    currentStep: action.payload.value
  }));
