import { getAppointmentAsyncResponse } from "./types";
import * as appointmentActions from "./actions";
import { put, call, takeEvery, select } from "redux-saga/effects";
import { ErrorResponse } from "../../../interfaces/Response";
import { apiGet, API_URL_APPOINTMENT, retrieveToken } from "../../../helpers/api";
import { RootState } from "../../rootReducers";

function* func(action: ReturnType<typeof appointmentActions.getAppointmentAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    const page = action.payload.page ? action.payload.page : 1;
    const doctorId: number = yield select((state: RootState) => state.authorization.authorizedUser?.id);
    if (!doctorId) {
      yield put(appointmentActions.getAppointmentAsync.failure({
        status: 404,
        message: 'User not found',
        errors: { }
      }));
      return;
    }

    const result: Response = yield call(apiGet, API_URL_APPOINTMENT.GET_APPOINTMENTS_DOCTOR(page, doctorId), token);

    if (result.status === 200) {
      const parsed: getAppointmentAsyncResponse = yield call(result.json.bind(result));
      yield put(appointmentActions.getAppointmentAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(appointmentActions.getAppointmentAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(appointmentActions.getAppointmentAsync.failure(parsed));
  }
}

export default function* sagaGetAppointment() {
  yield takeEvery(appointmentActions.getAppointmentAsync.request, func);
}
