import Select from 'react-select';
import Timeform from '../time-form/Timeform';
import React, { useEffect, useState } from 'react';
import Card from '../../../../components/layouts/Card';
import { useDispatch, useSelector } from 'react-redux';
import XrModalMv from '../../../../components/XrModalMv';
import XrButtonAv from '../../../../components/XrButtonAv';
import { RootState } from '../../../../redux/rootReducers';
import { parseDay } from '../../../../helpers/calendarUtils';
import { ScheduleTime } from '../../../../interfaces/Schedule';
import { Option, OptionsDay } from '../../../../interfaces/Options';
import { InputWrapper, SelectStyle } from '../../../../components/forms/FormComponents';
import { setScheduleDaily } from '../../../../redux/reducers/schedules/actions';
import XrTextAv from '../../../../components/XrTextAv';

interface ScheduleManageWeeklyModalProps {
  modalActive: boolean;
  selectedDay: Option | null;
  setModalActive: (valueL: boolean) => void;
  setSelectedDay: (value: Option | null) => void;
}

const ScheduleManageWeeklyModal = (props: ScheduleManageWeeklyModalProps) => {
  const dispatch = useDispatch();

  const scheduleDaily = useSelector((state: RootState) => state.schedule.scheduleDaily);

  const [selectedTime, setSelectedTime] = useState<ScheduleTime[]>([]);
  
  useEffect(() => {
    const day = props.selectedDay;
    if (day) {
      const existingDay = scheduleDaily.find(i => i.day === parseInt(day.value));
      if (existingDay) {
        setSelectedTime(existingDay.timestamp);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedDay])

  const onChangeDay = (e: Option | null) => {
    if (!e) return;
    const day = parseInt(e.value);
    if (isNaN(day)) return;
    const existingDay = scheduleDaily.find(i => i.day === day);
    if (existingDay) {
      setSelectedTime(existingDay.timestamp);
    }
    props.setSelectedDay(e);
  }

  const actionCloseScheduleDialog = () => {
    props.setModalActive(false);
  }

  const actionAddSchedule = () => {
    try {
      if (!props.selectedDay) throw new Error('Hari belum dipilih');
      const day = parseInt(props.selectedDay.value)
      if (isNaN(day)) throw new Error('Hari tidak valid');
      if (selectedTime.length === 0) {
        actionCloseScheduleDialog();
      }
      const newScheduleDaily = [...scheduleDaily];
      const duplicate = scheduleDaily.findIndex(i => i.day === day);
      if (duplicate >= 0) {
        newScheduleDaily[duplicate].timestamp = [...selectedTime];
        dispatch(setScheduleDaily(newScheduleDaily));
      } else {
        newScheduleDaily.push({
          day,
          timestamp: [...selectedTime]
        })
        dispatch(setScheduleDaily(newScheduleDaily));
      }
      setSelectedTime([]);
      actionCloseScheduleDialog();
      props.setSelectedDay(null);
    } catch (e) {
      console.log(e);
    }
  }
  
  return (
    <XrModalMv
      title="Tambah Jadwal"
      description="Jadwal rutin mingguan yang akan diterapkan dalam 1 bulan. Dapat memilih dari jadwal sebelumnya atau membuat baru."
      isVisible={props.modalActive} actionClose={actionCloseScheduleDialog}
    >
      <React.Fragment>
        <div className="form-group">
          <InputWrapper>
            <div className="input--field select">
              <label>Hari</label>
              <Select
                styles={SelectStyle}
                options={OptionsDay}
                value={props.selectedDay}
                placeholder="Pilih hari"
                onChange={(e: any) => onChangeDay(e)}
              />
            </div>
          </InputWrapper>
        </div>
        <Card>
          <div className='card--header'>
            <div className='card--header--title'>
              <XrTextAv tag='h2' variant='title'>
                Pengaturan Jam
              </XrTextAv>
            </div>
          </div>
          <div className="card--body">
            <Timeform
              selectedDay={props.selectedDay}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
            />
          </div>
        </Card>

        <div>
          <XrButtonAv
            size='medium'
            color='primary'
            disabled={false}
            isLoading={false}
            onClick={actionAddSchedule}
            caption={`Perbaharui Jadwal Hari ${parseDay(props.selectedDay ? parseInt(props.selectedDay.value) : 0)}`}
          />
        </div>
      </React.Fragment>
    </XrModalMv>
  );
}

export default ScheduleManageWeeklyModal;