
export const SET_ERROR = "@users/SET_ERROR";
export const SET_LOADING = "@users/SET_LOADING";
export const RESET_STATE = "@users/RESET_STATE";

export const GET_USER_REQUEST = "@users/GET_USER_REQUEST";
export const GET_USER_SUCCESS = "@users/GET_USER_SUCCESS";
export const GET_USER_FAILURE = "@users/GET_USER_FAILURE";

export const UPDATE_USER_REQUEST = "@users/UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "@users/UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "@users/UPDATE_USER_FAILURE";

export const SELECT_USER_REQUEST = "@users/SELECT_USER_REQUEST";
export const SELECT_USER_SUCCESS = "@users/SELECT_USER_SUCCESS";
export const SELECT_USER_FAILURE = "@users/SELECT_USER_FAILURE";

export const CREATE_USER_REQUEST = "@users/CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "@users/CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "@users/CREATE_USER_FAILURE";

export const SEARCH_USER_REQUEST = "@users/SEARCH_USER_REQUEST";
export const SEARCH_USER_SUCCESS = "@users/SEARCH_USER_SUCCESS";
export const SEARCH_USER_FAILURE = "@users/SEARCH_USER_FAILURE";