export const EnumGender = {
  MALE: 1,
  FEMALE: 2
}

export const EnumPaymentMethod = {
  PAYMENT_METHOD_CASH: 1,
  PAYMENT_METHOD_BANK_TRANSFER: 2,
  PAYMENT_METHOD_EDC: 3,
}

export const EnumStatusAppointment = {
  CREATED: 1,
  QUEUE: 2,
  PROCESS: 3,
  DONE: 4,
  CANCEL: 5,
}

export const EnumStatusSchedule = {
  CREATED: 1,
  LIBUR_CUTI: 2,
  GANTI: 3,
}

export const EnumStatusMedicalRecord = {
  STATUS_PROCESS: 0,
  STATUS_DONE: 1,
}

export const EnumStatusPayment = {
  STATUS_PENDING: 0,
  STATUS_PAID: 1,
}

export const EnumStatusPaymentMethod = {
  METHOD_CASH: 1
}