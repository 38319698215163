import styled from "styled-components";
import { COLOR } from '../../helpers/theme';
import Spinner from '../../components/Spinner';
import Card from "../../components/layouts/Card";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { RootState } from '../../redux/rootReducers';
import XrButtonAv from '../../components/XrButtonAv';
import { useDispatch, useSelector } from "react-redux";
import { Treatment } from "../../interfaces/Treatment";
import PageWrapper from "../../components/layouts/PageWrapper";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { InputWrapper } from "../../components/forms/FormComponents";
import { BootstrapGrid } from "../../components/layouts/BootstrapGrid";
import { createTreatmentAsync, selectTreatmentAsync, setError, updateTreatmentAsync } from "../../redux/reducers/models/treatments/treatments.actions";
import XrTextAv from "../../components/XrTextAv";

const TreatmentsManagePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const errors = useSelector((state: RootState) => state.treatment.error);
  const isLoading = useSelector((state: RootState) => state.treatment.isLoading);
  const createdTreatment = useSelector((state: RootState) => state.treatment.createdTreatment);
  const selectedTreatment = useSelector((state: RootState) => state.treatment.selectedTreatment);

  const [treatment, setTreatment] = useState<Treatment>({
    name: '',
    price: 0,
    duration: 0
  });
  const [formTitle, setFormTitle] = useState<string>("Tambah");

  const populateData = () => {
    const paths = location.pathname.split("/");
    if (paths[2] !== 'create') {
      const treatmentId = parseInt(paths[2]);
      if (treatmentId && !isNaN(treatmentId)) {
        dispatch(selectTreatmentAsync.request({ id: treatmentId }));
        setFormTitle('Ubah');
      } else {
        history.push(`/treatments/create`);
      }
    }
  }

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);


  useEffect(() => {
    if (selectedTreatment) {
      setTreatment(selectedTreatment);
    } else {
      setTreatment({
        name: '',
        price: 0,
        duration: 0
      });
    }
  }, [selectedTreatment]);

  const whichForm = () => {
    const paths = location.pathname.split("/");
    const treatmentId = paths[2];
    if (selectedTreatment && treatmentId && !isNaN(parseInt(treatmentId))) {
      return 'existing_data';
    } else if (treatmentId === 'create') {
      return 'new_data';
    } else {
      return 'unknown';
    }
  }

  const refreshPage = () => {
    if (whichForm() === 'existing_data') {
      populateData();   
    }
  }
 
  const clearError = (fieldName: string) => {
    if (errors) {
      dispatch(setError({
        ...errors,
        errors: {
          ...errors.errors,
          [`${fieldName}`]: []
        }
      }));
    }
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('name');
    setTreatment({
      ...treatment,
      name: e.target.value
    });
  };

  const onChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('price');
    const price = parseInt(e.target.value);
    if (isNaN(price)) return;
    setTreatment({
      ...treatment,
      price: price
    });
  };

  const onChangeDuration = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('price');
    const duration = parseInt(e.target.value);
    if (isNaN(duration)) return;
    setTreatment({
      ...treatment,
      duration: duration
    });
  };

  const actionCreate = () => {
    const paths = location.pathname.split("/");
    const treatmentId = paths[2];
    if (selectedTreatment && treatmentId && !isNaN(parseInt(treatmentId))) {
      dispatch(updateTreatmentAsync.request(treatment));
    } else if (treatmentId === 'create') {
      dispatch(createTreatmentAsync.request(treatment));
    }
  }

  const fieldHasErrors = (fieldName: string) => {
    if (errors && errors.errors && errors.errors[fieldName] && errors.errors[fieldName].length > 0) {
      return true;
    }
    return false;
  }

  const fieldErrorMessage = (fieldName: string) => {
    if (errors && errors.errors && errors.errors[fieldName]) {
      const fieldErrors = errors.errors[fieldName];
      if (fieldErrors.length === 1) {
        return fieldErrors[0];
      } else {
        return (
          <ul>
            {fieldErrors.map(item => {
              return <li>{item}</li>
            })}
          </ul>
        );
      }
    }
    return "";
  }

  const renderFormSuccess = () => {
    const labelFinished = whichForm() === 'new_data' ? 'ditambahkan' : 'diubah';
    return (
      <React.Fragment>
        <div className="formSuccess">
          <h3>Tindakan <b>{ createdTreatment?.name }</b> berhasil { labelFinished }!</h3>
        </div>
        <div className="formSubmit">
          <Link to='/treatments'>
            <XrButtonAv caption="Kembali ke Daftar Tindakan" />
          </Link>
          <p>atau <Link to={`/treatments/${createdTreatment?.id}`} onClick={refreshPage} className="formSubmit--update">ubah data tindakan</Link></p>
        </div>
      </React.Fragment>
    );
  }

  const renderFormLoading = () => {
    return (
      <React.Fragment>
        <div className="formLoading">
          <Spinner size="small" />
          <div className="formLoading--caption">Memuat Data...</div>
        </div>
      </React.Fragment>
    )
  }

  const renderForm = () => {
    return (
      <React.Fragment>
        <div className="form-group">
          <InputWrapper hasErrors={fieldHasErrors('name')}>
            <div className="input--field">
              <label>Nama Tindakan <span className="required">*</span></label>
              <input
                type="text"
                disabled={isLoading}
                value={treatment.name}
                onChange={onChangeName}
                placeholder="Masukkan nama tindakan disini"
              />
            </div>
            <div className="input--errors">
              { fieldErrorMessage('name') }
            </div>
          </InputWrapper>
        </div>
        <div className="form-group">
          <InputWrapper hasErrors={fieldHasErrors('price')}>
            <div className="input--field">
              <label>Harga <span className="required">*</span></label>
              <input
                type="number"
                disabled={isLoading}
                value={treatment.price}
                onChange={onChangePrice}
                placeholder="Masukkan harga perawatan disini"
              />
            </div>
            <div className="input--errors">
              { fieldErrorMessage('price') }
            </div>
          </InputWrapper>
        </div>
        <div className="form-group">
          <InputWrapper hasErrors={fieldHasErrors('duration')}>
            <div className="input--field">
              <label>Durasi (menit) <span className="required">*</span></label>
              <input
                type="number"
                disabled={isLoading}
                value={treatment.duration}
                onChange={onChangeDuration}
                placeholder="Masukan durasi perawatan disini"
              />
            </div>
            <div className="input--errors">
              { fieldErrorMessage('duration') }
            </div>
          </InputWrapper>
        </div>

        <div className="formSubmit">
          <XrButtonAv
            disabled={isLoading}
            isLoading={isLoading}
            onClick={actionCreate}
            caption={`${formTitle} Tindakan`}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <PageWrapper>
      <TreatmentsManagePageWrapper>
        <BootstrapGrid>
          <div className="row">
            <div className="col-lg-4">
              <Card>
                <div className='card--header'>
                  <div className='card--header--title'>
                    <XrTextAv tag='h2' variant='title'>
                      {`${formTitle} Tindakan`}
                    </XrTextAv>
                  </div>
                </div>
                <div className="card--body">
                  { isLoading ? renderFormLoading() : createdTreatment ? renderFormSuccess() : renderForm() }
                </div>
              </Card>
              
            </div>
          </div>
        </BootstrapGrid>
      </TreatmentsManagePageWrapper>
    </PageWrapper>
  );
}

const TreatmentsManagePageWrapper = styled.div`
  width: 100%;
  padding: 20px;
  min-height: 100vh;

  & .formTitle {
    margin-bottom: 10px;
  }

  & .formLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    & > .formLoading--caption {
      font-size: 16px;
      margin-top: -3px;
      margin-left: 10px;
    }
  }

  & .formSuccess {
    & > h3 {
      font-size: 16px;
      font-weight: normal;
      text-align: center;
    }
  }
  
  & .formSubmit {
    margin-top: 30px;
    text-align: center;

    & > p {
      margin-top: 5px;
      & > .formSubmit--update {
        cursor: pointer;
        color: ${COLOR.SECONDARY}
      }
    }
  }
`;

export default TreatmentsManagePage;