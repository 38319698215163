import styled from "styled-components";
import { Option } from "../../../interfaces/Options";
import XrInputAv from "../../../components/XrInputAv";
import XrModalMv from "../../../components/XrModalMv";
import XrButtonAv from "../../../components/XrButtonAv";
import { RootState } from "../../../redux/rootReducers";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { EnumPaymentMethod } from "../../../interfaces/Enums";
import { InputWrapper } from "../../../components/forms/FormComponents";
import { parseMoney } from "../../../helpers/fieldHelper";

interface Props {
  due: number;
  isCreate: boolean;
  isVisible: boolean;
  actionClose: () => void;
  actionFinishTransaction: (paymentMethod: number) => void;
}

const PaymentMethods: Option[] = [
  {
    label: 'TRANSFER BANK',
    value: EnumPaymentMethod.PAYMENT_METHOD_BANK_TRANSFER.toString()
  },
  {
    label: 'UANG TUNAI',
    value: EnumPaymentMethod.PAYMENT_METHOD_CASH.toString()
  },
  {
    label: 'MESIN EDC',
    value: EnumPaymentMethod.PAYMENT_METHOD_EDC.toString()
  }
]

const defaultPaymentMethod = {
  label: 'UANG TUNAI',
  value: EnumPaymentMethod.PAYMENT_METHOD_CASH.toString()
};

const ModalConfirmPayment = (props: Props) => {
  const refTxtCashValue = useRef<HTMLInputElement>(null);

  const isLoading = useSelector((state: RootState) => state.payment.isLoading);
  const [cashValue, setCashValue] = useState<string>('');
  const [showCashback, setShowCashback] = useState<boolean>(false);
  const [paymentMethod, setPaymentMethod] = useState<Option | null>(defaultPaymentMethod);

  const resetState = () => {
    setCashValue('');
    setShowCashback(false);
    setPaymentMethod(defaultPaymentMethod);
  }
    
  useEffect(() => {
    if (refTxtCashValue && refTxtCashValue.current) {
      refTxtCashValue.current.focus();
    }
    if (props.isVisible) {
      resetState();
    }
  }, [props.isVisible])

  const actionCloseModal = () => {
    props.actionClose();
  }

  const actionBack = () => {
    if (showCashback) {
      resetState();
    }
    actionCloseModal();
  }

  const actionConfirmPayment = () => {
    if (showCashback) {
      if (props.due >= 0 && paymentMethod) {
        const resultPaymentMethod = parseInt(paymentMethod.value);
        props.actionFinishTransaction(resultPaymentMethod);
      }
    } else {
      const amount = parseInt(cashValue);
      if (!isNaN(amount)) {
        setShowCashback(true);
      }
    }
  }

  const onChangePaymentMethod = (e: Option | null) => {
    if (e) {
      setPaymentMethod(e);
      const giveDefaultValue = [
        EnumPaymentMethod.PAYMENT_METHOD_BANK_TRANSFER.toString(),
        EnumPaymentMethod.PAYMENT_METHOD_EDC.toString()
      ];
      if (giveDefaultValue.includes(e.value)) {
        setCashValue(props.due.toString());
      }  
    }
  }

  const onChangeCashValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowCashback(false);
    if (!isNaN(parseInt(e.target.value))) {
      setCashValue(e.target.value);
    } else {
      setCashValue('');
    }
  }

  const onKeyPressCashValue = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      actionConfirmPayment();
    }
  }

  const getCashValue = () => {
    const amount = parseInt(cashValue);
    if (props.due === amount) return 0;
    return parseMoney(amount - props.due);
  }

  const getSubmitButtonLabel = () => {
    if (isLoading) return 'Menyelesaikan Transaksi';
    else if (showCashback && props.due == 0) return 'Selesaikan Transaksi? Jumlah Uang 0';
    else if (showCashback) return 'Yakin Selesaikan Transaksi?';
    return 'Konfirmasi Transaksi';
  }

  return (
    <XrModalMv
      title='Konfirmasi Pembayaran'
      description={''}
      isVisible={props.isVisible} actionClose={actionCloseModal}
    >
      <Wrapper>
        <div className='PaymentManageConfirm__inputs'>
          <XrInputAv
            label='Metode Pembayaran'
            required={true}
            disabled={isLoading}
            isLoading={isLoading}
            selectProps={{
              options: PaymentMethods,
              value: paymentMethod,
              onChange: onChangePaymentMethod,
              placeholder: "Cari preferensi dokter"
            }}
          />
          <XrInputAv
            type='text'
            required={true}
            value={cashValue}
            ref={refTxtCashValue}
            onChange={onChangeCashValue}
            onKeyPress={onKeyPressCashValue}
            label='Jumlah uang yang diterima'
            placeholder="Masukkan jumlah uang yang diterima"
          />
        </div>
      
        {showCashback ? (
          <InputWrapper>
            <div className="input--field">
              <label>Jumlah uang yang harus dikembalikan</label>
              <input
                type="text"
                disabled={true}
                value={getCashValue()}
              />
            </div>
          </InputWrapper>
        ) : null}

        <div className='button-wrapper'>
          <XrButtonAv
            noBlock
            size='medium'
            disabled={false}
            isLoading={false}
            onClick={actionConfirmPayment}
            color={showCashback ? 'danger' : 'primary'}
            caption={`${getSubmitButtonLabel()}`}
          />
          &nbsp;
          <XrButtonAv
            noBlock
            size='medium'
            disabled={false}
            isLoading={false}
            onClick={actionBack}
            caption='Kembali'
          />
        </div>
      </Wrapper>
    </XrModalMv>
  )
}

const Wrapper = styled.div`
  & .PaymentManageConfirm__inputs {
    & > * {
      margin-bottom: 5px;
    }
  }
`;

export default ModalConfirmPayment;