import { fork } from "redux-saga/effects";
import sagaCreateTransaction from "./saga.createTransaction";
import sagaFinishAppointment from "./saga.finishAppointment";
import sagaGetAppointment from "./saga.getAppointments";
import sagaGetTreatment from "./saga.getTreatment";
import sagaSelectAppointment from "./saga.selectAppointment";
import sagaUpdateAppointment from "./saga.updateAppointment";

const consultationSaga = [
  fork(sagaGetAppointment),
  fork(sagaSelectAppointment),
  fork(sagaUpdateAppointment),
  fork(sagaFinishAppointment),
  fork(sagaCreateTransaction),
  fork(sagaGetTreatment),
];

export default consultationSaga;