import * as actions from "./treatments.actions";
import * as types from "./treatments.actionTypes";
import { ActionType, createReducer } from "typesafe-actions";
import { TreatmentReducerType } from "./types";

export type TreatmentActions = ActionType<typeof actions>;

export const initialState: TreatmentReducerType = {
  error: null,
  treatments: [],
  isLoading: false,
  createdTreatment: null,
  selectedTreatment: null,
  meta: {
    total: 0,
    per_page: 0,
    last_page: 0,
    current_page: 0,
  }
};

export const TreatmentReducer = createReducer<
  TreatmentReducerType,
  TreatmentActions
>(initialState)
  .handleAction(actions.getTreatmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.getTreatmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    treatments: action.payload.data,
    meta: action.payload.meta
  }))
  .handleAction(actions.getTreatmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(actions.selectTreatmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    createdTreatment: null,
    selectedTreatment: null,
  }))
  .handleAction(actions.selectTreatmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    selectedTreatment: action.payload.data,
  }))
  .handleAction(actions.selectTreatmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(actions.updateTreatmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.updateTreatmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdTreatment: action.payload.data,
  }))
  .handleAction(actions.updateTreatmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(actions.createTreatmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.createTreatmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdTreatment: action.payload.data
  }))
  .handleAction(actions.searchTreatmentAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.searchTreatmentAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    treatments: action.payload.data,
    meta: action.payload.meta
  }))
  .handleAction(actions.searchTreatmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(types.SET_ERROR as any, (state, action) => ({
    ...state,
    error: action.payload.value
  }))
  .handleAction(types.RESET_STATE as any, (state, action) => ({
    ...state,
    error: null,
    createdTreatment: null,
    selectedTreatment: null
  }))
