import { StylesConfig, Theme } from "react-select";
import styled from "styled-components";
import { COLOR } from "../../helpers/theme";

export const CheckboxWrapper = styled.div`
  display: block;
  position: relative;
  margin-bottom: 10px;

  & label {
    z-index: 2;
    width: 100%;
    padding: 5px;
    display: block;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    position: relative;
    transition: 0.25s all;
    -ms-user-select: none;
    -moz-user-select: none;
    background-color: #fff;
    -webkit-user-select: none;
    border: 1px solid #e2e2e2;
    border-radius: var(--corner-radius-small);

    &:hover {
      border: 1px solid var(--color-secondary) !important;
    }

    & > .preview {
      width: 100%;
      height: 150px;
      overflow: hidden;
      position: relative;
      margin-bottom: 10px;
      border-radius: var(--corner-radius-small);

      & > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      & > .facilities {
        left: 5px;
        bottom: 5px;
        display: flex;
        margin-top: 15px;
        position: absolute;

        & > .facility {
          width: 25px;
          height: 25px;
          display: flex;
          margin-top: 5px;
          margin-right: 2px;
          background: #fff;
          align-items: center;
          align-items: center;
          border-radius: 100%;
          justify-content: center;
          border: 1px solid var(--color-detail);
          & > img {
            width: 12px;
            min-width: 12px;
          }
        }
      }
    }

    & > .indicatorContainer {
      top: 10px;
      right: 10px;
      width: 35px;
      height: 35px;
      padding: 8px;
      overflow: hidden;
      background: #fff;
      position: absolute;
      border: 1px solid #e2e2e2;
      border-radius: var(--corner-radius-small);

      & > img {
        position: relative;
        width: 100%;
        height: 100%;
        left: -100%;
        object-position: center;
        object-fit: contain;
        opacity: 0;
        transition: 0.25s all;
      }
    }

    & > .content {
      margin-bottom: 10px;
      text-align: center;

      & > p:first-child {
        font-weight: 700;
        margin-bottom: 0px;
      }
      & > p:last-child {
        color: var(--color-detail);
      }
    }
  }

  & input:checked ~ label {
    color: #fff;
    background: var(--color-primary);
    border: 1px solid var(--color-primary);

    & > .indicatorContainer {
      & > img {
        left: 0;
        opacity: 1;
      }
    }

    & > .content {
      & > p:last-child {
        color: var(--color-primary-darkest);
      }
    }
  }

  & input {
    position: absolute;
    visibility: hidden;
    cursor: pointer;
  }
`;

interface InputWrapperProps {
  noSpace?: boolean;
  disabled?: boolean;
  hasErrors?: boolean
}

export const InputWrapper = styled.div<InputWrapperProps>`
  width: 100%;

  & > .input--field {
    z-index: 0;
    width: 100%;
    height: 65px;
    padding: 10px;
    display: block;
    margin-top: -1px;
    padding-top: 10px;
    border-radius: 5px;
    position: relative;
    margin-bottom: ${props => {
      if (props.noSpace) {
        return '0px';
      }
      else if (props.hasErrors) {
        return '5px';
      } else {
        return '10px';
      }
    }};
    background-color: ${props => {
      if (props.disabled) {
        return COLOR.INPUT_DISABLED;
      }
      else if (props.hasErrors) {
        return COLOR.DANGER_INPUT_BACKGROUND;
      } else {
        return '#fff';
      }
    }};
    border: 1px solid ${props => {
      if (props.hasErrors) {
        return COLOR.DANGER_BORDER;
      } else {
        return '#e2e2e2';
      }
    }};

    & > * {
      width: 100%;
      display: block;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    & > input {
      background-color: inherit;
    }

    & > .react-datepicker-wrapper {
      & > .react-datepicker__input-container {
        & > input {
          width: 100%;
          border: none;
        }
      }
    }

    & > label {
      z-index: 1;
      display: flex;
      font-size: 16px;
      padding-left: 1px;
      position: relative;
      align-items: center;
      padding-bottom: 10px;
      width: calc(100% - 41px);
      color: ${props => {
        if (props.hasErrors) {
          return COLOR.DANGER_BORDER;
        } else {
          return COLOR.TEXT_DESCRIPTIVE;
        }
      }};

      & > .required {
        color: red;
        margin-left: 5px;
        font-weight: 900;
        font-size: 10px;
      }

      & .label--radio {
        padding-top: 4px;
        margin-right: 5px;

        & > input { 
          margin: 0;
        }
      }
    }

    & > *:last-child {
      position: absolute;
      z-index: 0;
      left: 0;
      top: 0%;
      border: none;
      font-size: 16px;
      padding-top: 33px;
      padding-left: 11px;
      padding-right: 10px;
      padding-bottom: 5px;
    }

    &.select {
      & > *:last-child {
        padding: 0;
      }
    }

    &.no--label {
      height: 40px;
      & > input {
        padding-top: 8px;
      }
    }
  }

  & > .input--errors {
    font-size: 16px;
    line-height: 125%;
    margin-bottom: 15px;
    color: ${COLOR.DANGER};
    display: ${props => {
      if (props.hasErrors) {
        return 'block';
      } else {
        return 'none';
      }
    }};

    & > ul {
      margin: 0;
      padding-left: 15px;
      font-size: inherit;
      & > li {
        font-size: inherit;
      }
    }
  }
`;

export const InputError = styled.ul`
  margin: 0;
  font-size: 16px;
  color: ${COLOR.DANGER};
  font-weight: bold;
  margin-bottom: 15px;
`;

export const SelectTheme = (theme: Theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary: "#5049B2",
    primary75: "#716bc3",
    primary50: "#948fd2",
    primary25: "#b7b3e1",
  },
});

export const SelectStyle: StylesConfig<{}, false> = {
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#535353",
    fontWeight: "normal",
    padding: "30px 10px 20px 12px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#535353",
    fontWeight: "normal",
    padding: "37px 10px 20px 9px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#8f8f8f",
    fontWeight: "normal",
    padding: "37px 10px 20px 9px",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "63px",
    border: "none",
    borderRadius: "5px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    marginTop: "0px",
    padding: "0px 5px 0px 5px",
    opacity: state.isDisabled ? 0 : 1,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "16px",
  }),
  menu: (provided, state) => ({
    ...provided
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    height: "100%",
  })
};
