export const SET_ERROR = "@appointments/SET_ERROR";
export const RESET_STATE = "@appointments/RESET_STATE";
export const SET_CURRENT_STEP = "@appointment/SET_CURRENT_STEP";
export const SET_SELECTED_DATE = "@appointments/SET_SELECTED_DATE";
export const SHOW_APPOINTMENT_FORM = "@appointments/SHOW_APPOINTMENT_FORM";
export const HIDE_APPOINTMENT_FORM = "@appointments/HIDE_APPOINTMENT_FORM";
export const CLEAR_SELECTED_APPOINTMENT = "@appointments/CLEAR_SELECTED_APPOINTMENT";

export const GET_APPOINTMENT_REQUEST = "@appointments/GET_APPOINTMENT_REQUEST";
export const GET_APPOINTMENT_SUCCESS = "@appointments/GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_FAILURE = "@appointments/GET_APPOINTMENT_FAILURE";

export const UPDATE_APPOINTMENT_REQUEST = "@appointments/UPDATE_APPOINTMENT_REQUEST";
export const UPDATE_APPOINTMENT_SUCCESS = "@appointments/UPDATE_APPOINTMENT_SUCCESS";
export const UPDATE_APPOINTMENT_FAILURE = "@appointments/UPDATE_APPOINTMENT_FAILURE";

export const SELECT_APPOINTMENT_REQUEST = "@appointments/SELECT_APPOINTMENT_REQUEST";
export const SELECT_APPOINTMENT_SUCCESS = "@appointments/SELECT_APPOINTMENT_SUCCESS";
export const SELECT_APPOINTMENT_FAILURE = "@appointments/SELECT_APPOINTMENT_FAILURE";

export const CREATE_APPOINTMENT_REQUEST = "@appointments/CREATE_APPOINTMENT_REQUEST";
export const CREATE_APPOINTMENT_SUCCESS = "@appointments/CREATE_APPOINTMENT_SUCCESS";
export const CREATE_APPOINTMENT_FAILURE = "@appointments/CREATE_APPOINTMENT_FAILURE";

export const SEARCH_DOCTOR_SCHEDULE_REQUEST = "@appointments/SEARCH_DOCTOR_SCHEDULE_REQUEST";
export const SEARCH_DOCTOR_SCHEDULE_SUCCESS = "@appointments/SEARCH_DOCTOR_SCHEDULE_SUCCESS";
export const SEARCH_DOCTOR_SCHEDULE_FAILURE = "@appointments/SEARCH_DOCTOR_SCHEDULE_FAILURE";