import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import XrButtonAv from "../../../components/XrButtonAv";
import XrModalMv from "../../../components/XrModalMv";

interface Props {
  isVisible: boolean;
  previousDiscount: number;
  actionClose: () => void;
  onChangeDiscount: (value: number) => void;
}

const ModalDiscount = (props: Props) => {
  const txtDiscount = useRef<HTMLInputElement>(null);

  const [quantity, setQuantity] = useState<number>(props.previousDiscount);
  
  useEffect(() => {
    if (props.isVisible && txtDiscount && txtDiscount.current) {
      setQuantity(props.previousDiscount);
      txtDiscount.current.focus();
    } 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isVisible]);

  const onChangeDiscount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    if (!isNaN(val) && val > 0) {
      setQuantity(val);
      return;
    }
    setQuantity(0);
  }

  const onSave = () => {
    props.onChangeDiscount(quantity);
    props.actionClose();
  }

  const onCancel = () => {
    props.actionClose();
  }

  const onTxtDiscountKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if(e.key === 'Enter') {
      onSave();
    }
  }

  return (
    <XrModalMv
      title='Terapkan Diskon Lainnya'
      description='Masukkan nominal diskon yang ingin diterapkan (dalam rupiah).'
      actionClose={props.actionClose}
      isVisible={props.isVisible}
    >
      <Wrapper>
        <div className='button-wrapper'>
          <input
            type='number'
            ref={txtDiscount}
            onChange={onChangeDiscount}
            className='ModalDiscount_amount'
            onKeyPress={onTxtDiscountKeyPress}
            value={quantity.toString().replace(/^0+/, '') || 0}
          />
          <XrButtonAv
            noBlock
            size='medium'
            color='primary'
            disabled={false}
            isLoading={false}
            onClick={onSave}
            caption='Terapkan Diskon'
          />
          &nbsp;
          <XrButtonAv
            noBlock
            size='medium'
            disabled={false}
            isLoading={false}
            onClick={onCancel}
            caption='Batalkan'
          />
        </div>
      </Wrapper>
    </XrModalMv>
  )
}

export default ModalDiscount;

const Wrapper = styled.div`
  .ModalDiscount_amount {
    font-size: 16px;
    width: 155px;
    border-radius: 5px;
    margin-right: 12px;
    text-align: center;
    padding: 8px 12px 8px 12px;
    border: 1px solid #dedede;
  }
`;