import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { RootState } from '../../../redux/rootReducers';
import ScheduleManageWeekly from './form-weekly-schedule/FormWeeklySchedule';
import ScheduleManageCalendar from './form-monthly-schedule/FormMonthlySchedule';
import ScheduleManageSelectTime from './ScheduleManageSelectTime';
import { BootstrapGrid } from '../../../components/layouts/BootstrapGrid';

const ScheduleManageCreateSchedule = () => {
  const step = useSelector((state: RootState) => state.schedule.step);

  const renderManageScheduleSteps = () => {
    switch (step) {
      case 'STEP_1_CHOOSE_MONTH':
        return (
          <div className='col-lg-3'>
            <ScheduleManageSelectTime />
          </div>
        );
      case 'STEP_2_MANAGE_WEEKLY':
        return (
          <div className='col-lg-12'>
            <ScheduleManageWeekly />
          </div>
        );
      case 'STEP_3_MANAGE_CALENDAR':
        return (
          <div className='col-lg-12'>
            <ScheduleManageCalendar />
          </div>
        );
    }
  }

  return (
    <ScheduleManageCreateScheduleWrapper>
      <BootstrapGrid>
        <div className='row'>
          { renderManageScheduleSteps() }
        </div>
      </BootstrapGrid>
    </ScheduleManageCreateScheduleWrapper>
  );
}

const ScheduleManageCreateScheduleWrapper = styled.div`
  & .calendar-grid {
    & > td {
      width: ${100/7}%;
      vertical-align: top;
    }
  }
  & .calendar-item {
    &:last-child {
      margin-bottom: 0px;
    }
    & > .card--body {
      padding: 12px 16px 12px 16px !important;

      & > * {
        line-height: 125%;
      }
    }
  }
`;

export default ScheduleManageCreateSchedule;