import { useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { COLOR } from '../../../../helpers/theme';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../../../../components/layouts/Card';
import XrTextAv from '../../../../components/XrTextAv';
import XrButtonAv from '../../../../components/XrButtonAv';
import { RootState } from '../../../../redux/rootReducers';
import { pad, padTime, parseMonth } from '../../../../helpers/calendarUtils';
import FormMonthlyScheduleModal from './FormMonthlyScheduleModal';
import { DataTableWrapper } from '../../../../components/data/DataTable';
import { Schedule, ScheduleTime } from '../../../../interfaces/Schedule';
import { createScheduleAsync, setManageScheduleStep, setScheduleMonthly, setSelectedMonth } from '../../../../redux/reducers/schedules/actions';
import { faSave } from '@fortawesome/free-regular-svg-icons';
import { showModal } from '../../../../redux/reducers/notifications/actions';

const ScheduleManageCalendar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const isLoading = useSelector((state: RootState) => state.schedule.isLoading);
  const selectedMonth = useSelector((state: RootState) => state.schedule.selectedMonth);
  const scheduleMonthly = useSelector((state: RootState) => state.schedule.scheduleMonthly);
  const selectedYear = useSelector((state: RootState) => state.schedule.selectedYear);

  const [selectedDay, setSelectedDay] = useState<number>(0);
  const [modalActive, setModalActive] = useState<boolean>(false);
  const [selectedTime, setSelectedTime] = useState<ScheduleTime[]>([]);

  const getDoctorId = () => {
    const paths = location.pathname.split('/');
    return parseInt(paths[2]);
  }
  
  const actionSelectMonth = () => {
    dispatch(setSelectedMonth(null));
    dispatch(setManageScheduleStep('STEP_1_CHOOSE_MONTH'));
  }

  const actionUpdateWeeklySchedule = () => {
    dispatch(showModal({
      title: 'Anda akan mengubah jadwal mingguan',
      content: 'Dengan mengubah jadwal mingguan, Anda akan menghapus jadwal bulanan Anda. Apakah Anda yakin ingin melanjutkan?',
      buttonSubmit: 'Ubah Jadwal',
      buttonCancel: 'Batalkan',
      actionSubmit: () => {
        dispatch(setScheduleMonthly([]));
        dispatch(setManageScheduleStep('STEP_2_MANAGE_WEEKLY'));
      }
    }))
  }

  const actionOpenScheduleDialog = (day: number) => {
    setSelectedDay(day - 1);
    setModalActive(true);
    const selectedSchedule = scheduleMonthly.find(i => i.day === day);
    if (selectedSchedule) {
      setSelectedTime(selectedSchedule.timestamp);
    };
  }

  const actionSaveSchedule = () => {
    const today = new Date();
    const payload: Schedule[] = [];
    if (!selectedMonth) return;
    const month = parseInt(selectedMonth?.value) + 1;
    scheduleMonthly.forEach(item => {
      item.timestamp.forEach(timestamp => {
        payload.push({
          doctor_id: getDoctorId(),
          start_date: `${today.getFullYear()}-${pad(month)}-${pad(item.day)} ${padTime(timestamp.start)}`,
          end_date: `${today.getFullYear()}-${pad(month)}-${pad(item.day)} ${padTime(timestamp.end)}`,
          status: 1
        });
      });
    });
    dispatch(createScheduleAsync.request({
      schedule: [...payload]
    }));
  }

  const renderMonthName = () => {
    try {
      if (selectedMonth && selectedYear) {
        return `${parseMonth(parseInt(selectedMonth.value))} ${parseInt(selectedYear.value)}`;
      }
      return '-';
    } catch {
      return '-';
    }
  }

  const renderScheduleDay = (day: number) => {
    if (scheduleMonthly[day] && scheduleMonthly[day].timestamp) {
      if (scheduleMonthly[day].timestamp.length > 0) {
        return (
          <div className='event-item'>
            <div className='event-item--icon active'>
            </div>
            <div className='event-item--text'>
              {scheduleMonthly[day].timestamp.length} jadwal
            </div>
          </div>
        );
      }
      return (
        <div className='event-item'>
          <div className='event-item--icon passive'>
          </div>
          <div className='event-item--text'>
            0 jadwal
          </div>
        </div>
      )
    }
    return <div />
  }
  
  const renderScheduleCalendar = () => {
    if (!selectedYear) return null;
    if (!selectedMonth) return null;
    const todayMonth = parseInt(selectedMonth.value);
    const todayYear = parseInt(selectedYear.value);
    const thisMonthStartDay = new Date(todayYear, todayMonth - 1, 1).getDay();
    const maxDateThisMonth = new Date(todayYear, todayMonth, 0).getDate();
    const daysRows = Array.from(Array(Math.floor(maxDateThisMonth / 7) + 2).keys());
    const daysInWeek = Array.from(Array(7).keys());
    return daysRows.map(daysRow => {
      return (
        <tr className='calendar-grid'>
          {daysInWeek.map(day => {
            const dayCtr = (daysRow * 7) + (day + 1);
            const dayDate = dayCtr - thisMonthStartDay + 1;
            if (dayCtr <= maxDateThisMonth + thisMonthStartDay - 1) {
              if (dayCtr < thisMonthStartDay) {
                return (
                  <td />
                );
              }
              return (
                <td>
                  <Card className='hoverable calendar-item' onClick={() => actionOpenScheduleDialog(dayDate)}>
                    <div className='card--body'>
                      <p>{dayDate}</p>
                      <br/>
                      {renderScheduleDay(dayDate - 1)}
                    </div>
                  </Card>
                </td>
              );
            }
            return <div />
          })}
        </tr>
      )
    })
  }

  return (
    <ScheduleManageCalendarWrapper>
      <FormMonthlyScheduleModal
        modalActive={modalActive}
        selectedDay={selectedDay}
        selectedTime={selectedTime}
        setModalActive={setModalActive}
        setSelectedTime={setSelectedTime}
      />
      <Card>
        <div className='card--header'>
          <div className='card--header--title'>
            <XrTextAv tag='h2' variant='title'>
              <span>Atur Jadwal Bulan ${renderMonthName()}</span>
            </XrTextAv>
            <XrTextAv tag='p' variant='description'>
              Perbaharui jadwal apabila Anda ingin memiliki jadwal yang berbeda pada tanggal tertentu.
            </XrTextAv>
          </div>
          <div className='card--header--action'>
            <XrButtonAv
              size='medium'
              noBlock={true}
              disabled={isLoading}
              isLoading={false}
              onClick={() => actionSelectMonth()}
              caption={`Ganti Kalender`}
            />
            &nbsp;
            <XrButtonAv
              size='medium'
              noBlock={true}
              disabled={isLoading}
              isLoading={false}
              onClick={() => actionUpdateWeeklySchedule()}
              caption={`Ubah Jadwal Mingguan`}
            />
            &nbsp;
            <XrButtonAv
              size='medium'
              color='primary'
              noBlock={true}
              disabled={isLoading}
              icon={faSave}
              isLoading={isLoading}
              onClick={() => actionSaveSchedule()}
              caption={`Simpan Jadwal`}
            />
          </div>
        </div>
        <div className='card--body'>
          <DataTableWrapper style={{ marginTop: 0 }}>
            <div className='tablewrapper--table'>
              <table>
                <thead>
                  <tr>
                    <th>Senin</th>
                    <th>Selasa</th>
                    <th>Rabu</th>
                    <th>Kamis</th>
                    <th>Jumat</th>
                    <th>Sabtu</th>
                    <th>Minggu</th>
                  </tr>
                </thead>
                <tbody>
                  {renderScheduleCalendar()}
                </tbody>
              </table>
            </div>
          </DataTableWrapper>
        </div>
      </Card>
    </ScheduleManageCalendarWrapper>
  );
}

const ScheduleManageCalendarWrapper = styled.div`
  .event-item {
    display: flex;
    .event-item--icon {
      width: 10px;
      height: 10px;
      margin-top: 4px;
      margin-right: 5px;
      border-radius: 10px;

      &.passive {
        background-color: ${COLOR.BORDER};
      }
      &.active {
        background-color: ${COLOR.SECONDARY};
      }
    }
    .event-item--text {
      color: ${COLOR.TEXT_DESCRIPTIVE}
    }
  }
`;

export default ScheduleManageCalendar;