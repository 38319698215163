import styled from "styled-components";
import { absoluteUrl } from "../helpers/fieldHelper";

type AvatarSize = 'small' | 'medium' | 'large'

export interface AvatarComponentProps {
  name: string;
  size?: AvatarSize;
  image?: string;
}

const XrAvatarAv = (props: AvatarComponentProps) => {
  const initials = () => {
    if (!props.name) return '';
    const name = props.name.split(" ");
    if (name.length > 1) {
      return `${name[0][0].toUpperCase()}${name[name.length - 1][0].toUpperCase()}`;
    } else {
      return `${name[0][0].toUpperCase()}${name[0][1].toUpperCase()}`;
    }
  }

  return (
    <AvatarContainer size={props.size}>
      {props.image ? (
        <div className='avatar-img' data-tip={props.name}>
          <img src={absoluteUrl(props.image)} alt={props.name} />
        </div>
      ) : (
        <div
          className='avatar'
          data-tip={props.name}
        >
          <span>{initials()}</span>
        </div>
      )}
    </AvatarContainer>
  );
};

export const AvatarContainer = styled.div<any>`
  & > .avatar-img {
    margin-right: 15px;
    border-radius: 100%;
    overflow: hidden;
    border: 1px solid #dedede;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.3);

    ${(props) => (() => {
      switch (props.size) {
        case 'small':
          return `
            width: 25px;
            height: 25px;
            min-width: 25px;
            min-height: 25px;
          `;
        case 'medium':
          return `
          
          `;
        default:
          return `
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
          `;
      }
    })()};
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  & > .avatar {
    display: flex;
    margin-right: 15px;
    align-items: center;
    justify-content: center;
    background: linear-gradient(
      to bottom,
      var(--color-secondary) 0%,
      var(--color-secondary-dark) 100%
    );
    color: #fff;
    line-height: 0px;
    border-radius: 100%;
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);

    ${(props) => (() => {
      switch (props.size) {
        case 'small':
          return `
            width: 25px;
            height: 25px;
            min-width: 25px;
            min-height: 25px;
            font-size: 12px;
          `;
        case 'medium':
          return `
          
          `;
        default:
          return `
            width: 40px;
            height: 40px;
            min-width: 40px;
            min-height: 40px;
          `;
      }
    })()};
  }
`;

export default XrAvatarAv;