import { updateUserAsyncResponse } from "./types";
import * as userActions from "./users.actions";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../../interfaces/Response";
import { apiPut, API_URL_USER, retrieveToken } from "../../../../helpers/api";

export function* updateUsers(action: ReturnType<typeof userActions.updateUserAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    if (action.payload.id) {
      const result: Response = yield call(apiPut, API_URL_USER.UPDATE_USER(action.payload.id), token, action.payload);
      if (result.status === 200) {
        const parsed: updateUserAsyncResponse = yield call(result.json.bind(result));
        yield put(userActions.updateUserAsync.success(parsed));
        return;
      } else {
        const parsed: ErrorResponse = yield call(result.json.bind(result));
        yield put(userActions.updateUserAsync.failure(parsed));
        return;
      }
    } else {
      const parsed: ErrorResponse = {
        status: 500,
        errors: {},
        message: 'Terjadi kesalahan pada server'
      }
      yield put(userActions.updateUserAsync.failure(parsed));  
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(userActions.updateUserAsync.failure(parsed));
  }
}

export default function* usersSagaUpdateUser() {
  yield takeEvery(userActions.updateUserAsync.request, updateUsers);
}
