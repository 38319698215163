import moment from "moment";
import styled from "styled-components";
import { useEditor } from "@tiptap/react";
import ModalDiscount from "./ModalDiscount";
import StarterKit from "@tiptap/starter-kit";
import ModalUpdateCart from "./ModalUpdateCart";
import { User } from "../../../interfaces/User";
import React, { useEffect, useRef, useState } from "react";
import Card from "../../../components/layouts/Card";
import XrTextAv from "../../../components/XrTextAv";
import XrInputAv from "../../../components/XrInputAv";
import { Patient } from "../../../interfaces/Patient";
import { useDispatch, useSelector } from "react-redux";
import XrButtonAv from "../../../components/XrButtonAv";
import { RootState } from "../../../redux/rootReducers";
import XrAvatarMv from "../../../components/XrAvatarMv";
import { Treatment } from "../../../interfaces/Treatment";
import XrSpacingAv from "../../../components/XrSpacingAv";
import { useHistory, useLocation } from "react-router-dom";
import { faPercent, faTrash } from "@fortawesome/free-solid-svg-icons";
import XrTextEditorMv from "../../../components/XrTextEditorMv";
import XrAccountingMv from "../../../components/XrAccountingMv";
import PageWrapper from "../../../components/layouts/PageWrapper";
import { fieldErrorMessage } from "../../../helpers/formErrorHandler";
import XrTransactionCartMv from "../../../components/XrTransactionCartMv";
import { showModal } from "../../../redux/reducers/notifications/actions";
import XrLoadingPlaceholder from "../../../components/XrLoadingPlaceholder";
import { TreatmentBilling } from "../../../redux/reducers/consultation/types";
import { selectTransactionAsync, updateTransactionAsync } from "../../../redux/reducers/payment/actions";
import { parseAge, parseGender, parseStringToSeries } from "../../../helpers/fieldHelper";
import { createTransactionAsync, finishAppointmentAsync, getTreatmentAsync, selectAppointmentAsync } from "../../../redux/reducers/consultation/actions";
import ModalConfirmPayment from "./ModalConfirmPayment";
import { EnumStatusPayment } from "../../../interfaces/Enums";
import PatientsMedicalRecord from "../../../components/patients/medical-record/PatientsMedicalRecord";

export interface Option { value: Treatment; label: string; }

interface Props {
  transactionMode?: boolean;
}

const ConsultationManagePage = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  // to serialize data
  const [isCreate, setIsCreate] = useState<boolean>(false);
  const [doctor, setDoctor] = useState<User | null | undefined>(null);
  const [patient, setPatient] = useState<Patient | null | undefined>(null);

  const selectTreatment = useRef<any>(null);

  // for selectable options
  const [selectedTreatments, setSelectedTreatments] = useState<TreatmentBilling[]>([]);
  const [treatmentOptions, setTreatmentOptions] = useState<Option[]>([]);
  const [selectedTreatment, setSelectedTreatment] = useState<Option | null>(null);

  // followings are for modals visibility
  /** if null, modal is hidden. To show modal, call this function with cart item index */
  const [showEditCartModal, setShowEditCartModal] = useState<number | null>(null);
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState<boolean>(false);

  // data from store
  const error = useSelector((state: RootState) => state.consultation.error);
  const treatments = useSelector((state: RootState) => state.consultation.treatments);
  const authorizedUser = useSelector((state: RootState) => state.authorization.authorizedUser);
  const selectedAppointment = useSelector((state: RootState) => state.consultation.selectedAppointment);
  const selectedTransaction = useSelector((state: RootState) => state.payment.selectedTransaction);
  const isLoading = useSelector((state: RootState) => props.transactionMode ?
    state.payment.isLoading :
    state.consultation.isLoading
  );
  const transactionItems = useSelector((state: RootState) => state.payment.selectedTransaction?.medical_treatment_detail);

  const isTransactionDisabled = Boolean(props.transactionMode && selectedTransaction && selectedTransaction.is_paid === 1);
  
  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    content: '',
  });

  const [discountValue, setDiscountValue] = useState<number>(0);

  const clearForm = () => {
    setShowEditCartModal(null);
    setShowDiscountModal(false);
    setShowConfirmPaymentModal(false);
  }

  // populate data. if this page used in consultation, will
  // call appointment directly. if not, will call through
  // transaction endpoint
  const populateData = () => {
    const paths = location.pathname.split("/");
    const cmd = paths[2];
    const id = parseInt(cmd);
    const idc = parseInt(paths[3]);
    if (id && !isNaN(id)) {
      clearForm();
      setIsCreate(false);
      dispatch(getTreatmentAsync.request({}));
      if (props.transactionMode) {
        dispatch(selectTransactionAsync.request({ id }));
      } else {
        dispatch(selectAppointmentAsync.request({ id }));
      }
    } else if (cmd === 'create' && idc && !isNaN(idc)) {
      clearForm();
      setIsCreate(true);
      dispatch(getTreatmentAsync.request({}));
      dispatch(selectAppointmentAsync.request({ id: idc }));
    } else {
      clearForm();
      setIsCreate(false);
      if (props.transactionMode) {
        history.push(`/payment`);
      } else {
        history.push(`/consultation`);
      }
    }
  }
  
  // call endpoint when route changes
  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  // will populate data if selected appointment is not null
  // and is using consultation mode
  useEffect(() => {
    if (props.transactionMode) {
      if (isCreate && selectedAppointment) {
        setDoctor(selectedAppointment.doctor_detail);
        setPatient(selectedAppointment.patient_detail);
      } else if (selectedTransaction) {
        setDoctor(selectedTransaction.doctor_detail);
        setPatient(selectedTransaction.patient_detail);
        setDiscountValue(selectedTransaction.discount);
        if (transactionItems) {
          const newOptions: TreatmentBilling[] = transactionItems.map(item => item);
          setSelectedTreatments(newOptions);
        }
      } else {
        setDoctor(null);
        setPatient(null);
      }
    } else {
      if (selectedAppointment) {
        setDoctor(selectedAppointment.doctor_detail);
        setPatient(selectedAppointment.patient_detail);
      } else {
        setDoctor(null);
        setPatient(null);
      }
    }
  }, [isCreate, transactionItems, selectedAppointment, selectedTransaction, props.transactionMode])
  
  useEffect(() => {
    const newOptions: Option[] = [];
    treatments.forEach(item => {
      if (item.id) {
        newOptions.push({
          value: item,
          label: item.name
        });
      }
    });
    setTreatmentOptions(newOptions);
  }, [treatments]);

  const finishAppointment = (excludeDiagnosis?: boolean) => {
    if (!editor || !selectedAppointment || !selectedAppointment.id) return;
    dispatch(finishAppointmentAsync.request({
      discount: discountValue,
      id_appointment: selectedAppointment.id,
      record: {
        description: '',
        diagnosis: excludeDiagnosis ? '' : editor.getHTML(),
      },
      medical_treatments: selectedTreatments.map(i => {
        return {
          treatment_id: i.treatment_id,
          quantity: i.quantity,
          price: i.price,
        }
      }),
      agenda: ''
    }));
  }

  const finishTransaction = (paymentMethod: number) => {
    if (isCreate) {
      if (!selectedAppointment || !selectedAppointment.id) return;
      dispatch(createTransactionAsync.request({
        is_paid: EnumStatusPayment.STATUS_PAID,
        id_appointment: selectedAppointment.id,
        record: {
          description: '',
          diagnosis: 'Transaksi adhoc, tanpa diagnosis',
        },
        medical_treatments: selectedTreatments.map(i => {
          return {
            treatment_id: i.treatment_id,
            quantity: i.quantity,
            price: i.price,
          }
        }),
        agenda: '',
        discount: discountValue,
        payment_method: paymentMethod,
      }));
    } else {
      if (!selectedTransaction) return;
      dispatch(updateTransactionAsync.request({
        ...selectedTransaction,
        payment_method: paymentMethod,
        is_paid: EnumStatusPayment.STATUS_PAID,
        paid_at: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
      }));
    }
  }

  const onClickFinishAppointment = () => {
    if (!selectedAppointment || !editor || !authorizedUser || !authorizedUser.id) return;

    dispatch(showModal({
      title: 'Selesaikan Konsultasi?',
      content: 'Mohon pastikan data yang Anda isi sudah benar! Dengan menyelesaikan konsultasi, data akan diteruskan ke bagian kasir untuk pembayaran.',
      buttonSubmit: 'Selesaikan (Enter)',
      buttonCancel: 'Ubah Data (Esc)',
      actionSubmit: finishAppointment
    }))
  }

  const onChangeDicsount = (val: number) => {
    setDiscountValue(val);
  }

  const onChangeSelectedTreatment = (e: Option | null) => {
    setSelectedTreatment(null);
    if (e) {
      const newTreatments = [...selectedTreatments];
      const existingTreatment = newTreatments.findIndex(i => i.treatment_id === e.value.id);
      if (existingTreatment > -1) {
        newTreatments[existingTreatment].quantity++;
      } else {
        newTreatments.push({
          quantity: 1,
          price: e.value.price,
          total_price: e.value.total_price,
          treatment_id: e.value.id,
          treatment_detail: e.value
        });
      }
      setSelectedTreatments(newTreatments);
    }
  }

  const agenda = () => {
    if (selectedAppointment) {
      const payload = selectedAppointment.agenda.replace(/(<([^>]+)>)/gi, "").trim();
      if (payload) return selectedAppointment.agenda
    }
    return 'Tidak ada agenda yang dideskripsikan oleh resepsionis.';
  }

  const actionDeleteTreatment = (index: number) => {
    const newTreatments = [...selectedTreatments];
    newTreatments.splice(index, 1);
    setSelectedTreatments(newTreatments);
  }

  const onChangeTreatmentQty = (val: number, index: number) => {
    if (val > 0) {
      const newTreatments = [...selectedTreatments];
      newTreatments[index].quantity = val;
      setSelectedTreatments(newTreatments);
    }
  }

  const onClickHideCartModal = () => {
    setShowEditCartModal(null);
  }

  const onClickShowCartModal = (index: number) => {
    if (isTransactionDisabled || isLoading) return;
    setShowEditCartModal(index);
  }

  const onClickShowDiscountModal = () => {
    setShowDiscountModal(true);
  }

  const onClickHideDiscountModal = () => {
    setShowDiscountModal(false);
  }

  const onClickFinishTransaction = () => {
    setShowConfirmPaymentModal(true);
  }

  const onClickHideConfirmPaymentModal = () => {
    setShowConfirmPaymentModal(false);
  }

  const onClickCancelTransaction = () => {
    if (!selectedTransaction) return;
    dispatch(showModal({
      title: `Batalkan transaksi #${selectedTransaction?.invoice_no}`,
      content: `Apakah Anda yakin ingin membatalkan transaksi ini?`,
      buttonSubmit: 'Batalkan Transaksi',
      buttonCancel: 'Lanjutkan Transaksi',
      actionSubmit: () => {},
    }));
  }

  const calculateTreatmentPrice = () => {
    return selectedTreatments.reduce((total, cur) => {
      return total + cur.price * cur.quantity
    }, 0);
  }

  const getSelectedCartItem = () => {
    if (showEditCartModal || showEditCartModal === 0) return selectedTreatments[showEditCartModal];
    return null;
  }

  const handleKeyDown = (e: KeyboardEvent) => {
    const triggerKey = ['F2', 'F3'];
    if (triggerKey.includes(e.key)) e.preventDefault();
    switch (e.key) {
      case 'F1':
        selectTreatment.current.focus();
        break;
      case 'F2':
        onClickShowDiscountModal();
        break;
      case 'F3':
        onClickFinishAppointment();
        break;
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderDate = () => {
    if (props.transactionMode && !isCreate) {
      return (
        <React.Fragment>
          <XrLoadingPlaceholder width='40%' height='24px' isLoading={isLoading}>
            <XrTextAv tag='p' variant='body' spaceBottom={8}>
              Invoice Nomor #{selectedTransaction?.invoice_no}
            </XrTextAv>
          </XrLoadingPlaceholder>
          <XrLoadingPlaceholder width='70%' height='24px' isLoading={isLoading}>
            <XrTextAv tag='p' variant='body' spaceBottom={8}>
              Invoice Tanggal {moment(selectedTransaction?.created_at).format('DD MMMM YYYY, HH:mm')}
            </XrTextAv>
          </XrLoadingPlaceholder>
        </React.Fragment>
      );
    }
    return (
      <XrLoadingPlaceholder width='70%' height='24px' isLoading={isLoading}>
        <XrTextAv tag='p' variant='body' spaceBottom={8}>
          Janji Tanggal {moment(selectedAppointment?.visit_date).format('DD MMMM YYYY, HH:mm')}
        </XrTextAv>
      </XrLoadingPlaceholder>
    );
  }
  
  const renderTransactionHeader = () => {
    return (
      <React.Fragment>
        <XrTextAv tag='p' variant='title' spaceBottom={24}>
          <b>{isCreate ? 'Buat Transaksi Baru' : 'Detail Konsultasi Pasien'}</b>
        </XrTextAv>
        <XrLoadingPlaceholder width='85%' height='27px' isLoading={isLoading}>
          <XrTextAv tag='p' variant='title' spaceBottom={8}>
            <b>{patient?.name || ''}</b>
          </XrTextAv>
        </XrLoadingPlaceholder>
        
        <XrLoadingPlaceholder width='30%' height='24px' isLoading={isLoading}>
          <XrTextAv tag='p' variant='body' spaceBottom={8}>
            {parseGender(patient?.gender || -1)}, {parseAge(patient?.bod || '')} tahun
          </XrTextAv>
        </XrLoadingPlaceholder>
        {renderDate()}
        <XrSpacingAv height={24} />
        <XrTextAv tag='p' variant='body' spaceBottom={4}>
          Ditangani oleh
        </XrTextAv>
        <XrAvatarMv
          name={doctor?.name || ''}
          image={doctor?.avatar || ''}
          description={parseStringToSeries(doctor?.phone_number, 4) || ''}
        />
      </React.Fragment>
    )
  }

  const renderSubmitButton = () => {
    if (props.transactionMode) {
      return (
        <XrButtonAv
          size='large'
          color='primary'
          disabled={isLoading || isTransactionDisabled}
          isLoading={isLoading}
          onClick={onClickFinishTransaction}
          caption='Selesaikan Transaksi'
        />
      );
    }
    return (
      <XrButtonAv
        size='large'
        color='primary'
        disabled={isLoading}
        isLoading={isLoading}
        onClick={onClickFinishAppointment}
        caption='Selesai Konsultasi'
      />
    );
  }
  
  return (
    <PageWrapper>
      <Wrapper>
        <ModalConfirmPayment
          isCreate={isCreate}
          due={calculateTreatmentPrice() - discountValue}
          isVisible={showConfirmPaymentModal}
          actionFinishTransaction={finishTransaction}
          actionClose={onClickHideConfirmPaymentModal}
        />
        <ModalDiscount
          isVisible={showDiscountModal}
          previousDiscount={discountValue}
          actionClose={onClickHideDiscountModal}
          onChangeDiscount={onChangeDicsount}
        />
        <ModalUpdateCart
          index={showEditCartModal}
          item={getSelectedCartItem()}
          actionClose={onClickHideCartModal}
          onChangeTreatmentQty={onChangeTreatmentQty}
          actionDeleteTreatment={actionDeleteTreatment}
        />
        <div className='TabMedicalRecordWrapper__record'>
          <XrInputAv
            ref={selectTreatment}
            label='Nama tindakan'
            required={true}
            disabled={isLoading || isTransactionDisabled}
            isLoading={isLoading}
            placeholder='Cari nama tindakan'
            selectProps={{
              placeholder: "",
              options: treatmentOptions,
              value: selectedTreatment,
              onChange: onChangeSelectedTreatment
            }}
            errorMessage={fieldErrorMessage('treatments', error)}
          />
          
          <XrSpacingAv height={24} /> 

          {!props.transactionMode ? (
            <Card>
              <div className='card--header'>
                <div className='card--header--title'>
                  <XrTextAv tag='h2' spaceBottom={5} variant='title'>
                    Agenda
                  </XrTextAv>
                </div>
              </div>
              <div className="card--body">
                <XrLoadingPlaceholder width='100%' height='100px' isLoading={isLoading}>
                  <div dangerouslySetInnerHTML={{ __html: agenda() }} />
                </XrLoadingPlaceholder>
              </div>
            </Card>
          ) : null}

          {!props.transactionMode ? (
            <Card>
              <div className='card--header'>
                <div className='card--header--title'>
                  <XrTextAv tag='h2' variant='title'>
                    Rekam Medis Pasien
                  </XrTextAv>
                </div>
              </div>
              <div className="card--body">
                <XrLoadingPlaceholder width='100%' height='300px' isLoading={isLoading}>
                  <XrTextEditorMv editor={editor} />
                </XrLoadingPlaceholder>
                <p style={{ color: 'red' }}>{ fieldErrorMessage('diagnosis', error) }</p>
              </div>
            </Card>
          ) : null}
          {selectedAppointment ?  <PatientsMedicalRecord patientId={selectedAppointment.patient_id} /> : null}
        </div>
        <div className='TabMedicalRecordWrapper__transaction'>
          
          {renderTransactionHeader()}
          <XrSpacingAv height={24} />
          <XrTransactionCartMv
            items={selectedTreatments}
            onClickShowCartModal={onClickShowCartModal}
          />
          <br />
          <XrAccountingMv
            otherDiscount={discountValue}
            subtotal={calculateTreatmentPrice()}
          />
          <div className='TabMedicalRecordWrapper__transaction--action'>
            <XrButtonAv
              icon={faPercent}
              size='large'
              color='default'
              disabled={isLoading || isTransactionDisabled}
              isLoading={isLoading}
              onClick={onClickShowDiscountModal}
              caption='Diskon'
            />
            {renderSubmitButton()}
          </div>
          {/* {props.transactionMode && !isCreate ? (
            <div className='TabMedicalRecordWrapper__transaction--cancel'>
              <XrButtonAv
                icon={faTrash}
                size='large'
                color='danger'
                disabled={isLoading || isTransactionDisabled}
                isLoading={isLoading}
                onClick={onClickCancelTransaction}
                caption='Batalkan Transaksi'
              />
            </div>
          ) : null} */}
        </div>
      </Wrapper>
    </PageWrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & .TabMedicalRecordWrapper {
    &__record {
      padding: 20px;
      width: calc(100% - 400px);
    }
    &__transaction {
      min-height: 100vh;
      padding: 20px;
      max-width: 400px;
      width: 400px;
      background: #fff;
      &--action {
        display: flex;
        & > *:first-child {
          max-width: 140px;
          margin-right: 8px;
        }
      }
      &--cancel {
        margin-top: 8px;
      }
    }
  }

  .medical-record__appointment-detail {
    top: 40px;
    position: sticky;
  }
`;

export default ConsultationManagePage;