import styled from "styled-components";
import { EditorContent, Editor } from '@tiptap/react';
import { COLOR } from "../helpers/theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBold, faItalic, faList, faRedo, faUndo } from "@fortawesome/free-solid-svg-icons";

interface XrTextEditorMvProps {
  editor: Editor | null;
}

const XrTextEditorMv = (props: XrTextEditorMvProps) => {
  const { editor } = props;
  if (!editor) return <div />;
  
  return (
    <XrTextEditorMvWrapper>
      <div className='editor--buttons'>
        <button
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={editor.isActive('bold') ? 'is-active' : ''}
        >
          <FontAwesomeIcon icon={faBold} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={editor.isActive('italic') ? 'is-active' : ''}
        >
          <FontAwesomeIcon icon={faItalic} />
        </button>
        <button
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          className={editor.isActive('bulletList') ? 'is-active' : ''}
        >
          <FontAwesomeIcon icon={faList} />
        </button>
        <button onClick={() => editor.chain().focus().undo().run()}>
        <FontAwesomeIcon icon={faUndo} />
        </button>
        <button onClick={() => editor.chain().focus().redo().run()}>
          <FontAwesomeIcon icon={faRedo} />
        </button>
      </div>
      <div className='editor--content'>
        <EditorContent editor={editor} />
      </div>
    </XrTextEditorMvWrapper>
  )
}

const XrTextEditorMvWrapper = styled.div`
  border-radius: 5px;
  background-color: ${COLOR.BUTTON_FACE};
  border: 1px solid ${COLOR.BORDER};

  .editor--buttons {
    padding: 5px;
    & > * {
      width: 35px;
      height: 35px;
      margin-right: 3px;
      border-radius: 5px;
      transition: 0.15s all;
      border: 1px solid ${COLOR.BORDER};

      &.is-active {
        color: #fff;
        background-color: ${COLOR.PRIMARY};
        border: 1px solid ${COLOR.PRIMARY_DARKER}; 
      }
    }
  }

  .editor--content {
    padding: 10px;
    background: #fff;
    margin-bottom: 5px;
    border-top: 1px solid ${COLOR.BORDER};
    border-bottom: 1px solid ${COLOR.BORDER};
  }
`;

export default XrTextEditorMv;