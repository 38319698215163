import styled from 'styled-components'
import { RootState } from '../../redux/rootReducers'
import { useSelector } from 'react-redux'
import { BootstrapGrid } from '../../components/layouts/BootstrapGrid'
import { COLOR } from '../../helpers/theme'
import { parseMoney } from '../../helpers/fieldHelper'
import XrSpacingAv from '../../components/XrSpacingAv'
import XrCardAv, { XrCardFooterAv, XrCardHeaderAv } from '../../components/XrCardAv'
import XrButtonAv from '../../components/XrButtonAv'
import React, { useState } from 'react'
import XrLoadingPlaceholder from '../../components/XrLoadingPlaceholder'

const PaymentSummary = () => {
  const isLoading = useSelector((state: RootState) => state.payment.isLoading);
  const report = useSelector((state: RootState) => state.payment.report && state.payment.report.summary);

  const [showReport, setShowReport] = useState<boolean>(false);

  const getCashValue = (cashValue: number | undefined) => {
    if (cashValue === undefined) return '-'
    return parseMoney(cashValue)
  }

  const transactionProgress = (comparator: number) => {
    if (!report) return 0;
    if (report.status.total_paid_trx === 0 && report.status.total_unpaid_trx === 0) {
      return 50;
    }
    return comparator / report.total_transaction * 100;
  }

  const shouldRenderUnpaidProgressBar = () => {
    if (!report) return false;
    return !(report.status.total_paid_trx > 0 && report.status.total_unpaid_trx === 0);
  }

  const shouldRenderPaidProgressBar = () => {
    if (!report) return false;
    return !(report.status.total_paid_trx === 0 && report.status.total_unpaid_trx < 0);
  }

  const onClickToggleReport = () => {
    setShowReport(!showReport);
  }

  const renderReport = () => {
    return (
      <BootstrapGrid>
        <div className='ReportDaily__labels'>
          <p style={{ color: COLOR.SUCCESS }}>Transaksi Lunas <div className='ReportDaily__labels--paid'/></p>
          <p style={{ color: COLOR.DANGER }}>Transaksi Belum Lunas <div className='ReportDaily__labels--unpaid'/></p>
        </div>
        <XrLoadingPlaceholder width='100%' height={40} isLoading={isLoading}>
          <div className='ReportDaily__progress'>
            {shouldRenderPaidProgressBar() ? (
              <div className='ReportDaily__progress--paid' style={{ width: `${transactionProgress(report?.status.total_paid_trx || 0)}%` }}>
                <span>{report?.status.total_paid_trx}</span>
              </div>
            ): null}
            {shouldRenderUnpaidProgressBar() ? (
              <div className='ReportDaily__progress--unpaid' style={{ width: `${transactionProgress(report?.status.total_unpaid_trx || 0)}%` }}>
                <span>{report?.status.total_unpaid_trx}</span>
              </div>
            ): null}
          </div>
        </XrLoadingPlaceholder>
        <XrSpacingAv height={12} />
        <div className='row'>
          <div className='col-md-4'>
            <XrCardAv padding={0}>
              <XrCardHeaderAv title='Cash' />
              <div className='ReportDaily__item'>
                <XrLoadingPlaceholder width='100%' height={75} isLoading={isLoading}>
                  <React.Fragment>{getCashValue(report?.payment_method.cash.total_trx_price)}</React.Fragment>
                </XrLoadingPlaceholder>
              </div>
              <XrCardFooterAv title='Jumlah transaksi'>
                <XrLoadingPlaceholder width='auto' height={24} isLoading={isLoading}>
                  <React.Fragment>{report?.payment_method.cash.total_trx}</React.Fragment>
                </XrLoadingPlaceholder>
              </XrCardFooterAv>
            </XrCardAv>
          </div>
          <div className='col-md-4'>
            <XrCardAv padding={0}>
              <XrCardHeaderAv title='Transfer Bank' />
              <div className='ReportDaily__item'>
                <XrLoadingPlaceholder width='100%' height={75} isLoading={isLoading}>
                  <React.Fragment>{getCashValue(report?.payment_method.bank_transfer.total_trx_price)}</React.Fragment>
                </XrLoadingPlaceholder>
              </div>
              <XrCardFooterAv title='Jumlah transaksi'>
                <XrLoadingPlaceholder width='auto' height={24} isLoading={isLoading}>
                  <React.Fragment>{report?.payment_method.bank_transfer.total_trx}</React.Fragment>
                </XrLoadingPlaceholder>
              </XrCardFooterAv>
            </XrCardAv>
          </div>
          <div className='col-md-4'>
            <XrCardAv padding={0}>
              <XrCardHeaderAv title='EDC' />
              <div className='ReportDaily__item'>
                <XrLoadingPlaceholder width='100%' height={75} isLoading={isLoading}>
                  <React.Fragment>{getCashValue(report?.payment_method.edc.total_trx_price)}</React.Fragment>
                </XrLoadingPlaceholder>
              </div>
              <XrCardFooterAv title='Jumlah transaksi'>
                <XrLoadingPlaceholder width='auto' height={24} isLoading={isLoading}>
                  <React.Fragment>{report?.payment_method.edc.total_trx}</React.Fragment>
                </XrLoadingPlaceholder>
              </XrCardFooterAv>
            </XrCardAv>
          </div>
        </div>
        <XrSpacingAv height={16} />
        <XrCardAv padding={0}>
          <XrCardHeaderAv title='Jumlah Pendapatan Harian' />
          <div className='ReportDaily__item'>
            <XrLoadingPlaceholder width='100%' height={75} isLoading={isLoading}>
              <React.Fragment>{getCashValue(report?.total_price)}</React.Fragment>
            </XrLoadingPlaceholder>
          </div>
          <XrCardFooterAv title='Jumlah transaksi'>
            <XrLoadingPlaceholder width='auto' height={24} isLoading={isLoading}>
              <React.Fragment>{report?.total_transaction}</React.Fragment>
            </XrLoadingPlaceholder>
          </XrCardFooterAv>
        </XrCardAv>
      </BootstrapGrid>
    )
  }

  return (
    <Wrapper>
      <XrCardAv padding={0}>
        <XrCardHeaderAv title='Ulasan Transaksi'>
          <XrButtonAv
            noBlock
            size='small'
            onClick={onClickToggleReport}
            caption={showReport ? 'Sembunyikan' : 'Tampilkan'}
          />
        </XrCardHeaderAv>
        {showReport ? (
          <div style={{ padding: '12px' }}>
            <div className="card--body">{renderReport()}</div>
          </div>
        ) : null}
      </XrCardAv>
      
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & .ReportDaily {
    &__item {
      padding: 16px;
      font-size: 28px;
    }

    &__labels {
      display: flex;
      font-weight: 700;
      justify-content: space-between;

      &--paid,
      &--unpaid {
        width: 10px;
        height: 10px;
        margin-left: 3px;
        border-radius: 3px;
        display: inline-block;
      }
      &--paid {
        background-color: ${COLOR.SUCCESS};
      }
      &--unpaid {
        background-color: ${COLOR.DANGER};
      }
    }

    &__progress {
      display: flex;
      color: #fff;
      overflow: hidden;
      font-weight: bold;
      border-radius: 5px;
      margin-bottom: 12px;
      box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.08);

      &--paid,
      &--unpaid {
        padding: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      &--paid {
        background-color: ${COLOR.SUCCESS};
      }
      &--unpaid {
        background-color: ${COLOR.DANGER};
      }
    }
  }
`

export default PaymentSummary;
