import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { API_URL_AUTH } from "../../../helpers/api";
import Card from "../../../components/layouts/Card";
import XrButtonAv from "../../../components/XrButtonAv";
import parseBouncer from "../../../helpers/parseBouncer";
import PageWrapper from "../../../components/layouts/PageWrapper";
import { getUserAsyncResponse } from "../../../redux/reducers/models/authorization/types";
import { User as UserBase } from "../../../interfaces/User";
import Spinner from "../../../components/Spinner";
import XrTextAv from "../../../components/XrTextAv";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducers";
import XrAvatarMv from "../../../components/XrAvatarMv";

interface User {
  token: string;
  detail: UserBase;
}

const ChangeUserPage = () => {
  const authorizedUser = useSelector((state: RootState) => state.authorization.authorizedUser);
  
  const [isLoading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<User[]>([]);

  const retrieveUsers = async () => {
    setLoading(true);
    const bouncer = parseBouncer();
    const tokens = bouncer.tokens.filter((item, pos) => {
      return bouncer.tokens.indexOf(item) === pos || item.length < 10;
    });
    const promises: any[] = [];
    tokens.forEach(btoken => {
      const payload = {
        method: 'POST',
        headers: {
          'Content-Type': "application/json; charset=UTF-8;",
          'Authorization': `Bearer ${btoken}`,
        }
      };
      const errorResponse = {
        token: btoken,
        data: null
      };
      promises.push(fetch(API_URL_AUTH.BY_TOKEN, payload).then(response => {
        if (response.ok) {
          return response.json().then((result: getUserAsyncResponse) => {
            return {
              status: 200,
              token: btoken,
              data: result.data,
            };
          }).catch(err => {
            return errorResponse;
          })
        } else {
          return errorResponse;
        }
      }).catch(err => {
        return errorResponse;
      }));
    })

    const newUsers: User[] = [];
    const promiseResult = await Promise.all(promises);
    promiseResult.forEach((prom: any) => {
      if (prom && prom.data && prom.token) {
        newUsers.push({
          token: prom.token,
          detail: prom.data
        })
      } else {
        bouncer.tokens = bouncer.tokens.filter((item) => {
          return item === prom.token;
        });
      }
    });
    localStorage.setItem('bouncer', JSON.stringify(bouncer));
    setUsers(newUsers);
    setLoading(false);
  }

  useEffect(() => {
    retrieveUsers();
  }, []);

  const actionAuthenticateUser = (token: string) => {
    setLoading(true);
    const bouncer = parseBouncer();
    bouncer.token = token;
    localStorage.setItem('bouncer', JSON.stringify(bouncer));
    window.location.href = '/change-user'
  }

  const renderLoading = () => {
    return (
      <div className="loading">
        <Spinner size="small" />
        <span>Memuat data...</span>
      </div>
    )
  }

  const renderOptions = () => {
    return (
      <React.Fragment>
        {users.map((user, index) => (
          <Card className="hoverable" onClick={() => actionAuthenticateUser(user.token)} key={index}>
            <div className="card--body">
                <XrAvatarMv name={user.detail.name} description={user.detail.email} image={user.detail.avatar} />
            </div>
          </Card>
        ))}
        <XrButtonAv
          size='medium'
          color='primary'
          href='/new-session'
          caption='Ganti Akun'
        />
      </React.Fragment>
    );
  }

  return (
    <PageWrapper>
      <ChangeUserPageWrapper>
        <Card className="user-selector">
          <div className='card--header'>
            <div className='card--header--title'>
              <XrTextAv tag='h1' variant='title'>
                Ganti Akun
              </XrTextAv>
              <XrTextAv tag='p' variant='description'>
                <span>Pilih salah satu dari akun berikut untuk melanjutkan proses.</span>
              </XrTextAv>
            </div>
          </div>
          <div className="card--body">
            {(() => {
              if (isLoading) return renderLoading();
              return renderOptions();
            })()}
          </div>
        </Card>
      </ChangeUserPageWrapper>
    </PageWrapper>
  ); 
}

const ChangeUserPageWrapper = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & .loading {
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    & > *:first-child {
      margin-right: 10px;
    }

    & > *:last-child {
      margin-bottom: 2px;
    }
  }

  & > .user-selector {
    width: 400px;
  }

  & .avatar-container {
    display: flex;

    & > .avatar-container--detail {
      & > p {
        line-height: 135%;
      }
    }
  }

`;

export default ChangeUserPage;