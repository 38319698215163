import * as actionTypes from "./actionTypes";
import * as types from "./types";
import { action, createAsyncAction } from "typesafe-actions";
import { ErrorResponse } from "../../../interfaces/Response";
import { Transaction } from "../../../interfaces/Transaction";
import ReportDaily from "../../../interfaces/ReportDaily";

// export const setCurrentStep = (value: types.ConsultationSteps) => action(actionTypes.SET_CURRENT_STEP, { value });

export const resetState = () => action(actionTypes.RESET_STATE, {});

export const setTransaction = (value: Transaction) => action(actionTypes.SET_TRANSACTION, { value });
// export const clearSelectedAppointment = () => action(actionTypes.CLEAR_SELECTED_APPOINTMENT, {});

export const setError = (value: ErrorResponse) => action(actionTypes.SET_ERROR, { value });

export const getTransactionsAsync = createAsyncAction(
  actionTypes.GET_TRANSACTIONS_REQUEST,
  actionTypes.GET_TRANSACTIONS_SUCCESS,
  actionTypes.GET_TRANSACTIONS_FAILURE
)<types.getTransactionsAsyncRequest, types.getTransactionsAggregateResponse, ErrorResponse>();

export const selectTransactionAsync = createAsyncAction(
  actionTypes.SELECT_TRANSACTION_REQUEST,
  actionTypes.SELECT_TRANSACTION_SUCCESS,
  actionTypes.SELECT_TRANSACTION_FAILURE
)<types.selectTransactionAsyncRequest, types.selectTransactionAsyncResponse, ErrorResponse>();

export const updateTransactionAsync = createAsyncAction(
  actionTypes.UPDATE_TRANSACTION_REQUEST,
  actionTypes.UPDATE_TRANSACTION_SUCCESS,
  actionTypes.UPDATE_TRANSACTION_FAILURE
)<types.updateTransactionAsyncRequest, types.updateTransactionAsyncResponse, ErrorResponse>();

export const getTreatmentsAsync = createAsyncAction(
  actionTypes.GET_TREATMENTS_REQUEST,
  actionTypes.GET_TREATMENTS_SUCCESS,
  actionTypes.GET_TREATMENTS_FAILURE
)<types.getTreatmentAsyncRequest, types.getTreatmentAsyncResponse, ErrorResponse>();

export const setReportDaily = (value: ReportDaily) => action(actionTypes.SET_REPORT_DAILY, { value });

export const setSelectedDate = (start: Date | null, end: Date | null) => action(actionTypes.SET_SELECTED_DATE, { start, end });