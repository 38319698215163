import { faInfo, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FunctionComponent } from "react";
import styled from "styled-components";

export type NotificationBarProps = {
  children: JSX.Element;
  type: 'info' | 'error' | 'warning'
  style?: React.CSSProperties;
};

const NotificationBar: FunctionComponent<NotificationBarProps> = (props: NotificationBarProps) => {
  const renderIcon = () => {
    switch (props.type) {
      case "error":
        return <FontAwesomeIcon icon={faTimes} />
      default:
        return <FontAwesomeIcon icon={faInfo} />
    }
  }

  return (
    <NotificationBarWrapper type={props.type} style={props.style}>
      <div className="icon">
        { renderIcon() }
      </div>
      <div className="content">
        {props.children}
      </div>
    </NotificationBarWrapper>
  );
}

interface NotificationBarWrapperType {
  type: 'info' | 'error' | 'warning'
}

const NotificationBarWrapper = styled.div<NotificationBarWrapperType>`
  display: flex;
  padding: 15px;
  border-radius: 5px;
  align-items: center;
  border: 1px solid #dddddd;
  background-color: #f7f7f7;

  & > .icon {
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
    color: ${(props) => {
      switch (props.type) {
        case 'info':
          return '#1861d0';
        default:
          return '#d1d1d1';
      }
    }};
    font-size: 18px;
    margin-right: 20px;
  }
`;

export default NotificationBar;