import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { COLOR } from '../../helpers/theme';

export type PaginationProps = {
  url: string;
  totalItem: number;
  isLoading?: boolean;
  listPerPage: number;
  currentIndex: number;
  maxIndex: number;
};

const Pagination = (props: PaginationProps) => {
  const { totalItem, currentIndex, maxIndex, isLoading } = props;
  const isPageLoading = typeof props.isLoading === 'undefined' ? false : props.isLoading;

  const renderPageItems = useCallback(() => {
    if (totalItem === 0) {
      return [];
    }

    let itemLimit = 9;
    let indexComponents = [];
    let lowerLimit = 0;
    let higherLimit = 5;

    if (maxIndex <= itemLimit) {
      lowerLimit = 0;
      higherLimit = maxIndex;

      for (let i = lowerLimit; i < higherLimit; i++) {
        indexComponents.push(
          <Link
            key={`page-${i + 1}`}
            to={`${props.url}${i + 1}`}
            className={`Pagination__button ${isPageLoading ? 'disabled' : currentIndex === i + 1 ? 'active' : ''}`}
          >
            {i + 1}
          </Link>
        );
      }
    } else {
      if (currentIndex > 4 && currentIndex <= maxIndex - 4) {
        lowerLimit = currentIndex - 2;
        higherLimit = currentIndex + 2;

        indexComponents.push(
          <Link key={`page-1`} className={`Pagination__button ${isPageLoading ? 'disabled' : currentIndex === 1 ? 'active' : ''}`} to={`${props.url}1`}>
            {1}
          </Link>,
          <button key="ellipsis-lower" disabled onClick={() => {}}>
            ...
          </button>
        );

        for (let i = lowerLimit - 1; i < higherLimit; i++) {
          indexComponents.push(
            <Link 
              key={`page-${i + 1}`}
              to={`${props.url}${i + 1}`}
              className={`Pagination__button ${isPageLoading ? 'disabled' : currentIndex === i + 1 ? 'active' : ''}`}
            >
              {i + 1}
            </Link>
          );
        }

        indexComponents.push(
          <button key="ellipsis-higher" disabled={true} onClick={() => {}}>
            ...
          </button>,
          <Link
            key={`page-${maxIndex}`}
            className={`Pagination__button ${isPageLoading ? 'disabled' : currentIndex === maxIndex ? 'active' : ''}`}
            to={`${props.url}${maxIndex}`}
          >
            {maxIndex}
          </Link>
        );
      } else if ((currentIndex > 0 && currentIndex < 5) || (currentIndex > maxIndex - 5 && currentIndex <= maxIndex)) {
        lowerLimit = 0;
        higherLimit = maxIndex;
        let ellipsis = 4;
        let adjustEllipsis = 0;
        if (currentIndex === ellipsis) {
          ellipsis++;
          adjustEllipsis += 2;
        } else if (currentIndex === higherLimit - ellipsis + 1) {
          ellipsis--;
          adjustEllipsis -= 2;
        }

        for (let i = lowerLimit; i < higherLimit; i++) {
          if (i === ellipsis) {
            indexComponents.push(
              <button key={`page-${i + 1}`} className='Pagination__button' disabled onClick={() => {}}>
                ...
              </button>
            );
            i = higherLimit - ellipsis - 1 + adjustEllipsis;
          } else {
            indexComponents.push(
              <Link
                key={`page-${i + 1}`}
                className={`Pagination__button ${isPageLoading ? 'disabled' : currentIndex === i + 1 ? 'active' : ''}`}
                to={`${props.url}${i + 1}`}
              >
                {i + 1}
              </Link>
            );
          }
        }
      }
    }

    return indexComponents;
    // if isLoading is ignored, page won't load because of use callback
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxIndex, currentIndex, isLoading]);

  const renderPrevButton = () => {
    if (currentIndex === 1) {
      return (
        <button className='Pagination__button-chevron' disabled>
          <FontAwesomeIcon icon={faChevronLeft} />
        </button>  
      );
    }

    return (
      <Link
        className={`Pagination__button-chevron ${isPageLoading ? 'disabled' : ''}`}
        to={`${props.url}${currentIndex + 1}`}
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </Link>
    );
  }

  const renderNextButton = () => {
    if (currentIndex === maxIndex) {
      return (
        <button className='Pagination__button-chevron' disabled>
          <FontAwesomeIcon icon={faChevronRight} />
        </button>  
      );
    }

    return (
      <Link
        className={`Pagination__button-chevron ${isPageLoading ? 'disabled' : ''}`}
        to={`${props.url}${currentIndex + 1}`}
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </Link>
    );
  }

  if (totalItem === 0) return <div />

  return (
    <PaginationWrapper>
      {renderPrevButton()}
      {renderPageItems()}
      {renderNextButton()}
    </PaginationWrapper>
  );
};

export default Pagination;

const PaginationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;

  & .Pagination {
    &__button,
    &__button-chevron {
      width: 40px;
      height: 35px;
      min-width: 40px;
      cursor: pointer;
      font-size: 16px;
      font-weight: 600;
      margin-right: 3px;
      text-align: center;
      border-radius: 5px;
      background: none;
      transition: 0.15s all;
      padding: 5px 0px 5px 0px;
      color: #${COLOR.BUTTON_TEXT};
      border: none;

      &:last-child {
        margin-right: 0px;
      }

      &.active {
        color: #fff;
        background: ${COLOR.PRIMARY};
      }

      &:disabled,
      &.disabled {
        color: ${COLOR.TEXT_DESCRIPTIVE};
      }

      &:hover {
        color: ${COLOR.PRIMARY};
        background: ${COLOR.BUTTON_FACE_HOVER};
      }
      
      &-chevron {
        color: ${COLOR.BUTTON_TEXT};
        padding-top: 8px;
        font-size: 12px !important;
        background: none !important;
        border: none;
      }
    }
  }
`;