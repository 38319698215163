export const SET_ERROR = "@transactions/SET_ERROR";
export const RESET_STATE = "@transactions/RESET_STATE";
export const SET_TRANSACTION = "@consultations/SET_TRANSACTION";

export const GET_TRANSACTIONS_REQUEST = "@transactions/GET_APPOINTMENT_REQUEST";
export const GET_TRANSACTIONS_SUCCESS = "@transactions/GET_APPOINTMENT_SUCCESS";
export const GET_TRANSACTIONS_FAILURE = "@transactions/GET_APPOINTMENT_FAILURE";

export const UPDATE_TRANSACTION_REQUEST = "@transactions/UPDATE_TRANSACTION_REQUEST";
export const UPDATE_TRANSACTION_SUCCESS = "@transactions/UPDATE_TRANSACTION_SUCCESS";
export const UPDATE_TRANSACTION_FAILURE = "@transactions/UPDATE_TRANSACTION_FAILURE";

export const SELECT_TRANSACTION_REQUEST = "@transactions/SELECT_TRANSACTION_REQUEST";
export const SELECT_TRANSACTION_SUCCESS = "@transactions/SELECT_TRANSACTION_SUCCESS";
export const SELECT_TRANSACTION_FAILURE = "@transactions/SELECT_TRANSACTION_FAILURE";

export const GET_TREATMENTS_REQUEST = "@transactions/GET_TREATMENTS_REQUEST";
export const GET_TREATMENTS_SUCCESS = "@transactions/GET_TREATMENTS_SUCCESS";
export const GET_TREATMENTS_FAILURE = "@transactions/GET_TREATMENTS_FAILURE";

export const SET_SELECTED_DATE = "@transactions/SET_SELECTED_DATE";
export const SET_REPORT_DAILY = "@transactions/SET_REPORT_DAILY";