import React from "react";
import { Switch, Route } from "react-router-dom";

// related components
import AuthenticationLoginPage from "./authentication/AuthenticationLoginPage";
import AuthenticationForgotPasswordPage from "./authentication/AuthenticationForgotPassword";

const RoutesAuth = () => {
  return (
    <Switch>
      <Route path="/">
        <AuthenticationLoginPage />
      </Route>
      <Route path="/forgot-password">
        <AuthenticationForgotPasswordPage />
      </Route>
    </Switch>
  );
};

export default RoutesAuth;