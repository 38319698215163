export const COLOR = {
  PRIMARY: '#178fe3',
  PRIMARY_HOVER: '#107dc9',
  SECONDARY: '#47E5BC',
  SECONDARY_HOVER: '#47c9a7',
  DANGER: '#de2f59',
  DANGER_HOVER: '#cc1844',
  SUCCESS: '#1dd153',
  SUCCESS_HOVER: '#16b847',
  PRIMARY_DARKER: '#14517c',
  TEXT_DESCRIPTIVE: 'rgb(184,184,184)',
  INPUT_DISABLED: 'rgb(240,240,240)',
  BORDER: '#DDD',
  BUTTON_TEXT: '#121212',
  BUTTON_FACE: '#FAFAFA',
  BUTTON_FACE_HOVER: '#EDEDED',
  DANGER_BORDER: '#ff9090',
  DANGER_INPUT_BACKGROUND: '#FFEFEF'
}

export const LAYER = {
  MODAL: 2,
  MODAL_BACKGROUND: 3,
  MODAL_CONTENT: 4,
  SELECT_OPTIONS: 5,
}