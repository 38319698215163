import { updateAppointmentAsyncResponse } from "./types";
import * as appointmentActions from "./actions";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../interfaces/Response";
import { apiPut, API_URL_APPOINTMENT, retrieveToken } from "../../../helpers/api";

export function* func(action: ReturnType<typeof appointmentActions.updateAppointmentAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    if (action.payload.id) {
      const result: Response = yield call(apiPut, API_URL_APPOINTMENT.UPDATE_APPOINTMENT(action.payload.id), token, action.payload);
      if (result.status === 200) {
        const id = action.payload.id;
        if (id) {
          yield put(
            appointmentActions.selectAppointmentAsync.request({
              id
            })
          );
        }
        const parsed: updateAppointmentAsyncResponse = yield call(result.json.bind(result));
        yield put(appointmentActions.updateAppointmentAsync.success(parsed));
        return;
      } else {
        const parsed: ErrorResponse = yield call(result.json.bind(result));
        yield put(appointmentActions.updateAppointmentAsync.failure(parsed));
        return;
      }
    } else {
      const parsed: ErrorResponse = {
        status: 500,
        errors: {},
        message: 'Terjadi kesalahan pada server'
      }
      yield put(appointmentActions.updateAppointmentAsync.failure(parsed));  
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(appointmentActions.updateAppointmentAsync.failure(parsed));
  }
}

export default function* sagaUpdateAppointment() {
  yield takeEvery(appointmentActions.updateAppointmentAsync.request, func);
}
