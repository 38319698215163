export const RESET_STATE = "@medicalRecords/RESET_STATE";
export const SET_ERROR = "@medicalRecords/SET_ERROR";

export const GET_MEDICAL_RECORD_REQUEST = "@medicalRecords/GET_MEDICAL_RECORD_REQUEST";
export const GET_MEDICAL_RECORD_SUCCESS = "@medicalRecords/GET_MEDICAL_RECORD_SUCCESS";
export const GET_MEDICAL_RECORD_FAILURE = "@medicalRecords/GET_MEDICAL_RECORD_FAILURE";

export const UPDATE_MEDICAL_RECORD_REQUEST = "@medicalRecords/UPDATE_MEDICAL_RECORD_REQUEST";
export const UPDATE_MEDICAL_RECORD_SUCCESS = "@medicalRecords/UPDATE_MEDICAL_RECORD_SUCCESS";
export const UPDATE_MEDICAL_RECORD_FAILURE = "@medicalRecords/UPDATE_MEDICAL_RECORD_FAILURE";

export const SELECT_MEDICAL_RECORD_REQUEST = "@medicalRecords/SELECT_MEDICAL_RECORD_REQUEST";
export const SELECT_MEDICAL_RECORD_SUCCESS = "@medicalRecords/SELECT_MEDICAL_RECORD_SUCCESS";
export const SELECT_MEDICAL_RECORD_FAILURE = "@medicalRecords/SELECT_MEDICAL_RECORD_FAILURE";

export const CREATE_MEDICAL_RECORD_REQUEST = "@medicalRecords/CREATE_MEDICAL_RECORD_REQUEST";
export const CREATE_MEDICAL_RECORD_SUCCESS = "@medicalRecords/CREATE_MEDICAL_RECORD_SUCCESS";
export const CREATE_MEDICAL_RECORD_FAILURE = "@medicalRecords/CREATE_MEDICAL_RECORD_FAILURE";