import moment from "moment";
import Select from "react-select";
import styled from "styled-components";
import {Option} from '../../../interfaces/Options';
import React, { useEffect, useState } from "react";
import XrTextAv from "../../../components/XrTextAv";
import Card from "../../../components/layouts/Card";
import "react-datepicker/dist/react-datepicker.css";
import XrButtonAv from "../../../components/XrButtonAv";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducers";
import { ScheduleSearch } from "../../../interfaces/Schedule";
import { BootstrapGrid } from "../../../components/layouts/BootstrapGrid";
import { getUserAsync } from "../../../redux/reducers/models/users/users.actions";
import { InputWrapper, SelectStyle } from "../../../components/forms/FormComponents";
import { searchDoctorScheduleAsync, setCurrentStep } from "../../../redux/reducers/appointments/actions";

const AppointmentFormSchedule = () => {
  const dispatch = useDispatch();

  const users = useSelector((state: RootState) => state.user.users);

  const [userOptions, setUserOptions] = useState<Option[]>([]);
  const [selectedTime, setSelectedTime] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedDoctor, setSelectedDoctor] = useState<Option | null>();

  useEffect(() => {
    const newOptions: Option[] = [];
    newOptions.push({
      value: '',
      label: 'Tidak ada preferensi'
    });
    users.forEach(item => {
      if (item.id) {
        newOptions.push({
          value: item.id?.toString(),
          label: item.name
        });
      }
    });
    setUserOptions(newOptions);
  }, [users]);

  const populateData = () => {
    dispatch(getUserAsync.request({ page: 0 }));
  };

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actionCheckAvailability = () => {
    if (!selectedTime) {
      setErrorMessage('Tanggal pemeriksaan wajib diisi.');
      return;
    }
    const payload: ScheduleSearch = {};
    const val = moment(selectedTime);
    payload.visit_date = val.format("YYYY-MM-DD");
    payload.visit_time = val.format("H:mm");
    if (selectedDoctor && selectedDoctor.value) payload.doctor_id = parseInt(selectedDoctor.value);
    dispatch(searchDoctorScheduleAsync.request(payload));
    dispatch(setCurrentStep('doctor'));
  }

  const onChangeSelectedDoctor = (e: Option | null) => {
    setSelectedDoctor(e);
  }

  const onChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrorMessage('');
    setSelectedTime(e.target.value);
  }
  
  return (
    <AppointmentFormScheduleWrapper>
      <BootstrapGrid>
        <div className="row">
          <div className="col-lg-6">
            <Card>
              <div className='card--header'>
                <div className='card--header--title'>
                  <XrTextAv tag='h2' variant='title'>
                    Preferensi Jadwal
                  </XrTextAv>
                  <XrTextAv tag='p' variant='description'>
                    Cari dokter berdasarkan ketersediaan jadwal
                  </XrTextAv>
                </div>
              </div>
              <div className="card--body">
                <InputWrapper>
                  <div className="input--field select">
                    <label>Preferensi Dokter</label>
                    <Select
                      styles={SelectStyle}
                      options={userOptions}
                      value={selectedDoctor}
                      placeholder="Cari preferensi dokter"
                      onChange={(e: any) => onChangeSelectedDoctor(e)}
                      menuPortalTarget={document.querySelector("body")}
                    />
                  </div>
                </InputWrapper>
                <InputWrapper hasErrors={Boolean(errorMessage)}>
                  <div className="input--field">
                    <label>Tanggal Pemeriksaan <span className="required">*</span></label>
                    <input type="datetime-local" onChange={onChangeDate} />
                  </div>
                  <div className="input--errors">
                    { errorMessage }
                  </div>
                </InputWrapper>
                <XrButtonAv onClick={actionCheckAvailability} color='primary' size='medium' caption="Cek Ketersediaan" />
              </div>
            </Card>
          </div>
        </div>
      </BootstrapGrid>
    </AppointmentFormScheduleWrapper>
  );
}

const AppointmentFormScheduleWrapper = styled.div`
  & .react-datepicker__tab-loop {
    z-index: auto !important;
  }
  & .react-datepicker-popper {
    z-index: 2 !important;
  }
`;

export default AppointmentFormSchedule;