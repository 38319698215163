import moment from 'moment';
import styled from "styled-components";
import Card from "../../components/layouts/Card";
import { User } from "../../interfaces/User";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { RootState } from '../../redux/rootReducers';
import XrButtonAv from '../../components/XrButtonAv';
import { COLOR } from '../../helpers/theme';
import { useDispatch, useSelector } from "react-redux";
import PageWrapper from "../../components/layouts/PageWrapper";
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BootstrapGrid } from "../../components/layouts/BootstrapGrid";
import { InputWrapper } from "../../components/forms/FormComponents";
import { createUserAsync, selectUserAsync, setError, updateUserAsync } from "../../redux/reducers/models/users/users.actions";
import Spinner from '../../components/Spinner';
import XrTextAv from '../../components/XrTextAv';

const UsersManagePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const errors = useSelector((state: RootState) => state.user.error);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const createdUser = useSelector((state: RootState) => state.user.createdUser);
  const selectedUser = useSelector((state: RootState) => state.user.selectedUser);

  const [formTitle, setFormTitle] = useState<string>("Tambah");
  const [user, setUser] = useState<User>({
    name: '',
    nik: '',
    email: '',
    password: '',
    email_verified_at: '',
    phone_number: '',
    deleted_at: '',
    is_doctor: 0,
    created_at: moment(new Date()).format('YYYY-MM-DD'),
    updated_at: moment(new Date()).format('YYYY-MM-DD'),
  });

  const populateData = () => {
    const paths = location.pathname.split("/");
    if (paths[2] !== 'create') {
      const userId = parseInt(paths[2]);
      if (userId && !isNaN(userId)) {
        dispatch(selectUserAsync.request({ id: userId }));
        setFormTitle('Ubah');
      } else {
        history.push(`/users/create`);
      }
    }
  }

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser);
    } else {
      setUser({
        name: '',
        nik: '',
        email: '',
        password: '',
        email_verified_at: '',
        phone_number: '',
        deleted_at: '',
        is_doctor: 0,
        created_at: moment(new Date()).format('YYYY-MM-DD'),
        updated_at: moment(new Date()).format('YYYY-MM-DD'),
      });
    }
  }, [selectedUser]);

  const whichForm = () => {
    const paths = location.pathname.split("/");
    const userId = paths[2];
    if (selectedUser && userId && !isNaN(parseInt(userId))) {
      return 'existing_data';
    } else if (userId === 'create') {
      return 'new_data';
    } else {
      return 'unknown';
    }
  }

  const refreshPage = () => {
    if (whichForm() === 'existing_data') {
      populateData();   
    }
  }
 
  const clearError = (fieldName: string) => {
    if (errors) {
      dispatch(setError({
        ...errors,
        errors: {
          ...errors.errors,
          [`${fieldName}`]: []
        }
      }));
    }
  }

  const onChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('name');
    setUser({
      ...user,
      name: e.target.value
    });
  };

  const onChangeNik = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('nik')
    setUser({
      ...user,
      nik: e.target.value,
    })
  }

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('nik');
    setUser({
      ...user,
      email: e.target.value
    });
  };

  const onChangePhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('phone_number');
    setUser({
      ...user,
      phone_number: e.target.value
    });
  };

  const onPasswordNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    clearError('password');
    setUser({
      ...user,
      password: e.target.value
    });
  };

  const actionCreate = () => {
    const paths = location.pathname.split("/");
    const userId = paths[2];
    if (selectedUser && userId && !isNaN(parseInt(userId))) {
      dispatch(updateUserAsync.request(user));
    } else if (userId === 'create') {
      dispatch(createUserAsync.request(user));
    }
  }

  const fieldHasErrors = (fieldName: string) => {
    if (errors && errors.errors && errors.errors[fieldName] && errors.errors[fieldName].length > 0) {
      return true;
    }
    return false;
  }

  const fieldErrorMessage = (fieldName: string) => {
    if (errors && errors.errors && errors.errors[fieldName]) {
      const fieldErrors = errors.errors[fieldName];
      if (fieldErrors.length === 1) {
        return fieldErrors[0];
      } else {
        return (
          <ul>
            {fieldErrors.map(item => {
              return <li>{item}</li>
            })}
          </ul>
        );
      }
    }
    return "";
  }

  const renderFormSuccess = () => {
    const labelFinished = whichForm() === 'new_data' ? 'ditambahkan' : 'diubah';
    return (
      <React.Fragment>
        <div className="formSuccess">
          <h3>Pengguna <b>{ createdUser?.name }</b> berhasil { labelFinished }!</h3>
        </div>
        <div className="formSubmit">
          <Link to='/users'>
            <XrButtonAv caption="Kembali ke Daftar Pengguna" />
          </Link>
          <p>atau <Link to={`/users/${createdUser?.id}`} onClick={refreshPage} className="formSubmit--update">ubah data pengguna</Link></p>
        </div>
      </React.Fragment>
    );
  }

  const renderFormLoading = () => {
    return (
      <React.Fragment>
        <div className="formLoading">
          <Spinner size="small" />
          <div className="formLoading--caption">Memuat Data...</div>
        </div>
      </React.Fragment>
    )
  }

  const renderForm = () => {
    return (
      <React.Fragment>
        <div className="form-group">
          <InputWrapper hasErrors={fieldHasErrors('name')}>
            <div className="input--field">
              <label>Nama Pengguna<span className="required">*</span></label>
              <input
                type="text"
                disabled={isLoading}
                value={user.name}
                onChange={onChangeName}
                placeholder="Masukkan nama pengguna disini"
              />
            </div>
            <div className="input--errors">
              { fieldErrorMessage('name') }
            </div>
          </InputWrapper>
        </div>
        <div className="form-group">
          <InputWrapper hasErrors={fieldHasErrors('nik')}>
            <div className="input--field">
              <label>NIK<span className="required">*</span></label>
              <input
                type="text"
                disabled={isLoading}
                value={user.nik}
                onChange={onChangeNik}
                placeholder="Masukkan NIK sesuai KTP"
              />
            </div>
            <div className="input--errors">
              { fieldErrorMessage('nik') }
            </div>
          </InputWrapper>
          <div >
          <XrButtonAv
            size='medium'
            
            disabled={isLoading}
            isLoading={isLoading}
            onClick={actionCreate}
            caption={`Check NIK`}
          />
          </div>
        </div>
        <div className="form-group">
          <InputWrapper hasErrors={fieldHasErrors('email')}>
            <div className="input--field">
              <label>Alamat Email<span className="required">*</span></label>
              <input
                type="text"
                disabled={isLoading}
                value={user.email}
                onChange={onChangeEmail}
                placeholder="Masukkan alamat email disini"
              />
            </div>
            <div className="input--errors">
              { fieldErrorMessage('email') }
            </div>
          </InputWrapper>
        </div>
        <div className="form-group">
          <InputWrapper hasErrors={fieldHasErrors('phone_number')}>
            <div className="input--field">
              <label>Telepon<span className="required">*</span></label>
              <input
                type="text"
                disabled={isLoading}
                value={user.phone_number}
                onChange={onChangePhoneNumber}
                placeholder="Masukan telepon pengguna disini"
              />
            </div>
            <div className="input--errors">
              { fieldErrorMessage('phone_number') }
            </div>
          </InputWrapper>
        </div>
        <div className="form-group">
          <InputWrapper hasErrors={fieldHasErrors('password')}>
            <div className="input--field">
              <label>Password<span className="required">*</span></label>
              <input
                type="password"
                disabled={isLoading}
                value={user.password}
                onChange={onPasswordNumber}
                placeholder="Masukan password pengguna disini"
              />
            </div>
            <div className="input--errors">
              { fieldErrorMessage('password') }
            </div>
          </InputWrapper>
        </div>

        <div className="formSubmit">
          <XrButtonAv
            size='medium'
            color='primary'
            disabled={isLoading}
            isLoading={isLoading}
            onClick={actionCreate}
            caption={`${formTitle} Pengguna`}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <PageWrapper>
      <UsersManagePageWrapper>
        <BootstrapGrid>
          <div className="row">
            <div className="col-lg-3">
              <Card>
                <div className='card--header'>
                  <div className='card--header--title'>
                    <XrTextAv tag='h2' variant='title'>
                      {`${formTitle} Pengguna`}
                    </XrTextAv>
                  </div>
                </div>
                <div className="card--body">
                  { isLoading ? renderFormLoading() : createdUser ? renderFormSuccess() : renderForm() }
                </div>
              </Card>
              
            </div>
          </div>
        </BootstrapGrid>
      </UsersManagePageWrapper>
    </PageWrapper>
  );
}

const UsersManagePageWrapper = styled.div`
  width: 100%;
  padding: 20px;
  min-height: 100vh;

  & .formTitle {
    margin-bottom: 10px;
  }

  & .formLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    & > .formLoading--caption {
      font-size: 16px;
      margin-top: -3px;
      margin-left: 10px;
    }
  }

  & .formSuccess {
    & > h3 {
      font-size: 16px;
      font-weight: normal;
      text-align: center;
    }
  }
  
  & .formSubmit {
    margin-top: 30px;
    text-align: center;

    & > p {
      margin-top: 5px;
      & > .formSubmit--update {
        cursor: pointer;
        color: ${COLOR.SECONDARY}
      }
    }
  }
`;

export default UsersManagePage;