import moment from "moment";
import styled from "styled-components";
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { COLOR } from "../../../helpers/theme";
import XrTextAv from "../../../components/XrTextAv";
import Card from "../../../components/layouts/Card";
import { Patient } from "../../../interfaces/Patient";
import { useDispatch, useSelector } from "react-redux";
import XrButtonAv from "../../../components/XrButtonAv";
import { Schedule } from "../../../interfaces/Schedule";
import { RootState } from "../../../redux/rootReducers";
import XrTextEditorMv from "../../../components/XrTextEditorMv";
import { createAppointmentAsync, setCurrentStep } from "../../../redux/reducers/appointments/actions";

interface AppointmentFormSummaryProps {
  selectedPatient: Patient | null;
  selectedSchedule: Schedule | null;
}

const AppointmentFormSummary = (props: AppointmentFormSummaryProps) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => state.appointment.isLoading);

  const editor = useEditor({
    extensions: [
      StarterKit,
    ],
    content: '',
  });

  const actionChangePatient = () => {
    dispatch(setCurrentStep('patient'));
  }

  const actionConfirmAppointment = () => {
    if (props.selectedPatient && props.selectedPatient.id && props.selectedSchedule && editor) {
      dispatch(createAppointmentAsync.request({
        status: 1,
        agenda: editor.getHTML(),
        patient_id: props.selectedPatient.id,
        doctor_id: props.selectedSchedule.doctor_id,
        visit_date: moment(props.selectedSchedule.start_date).format("YYYY-MM-DD HH:mm:ss"),
      }))
    }
  }
  
  return (
    <AppointmentFormSummaryWrapper>
      <Card>
        <div className='card--header'>
          <div className='card--header--title'>
            <XrTextAv tag='h2' variant='title'>
              Konfirmasi Janji Bertemu
            </XrTextAv>
            <XrTextAv tag='p' variant='description'>
              Masukan agenda bertemu dan periksa kembali sebelum menyimpan janji.
            </XrTextAv>
          </div>
        </div>
        <div className="card--body">
          <Card>
            <div className='card--header'>
              <div className='card--header--title'>
                <XrTextAv tag='h2' variant='title'>
                  Agenda
                </XrTextAv>
                <XrTextAv tag='p' variant='description'>
                  Deskripsikan keluhan pasien.
                </XrTextAv>
              </div>
            </div>
            <div className="card--body">
              <XrTextEditorMv editor={editor} />
            </div>
          </Card>
          <br />
          <div>
            <XrButtonAv
              noBlock
              size='medium'
              color='primary'
              caption='Buat Janji'
              disabled={isLoading}
              isLoading={isLoading}
              onClick={actionConfirmAppointment}
            />
            &nbsp;
            <XrButtonAv
              noBlock
              size='medium'
              caption='Ganti Pasien'
              disabled={isLoading}
              isLoading={isLoading}
              onClick={actionChangePatient}
            />
          </div>
        </div>
      </Card>
    </AppointmentFormSummaryWrapper>
  );
}

const AppointmentFormSummaryWrapper = styled.div`
  & .descriptive {

    & > .descriptive--title {
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: normal;
      color: ${COLOR.TEXT_DESCRIPTIVE}
    }
    & > .descriptive--details {
      font-size: 18px;
      font-weight: normal;
    }
  }
`;

export default AppointmentFormSummary;