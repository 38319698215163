import * as actions from "./medicalRecords.actions";
import * as types from "./medicalRecords.actionTypes";
import { ActionType, createReducer } from "typesafe-actions";
import { MedicalRecordReducerType } from "./types";

export type MedicalRecordActions = ActionType<typeof actions>;

export const initialState: MedicalRecordReducerType = {
  error: null,
  medicalRecords: [],
  isLoading: false,
  createdMedicalRecord: null,
  selectedMedicalRecord: null,
  meta: {
    total: 0,
    per_page: 0,
    last_page: 0,
    current_page: 0,
  }
};

export const MedicalRecordReducer = createReducer<
  MedicalRecordReducerType,
  MedicalRecordActions
>(initialState)
  .handleAction(actions.getMedicalRecordAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.getMedicalRecordAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    medicalRecords: action.payload.data,
    meta: action.payload.meta
  }))
  .handleAction(actions.getMedicalRecordAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(actions.selectMedicalRecordAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
    createdMedicalRecord: null,
    selectedMedicalRecord: null,
  }))
  .handleAction(actions.selectMedicalRecordAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    selectedMedicalRecord: action.payload.data,
  }))
  .handleAction(actions.selectMedicalRecordAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(actions.updateMedicalRecordAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.updateMedicalRecordAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdMedicalRecord: action.payload.data,
  }))
  .handleAction(actions.updateMedicalRecordAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(actions.createMedicalRecordAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.createMedicalRecordAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    createdMedicalRecord: action.payload.data
  }))
  .handleAction(actions.createMedicalRecordAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  .handleAction(types.SET_ERROR as any, (state, action) => ({
    ...state,
    error: action.payload.value
  }))
  .handleAction(types.RESET_STATE as any, (state, action) => ({
    ...state,
    error: null,
    createdMedicalRecord: null,
    selectedMedicalRecord: null
  }))
