import { useEffect } from "react";
import parseBouncer from "../../helpers/parseBouncer";

const LogoutPage = () => {
  useEffect(() => {
    const bouncer = parseBouncer();
    bouncer.token = '';
    bouncer.tokens = [];
    localStorage.setItem('bouncer', JSON.stringify(bouncer));
    window.location.href = '/';
  }, []);

  return (<div />)
}

export default LogoutPage;