import React, { useEffect, useState } from "react";
import styled from "styled-components";
import XrButtonAv from "../../../components/XrButtonAv";
import XrModalMv from "../../../components/XrModalMv";
import XrTextAv from "../../../components/XrTextAv";
import { TreatmentBilling } from "../../../redux/reducers/consultation/types";

interface Props {
  index: number | null;
  item: TreatmentBilling | null;
  actionClose: () => void;
  actionDeleteTreatment: (index: number) => void;
  onChangeTreatmentQty: (val: number, index: number) => void;
}

const ModalUpdateCart = (props: Props) => {
  const [quantity, setQuantity] = useState<number>(props.item?.quantity || 1);

  useEffect(() => {
    setQuantity(props.item?.quantity || 1);
  }, [ props.item ])
  
  const onChangeQuantity = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = parseInt(e.target.value);
    if (!isNaN(val) && val > 0) {
      setQuantity(val);
      return;
    }
    setQuantity(0);
  }

  const onSave = () => {
    if (props.index || props.index === 0) {
      props.onChangeTreatmentQty(quantity, props.index);
      props.actionClose();
    }
  }

  const onDelete = () => {
    if (props.index || props.index === 0) {
      props.actionDeleteTreatment(props.index);
      props.actionClose();
    }
  }

  return (
    <XrModalMv
      title={props.item?.treatment_detail?.name || ''}
      description='Ubah Tindakan'
      isVisible={Boolean(props.item)} actionClose={props.actionClose}
    >
      <Wrapper>
        <XrTextAv tag='p' variant='body' lineHeightPercent={150} spaceBottom={24}>
          Ubah jumlah pada kotak isian dibawah, atau hapus tindakan dari daftar keranjang.
        </XrTextAv>

        <div className='button-wrapper'>
          <input
            type='number'
            value={quantity.toString().replace(/^0+/, '') || 0}
            className='ModalUpdateCart__qty'
            onChange={onChangeQuantity}
          />
          <XrButtonAv
            noBlock
            size='medium'
            color='primary'
            disabled={false}
            isLoading={false}
            onClick={onSave}
            caption='Simpan'
          />
          &nbsp;
          <XrButtonAv
            noBlock
            size='medium'
            color='danger'
            disabled={false}
            isLoading={false}
            onClick={onDelete}
            caption='Hapus'
          />
          &nbsp;
          <XrButtonAv
            noBlock
            size='medium'
            disabled={false}
            isLoading={false}
            onClick={props.actionClose}
            caption='Batalkan'
          />
        </div>
      </Wrapper>
    </XrModalMv>
  )
}

export default ModalUpdateCart;

const Wrapper = styled.div`
  .ModalUpdateCart__qty {
    font-size: 16px;
    max-width: 75px;
    border-radius: 5px;
    margin-right: 12px;
    text-align: center;
    padding: 8px 12px 8px 12px;
    border: 1px solid #dedede;
  }
`;