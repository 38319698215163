import * as transactionActions from "./actions";
import { updateTransactionAsyncResponse } from "./types";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../interfaces/Response";
import { apiPost, apiPut, API_URL_MEDICAL_TREATMENT, API_URL_TRANSACTION, retrieveToken } from "../../../helpers/api";
import { toast } from "react-toastify";
import { toastifyProps } from "../../../helpers/config";
import { push } from "connected-react-router";

export function* func(action: ReturnType<typeof transactionActions.updateTransactionAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    if (action.payload.id && action.payload.medical_treatment_detail) {
      const resultMedicalTreatment: Response = yield call(apiPost, API_URL_MEDICAL_TREATMENT.CREATE_MEDICAL_TREATMENT, token, { medical_treatments: action.payload.medical_treatment_detail });
      if (resultMedicalTreatment.status === 200) {
        const result: Response = yield call(apiPut, API_URL_TRANSACTION.UPDATE_TRANSACTION(action.payload.id), token, action.payload);
        if (result.status === 200) {
          const parsed: updateTransactionAsyncResponse = yield call(result.json.bind(result));
          toast(`Berhasil menyelesaikan pembayaran.`, {
            ...toastifyProps,
            type: 'success',
          });
          yield put(transactionActions.updateTransactionAsync.success(parsed));
          yield put(push('/payment'));
          return;
        } else {
          const parsed: ErrorResponse = yield call(result.json.bind(result));
          yield put(transactionActions.updateTransactionAsync.failure(parsed));
          return;
        }
      }
      const parsed: ErrorResponse = {
        status: 500,
        errors: {},
        message: 'Terjadi kesalahan pada server'
      }
      yield put(transactionActions.updateTransactionAsync.failure(parsed));  
    } else {
      const parsed: ErrorResponse = {
        status: 500,
        errors: {},
        message: 'Terjadi kesalahan pada server'
      }
      yield put(transactionActions.updateTransactionAsync.failure(parsed));  
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(transactionActions.updateTransactionAsync.failure(parsed));
  }
}

export default function* sagaUpdateTransaction() {
  yield takeEvery(transactionActions.updateTransactionAsync.request, func);
}
