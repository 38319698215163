import { faCheck, faClock, faPause, faStethoscope, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { EnumStatusAppointment } from "../../../interfaces/Enums";

interface AppointmentStatusProps {
  status: number;
  fixedWidth?: boolean;
}

const AppointmentStatus = (props: AppointmentStatusProps) => {
  const [statusDetail, setStatusDetail] = useState({ name: '', icon: faTimes });

  useEffect(() => {
    switch (props.status) {
      case EnumStatusAppointment.CREATED:
        setStatusDetail({
          name: 'Pasien belum hadir',
          icon: faPause
        });
        break;
      case EnumStatusAppointment.QUEUE:
        setStatusDetail({
          name: 'Sedang dalam antrian',
          icon: faClock
        });
        break;
      case EnumStatusAppointment.PROCESS:
        setStatusDetail({
          name: 'Pasien sedang bertemu Dokter',
          icon: faStethoscope
        });
        break;
      case EnumStatusAppointment.DONE:
        setStatusDetail({
          name: 'Pasien selesai diperiksa dokter',
          icon: faCheck
        });
        break;
      case EnumStatusAppointment.CANCEL:
        setStatusDetail({
          name: 'Pertemuan dibatalkan',
          icon: faTimes
        });
        break;
      default:
        setStatusDetail({
          name: '',
          icon: faTimes
        });
        break;
    }
  }, [props.status])

  return (
    <AppointmentStatusWrapper status={props.status} fixedWidth={props.fixedWidth}>
      <FontAwesomeIcon className='icon' icon={statusDetail.icon} />
      <span>{statusDetail.name}</span>
    </AppointmentStatusWrapper>
  )
}

const AppointmentStatusWrapper = styled.div<AppointmentStatusProps>`
  margin: 0px;
  display: flex;    
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  color: ${props => {
    switch (props.status) {
      case EnumStatusAppointment.CREATED:
        return '#000000';
      case EnumStatusAppointment.QUEUE:
        return '#FFA800';
      case EnumStatusAppointment.PROCESS:
        return '#0077ffed';
      case EnumStatusAppointment.DONE:
        return '#00ff1eed';
      case EnumStatusAppointment.CANCEL:
        return '#de2323';
    }
  }};
  max-width: ${props => props.fixedWidth ? '285px' : 'none'};
  min-width: ${props => props.fixedWidth ? '285px' : 'none'};

  & .icon {
    margin-right: 10px;
  }
`;

export default AppointmentStatus;