import * as types from "./authorization.actionTypes";
import { action, createAsyncAction } from "typesafe-actions";
import { ErrorResponse } from "../../../../interfaces/Response";
import { authenticateAsyncRequest, authenticateAsyncResponse, getUserAsyncRequest, getUserAsyncResponse } from "./types";

export const updateToken = (value: string) => action(types.SET_TOKEN, { value });
export const updateMessage = (value: string) => action(types.SET_MESSAGE, { value });
export const updateError = (value: ErrorResponse) => action(types.SET_ERROR, { value });
export const updateUser = (value: getUserAsyncResponse) => action(types.SET_USER, { value });

export const getUserAsync = createAsyncAction(
  types.GET_USER_REQUEST,
  types.GET_USER_SUCCESS,
  types.GET_USER_FAILURE
)<getUserAsyncRequest, getUserAsyncResponse, ErrorResponse>();

export const authenticateAsync = createAsyncAction(
  types.AUTHENTICATE_REQUEST,
  types.AUTHENTICATE_SUCCESS,
  types.AUTHENTICATE_FAILURE
)<authenticateAsyncRequest, authenticateAsyncResponse, ErrorResponse>();