import moment from "moment";
import styled from "styled-components";
import Card from "../../../components/layouts/Card";
import XrButtonAv from "../../../components/XrButtonAv";
import { useDispatch, useSelector } from "react-redux";
import { BootstrapGrid } from "../../../components/layouts/BootstrapGrid";
import { setCurrentStep } from "../../../redux/reducers/appointments/actions";
import { Schedule } from "../../../interfaces/Schedule";
import XrTextAv from "../../../components/XrTextAv";
import { RootState } from "../../../redux/rootReducers";
import { DataTableWrapper } from "../../../components/data/DataTable";
import XrAvatarAv from "../../../components/XrAvatarAv";
import Spinner from "../../../components/Spinner";
import XrDatePillAv from "../../../components/XrDatePillAv";

interface AppointmentFormDoctorProps {
  setSelectedSchedule: (val: Schedule) => void;
}

const AppointmentFormDoctor = (props: AppointmentFormDoctorProps) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state: RootState) => state.appointment.isLoading);
  const schedules = useSelector((state: RootState) => state.appointment.availableSchedule);

  const actionSelectDoctor = (schedule: Schedule) => {
    props.setSelectedSchedule(schedule);
    dispatch(setCurrentStep('patient'));
  }

  const actionEditSchedule = () => {
    dispatch(setCurrentStep('schedule'));
  }

  const renderDate = (startDate: moment.Moment, index: number) => {
    const prevSchedule = schedules[index - 1];
    if (prevSchedule) {
      const currDate = startDate.format('YYYY-MM-DD');
      const prevDate = moment(prevSchedule.start_date).format('YYYY-MM-DD');
      if (currDate === prevDate) return;
    }
    return (
      <XrDatePillAv date={startDate} />
    );
  }

  const renderLoading = () => {
    return (
      <tr>
        <td colSpan={5} style={{ textAlign: 'center' }}>
          <Spinner size='small' /> Mohon menunggu sedang memuat data...
        </td>
      </tr>
    );
  }

  const renderEmpty = () => {
    return (
      <tr>
        <td colSpan={5} style={{ textAlign: 'center' }}>
          Tidak ada dokter yang tersedia.
          &nbsp;&nbsp;&nbsp;
          <XrButtonAv noBlock onClick={actionEditSchedule} size='medium' caption="Ubah Jadwal" />
        </td>
      </tr>
    );
  }

  const renderSchedules = () => {
    if (isLoading) return renderLoading();
    if (schedules.length === 0) return renderEmpty();

    return schedules.map((schedule, index) => {
      const startDate = moment(schedule.start_date);
      const endDate = moment(schedule.end_date);
      return (
        <tr key={index}>
          <td>
            {renderDate(startDate, index)}
          </td>
          <td>
            { startDate.format('dddd') }
          </td>
          <td>
            { startDate.format("HH:mm") } — { endDate.format("HH:mm") }
          </td>
          <td>
            <div className='name'>
              <XrAvatarAv
                size='small'
                name={schedule.doctor_detail?.name || ''}
              />
              { schedule.doctor_detail?.name }
            </div>
          </td>
          <td>
            <XrButtonAv
              noBlock
              size='small'
              color='primary'
              caption='Pilih Dokter'
              onClick={() => actionSelectDoctor(schedule)}
            />
          </td>
        </tr>
      );
    });
  }
  
  return (
    <AppointmentFormDoctorWrapper>
      <BootstrapGrid>
        <div className="row">
          <div className="col-lg-12">
            <Card>
              <div className='card--header'>
                <div className="card--header--title">
                  <XrTextAv tag='h2' variant='title'>
                    Pilih Dokter
                  </XrTextAv>
                  <XrTextAv tag='p' variant='description'>
                    Klik nama dokter dengan jadwal yang sesuai dengan pasien.
                  </XrTextAv>
                </div>
                <div className="card--header--action">
                  <XrButtonAv
                    size='medium'
                    color='primary'
                    noBlock={true}
                    disabled={false}
                    isLoading={false}
                    onClick={actionEditSchedule}
                    caption={`Cari Jadwal Lain`}
                  />
                </div>
              </div>
              <div className="card--body">
                <DataTableWrapper>
                  <div className="tablewrapper--title">
                    Dokter Tersedia
                  </div>
                  <div className="tablewrapper--table">
                    <table>
                      <thead>
                        <tr>
                          <th>Tanggal</th>
                          <th>Hari</th>
                          <th>Waktu</th>
                          <th>Dokter</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderSchedules()}
                      </tbody>
                    </table>
                  </div>
                </DataTableWrapper>
              </div>
            </Card>
          </div>
        </div>
      </BootstrapGrid>
    </AppointmentFormDoctorWrapper>
  );
}

const AppointmentFormDoctorWrapper = styled.div`
  & .name {
    display: flex;
    align-items: center;
  }
`;

export default AppointmentFormDoctor;