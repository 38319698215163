import { createTreatmentAsyncResponse } from "./types";
import * as treatmentActions from "./treatments.actions";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../../interfaces/Response";
import { apiPost, API_URL_TREATMENT, retrieveToken } from "../../../../helpers/api";

export function* createTreatment(action: ReturnType<typeof treatmentActions.createTreatmentAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    const result: Response = yield call(apiPost, API_URL_TREATMENT.CREATE_TREATMENT, token, action.payload);
    if (result.status === 200) {
      const parsed: createTreatmentAsyncResponse = yield call(result.json.bind(result));
      yield put(treatmentActions.createTreatmentAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(treatmentActions.createTreatmentAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(treatmentActions.createTreatmentAsync.failure(parsed));
  }
}

export default function* treatmentsSagaCreateTreatment() {
  yield takeEvery(treatmentActions.createTreatmentAsync.request, createTreatment);
}
