import React, { useEffect, useState } from "react";
import XrButtonAv from "../../components/XrButtonAv";
import { RootState } from "../../redux/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import paramsToObject from "../../helpers/paramsToObject";
import Pagination from "../../components/data/Pagination";
import PageWrapper from "../../components/layouts/PageWrapper";
import { useHistory, useLocation } from "react-router-dom";
import { DataTableWrapper } from "../../components/data/DataTable";
import { BootstrapGrid } from "../../components/layouts/BootstrapGrid";
import { parseStringToSeries } from "../../helpers/fieldHelper";
import styled from "styled-components";
import XrLoadingPlaceholder from "../../components/XrLoadingPlaceholder";
import XrInputAv from "../../components/XrInputAv";
import XrAvatarAv from "../../components/XrAvatarAv";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { getUserAsync, searchUserAsync } from "../../redux/reducers/models/users/users.actions";

const UsersPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const meta = useSelector((state: RootState) => state.user.meta);
  const users = useSelector((state: RootState) => state.user.users);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);

  const [txtSearch , setTxtSearch] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const populateData = () => {
    const params = paramsToObject(location.search);
    const pageNumber = parseInt(params && params.page ? params.page : 1);
    if (pageNumber && !isNaN(pageNumber)) {
      if (txtSearch.length >= 3 ) {
        dispatch(searchUserAsync.request({ page: pageNumber, name: txtSearch }));
      } else {
        dispatch(getUserAsync.request({ page: pageNumber }));
      }
    } else {
      history.push(`/users`);
    }
  }

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, searchQuery]);

  const onChangeTxtSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(e.target.value);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchQuery(txtSearch);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [txtSearch]);

  const renderUserList = () => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
        </tr>
      );
    }

    if (users.length > 0) {
      return users.map(user => {
        return (
          <tr key={user.id}>
            <td>
              <div style={{ display: 'flex' }}>
                <XrAvatarAv name={user.name} image={user.avatar} size='small' />&nbsp;
                {user.name}
              </div>
            </td>
            <td>
              <p>{user.email}</p>
            </td>
            <td>{parseStringToSeries(user.phone_number, 4)}</td>
            <td>
              <XrButtonAv
                noBlock
                size='small'
                disabled={isLoading}
                href={`/users/${user.id}`}
                isLoading={isLoading}
                icon={faPen}
              />
            </td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td colSpan={7}>Tidak ada pengguna yang ditemukan</td>
        </tr>
      );
    }
  }

  return (
    <PageWrapper>
      <UsersPageWrapper>
        <BootstrapGrid>
          <div className="row">
            <div className="col-lg-12">
              
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <DataTableWrapper>
                <div className="tablewrapper--title">
                  <div>
                    Daftar Pengguna
                  </div>
                  <div style={{ width: '450px' }}>
                    <XrInputAv
                      type='text'
                      label='Cari Nama Pengguna'
                      inline={true}
                      required={true}
                      value={txtSearch}
                      disabled={isLoading}
                      isLoading={isLoading}
                      onChange={onChangeTxtSearch}
                    />
                  </div>
                  <div>
                    <XrButtonAv noBlock size='medium' color='primary' href="/users/create" caption="Pengguna Baru" />
                  </div>
                </div>
                <div className="tablewrapper--table">
                  <table>
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Email</th>
                        <th>Nomor Telepon</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderUserList()}
                    </tbody>
                  </table>
                </div>
                {/* <Pagination
                  isLoading={isLoading}
                  totalItem={meta.total}
                  listPerPage={meta.per_page}
                  currentIndex={meta.current_page}
                  maxIndex={meta.last_page}
                  url='users?page='
                /> */}
              </DataTableWrapper>
            </div>
            <div className="col-lg-4">
            </div>
          </div>
        </BootstrapGrid>
      </UsersPageWrapper>
    </PageWrapper>
  );
}

const UsersPageWrapper = styled.div`
  padding: 20px;
`;

export default UsersPage;