import * as types from "./medicalRecords.actionTypes";
import { ErrorResponse } from "../../../../interfaces/Response";
import { action, createAsyncAction } from "typesafe-actions";
import { createMedicalRecordAsyncRequest, createMedicalRecordAsyncResponse, getMedicalRecordAsyncRequest, getMedicalRecordAsyncResponse, selectMedicalRecordAsyncRequest, selectMedicalRecordAsyncResponse, updateMedicalRecordAsyncRequest, updateMedicalRecordAsyncResponse } from "./types";

export const resetState = () => action(types.RESET_STATE, {});

export const setError = (value: ErrorResponse) => action(types.SET_ERROR, { value });

export const getMedicalRecordAsync = createAsyncAction(
  types.GET_MEDICAL_RECORD_REQUEST,
  types.GET_MEDICAL_RECORD_SUCCESS,
  types.GET_MEDICAL_RECORD_FAILURE
)<getMedicalRecordAsyncRequest, getMedicalRecordAsyncResponse, ErrorResponse>();

export const updateMedicalRecordAsync = createAsyncAction(
  types.UPDATE_MEDICAL_RECORD_REQUEST,
  types.UPDATE_MEDICAL_RECORD_SUCCESS,
  types.UPDATE_MEDICAL_RECORD_FAILURE
)<updateMedicalRecordAsyncRequest, updateMedicalRecordAsyncResponse, ErrorResponse>();

export const createMedicalRecordAsync = createAsyncAction(
  types.CREATE_MEDICAL_RECORD_REQUEST,
  types.CREATE_MEDICAL_RECORD_SUCCESS,
  types.CREATE_MEDICAL_RECORD_FAILURE
)<createMedicalRecordAsyncRequest, createMedicalRecordAsyncResponse, ErrorResponse>();

export const selectMedicalRecordAsync = createAsyncAction(
  types.SELECT_MEDICAL_RECORD_REQUEST,
  types.SELECT_MEDICAL_RECORD_SUCCESS,
  types.SELECT_MEDICAL_RECORD_FAILURE
)<selectMedicalRecordAsyncRequest, selectMedicalRecordAsyncResponse, ErrorResponse>();