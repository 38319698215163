import * as actionTypes from "./actionTypes";
import * as types from "./types";
import { action, createAsyncAction } from "typesafe-actions";
import { ErrorResponse } from "../../../interfaces/Response";

export const setCurrentStep = (value: types.ConsultationSteps) => action(actionTypes.SET_CURRENT_STEP, { value });

export const resetState = () => action(actionTypes.RESET_STATE, {});

export const clearSelectedAppointment = () => action(actionTypes.CLEAR_SELECTED_APPOINTMENT, {});

export const setError = (value: ErrorResponse) => action(actionTypes.SET_ERROR, { value });

export const getAppointmentAsync = createAsyncAction(
  actionTypes.GET_APPOINTMENT_REQUEST,
  actionTypes.GET_APPOINTMENT_SUCCESS,
  actionTypes.GET_APPOINTMENT_FAILURE
)<types.getAppointmentAsyncRequest, types.getAppointmentAsyncResponse, ErrorResponse>();

export const finishAppointmentAsync = createAsyncAction(
  actionTypes.FINISH_APPOINTMENT_REQUEST,
  actionTypes.FINISH_APPOINTMENT_SUCCESS,
  actionTypes.FINISH_APPOINTMENT_FAILURE
)<types.finishAppointmentAsyncRequest, types.finishAppointmentAsyncResponse, ErrorResponse>();

export const createTransactionAsync = createAsyncAction(
  actionTypes.CREATE_TRANSACTION_REQUEST,
  actionTypes.CREATE_TRANSACTION_SUCCESS,
  actionTypes.CREATE_TRANSACTION_FAILURE
)<types.createTransactionAsyncRequest, types.createTransactionsyncResponse, ErrorResponse>();

export const getTreatmentAsync = createAsyncAction(
  actionTypes.GET_TREATMENT_REQUEST,
  actionTypes.GET_TREATMENT_SUCCESS,
  actionTypes.GET_TREATMENT_FAILURE
)<types.getTreatmentAsyncRequest, types.getTreatmentAsyncResponse, ErrorResponse>();

export const updateAppointmentAsync = createAsyncAction(
  actionTypes.UPDATE_APPOINTMENT_REQUEST,
  actionTypes.UPDATE_APPOINTMENT_SUCCESS,
  actionTypes.UPDATE_APPOINTMENT_FAILURE
)<types.updateAppointmentAsyncRequest, types.updateAppointmentAsyncResponse, ErrorResponse>();

export const selectAppointmentAsync = createAsyncAction(
  actionTypes.SELECT_APPOINTMENT_REQUEST,
  actionTypes.SELECT_APPOINTMENT_SUCCESS,
  actionTypes.SELECT_APPOINTMENT_FAILURE
)<types.selectAppointmentAsyncRequest, types.selectAppointmentAsyncResponse, ErrorResponse>();