/**
 * Convert object into params.
 */
 const paramsFromObject = (obj: any): any => {
  try {
    return Object.keys(obj).map(function(key) {
      return key + '=' + obj[key];
    }).join('&');
  } catch (ex) {
    return null;
  }
}

export default paramsFromObject;