import * as types from "./actionTypes";
import { Option } from '../../../interfaces/Options';
import { action, createAsyncAction } from "typesafe-actions";
import { ErrorResponse } from "../../../interfaces/Response";
import { ScheduleDayTime } from "../../../interfaces/Schedule";
import { getDoctorAsyncResponse } from "../dashboard/dashboard.types";
import { createScheduleAsyncRequest, createScheduleAsyncResponse, getDoctorAsyncRequest, getDoctorScheduleAsyncRequest, getDoctorScheduleAsyncResponse, ManageScheduleStep } from "./types";

export const resetState = () => action(types.RESET_STATE, {});
export const setError = (value: ErrorResponse) => action(types.SET_ERROR, { value });
export const setManageScheduleStep = (value: ManageScheduleStep) => action(types.SET_MANAGE_SCHEDULE_STEP , { value });
export const setSelectedYear = (value: Option | null) => action(types.SET_SELECTED_YEAR , { value });
export const setSelectedMonth = (value: Option | null) => action(types.SET_SELECTED_MONTH , { value });
export const setScheduleDaily = (value: ScheduleDayTime[]) => action(types.SET_SCHEDULE_DAILY, { value });
export const setScheduleMonthly = (value: ScheduleDayTime[]) => action(types.SET_SCHEDULE_MONTHLY , { value });

export const createScheduleAsync = createAsyncAction(
  types.CREATE_SCHEDULE_REQUEST,
  types.CREATE_SCHEDULE_SUCCESS,
  types.CREATE_SCHEDULE_FAILURE
)<createScheduleAsyncRequest, createScheduleAsyncResponse, ErrorResponse>();

export const getDoctorScheduleAsync = createAsyncAction(
  types.GET_DOCTOR_SCHEDULE_REQUEST,
  types.GET_DOCTOR_SCHEDULE_SUCCESS,
  types.GET_DOCTOR_SCHEDULE_FAILURE
)<getDoctorScheduleAsyncRequest, getDoctorScheduleAsyncResponse, ErrorResponse>();

export const getDoctorAsync = createAsyncAction(
  types.GET_DOCTOR_REQUEST,
  types.GET_DOCTOR_SUCCESS,
  types.GET_DOCTOR_FAILURE
)<getDoctorAsyncRequest, getDoctorAsyncResponse, ErrorResponse>();