import styled from "styled-components";
import { COLOR, LAYER } from "../helpers/theme";
import Select, { StylesConfig } from "react-select";
import React from "react";

type InputType = 'text' | 'date' | 'time' | 'datetime' | 'number';

interface SelectProps {
  options: any[];
  value: any,
  placeholder: any,
  onChange: (e: any) => void;
}

interface Props {
  /** input label */
  label: string;
  /** if true, display as one liner input */
  inline?: boolean;
  /** text placeholder when input is empty */
  placeholder?: string;
  /** input type, if you want to use advanced select, leave it empty */
  type?: InputType;
  /** if true, will display required asterix */
  required?: boolean;
  /** if true, input will be disabled */
  disabled?: boolean;
  /** if true, input will be disabled but with loading indicator */
  isLoading?: boolean;
  /** if set, error state and message will be visible */
  errorMessage?: string[];
  /** required if you want to use input select */
  selectProps?: SelectProps;
  /** input value from state. For advanced select, use the one inside selectProps instead */
  value?: string | number | readonly string[] | undefined;
  /** triggered when you try to change input value. For advanced select, use the one inside selectProps instead. */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const XrInputAv = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const hasErrors = () => {
    const errorMessages = props.errorMessage;
    if (typeof errorMessages === 'undefined') return false;
    if (errorMessages.length === 0) return false;
    return true;
  }
  const renderErrorMessage = () => {
    const errorMessages = props.errorMessage;
    if (typeof errorMessages === 'undefined') return;
    if (errorMessages.length === 0) return;
    
    return (
      <div className="input--errors">
        {(() => {
          if (errorMessages.length === 1) {
            return errorMessages[0];
          } else {
            return (
              <ul>
                {errorMessages.map((item, index) => {
                  return <li key={index}>{item}</li>
                })}
              </ul>
            );
          }
        })()}
      </div>
    );
  }

  const renderRequired = () => {
    if (!props.required) return;
    return (
      <span className="required">*</span>
    );
  };

  const isUsingAdvancedSelect = () => {
    if (typeof props.selectProps === 'undefined') return false;
    return true;
  }

  const renderInputType = () => {
    const selectProps = props.selectProps
    if (props.type === 'date') {
      return (
        <input
          ref={ref}
          type="date" 
          value={props.value}
          onChange={props.onChange}
          disabled={props.isLoading || props.disabled}
        />
      );
    }
    if (props.type === 'time' || typeof selectProps === 'undefined') {
      return (
        <input
          ref={ref}
          type={props.type} 
          value={props.value}
          onChange={props.onChange}
          onKeyPress={props.onKeyPress}
          placeholder={props.placeholder || ''}
          disabled={props.isLoading || props.disabled}
        />
      );
    }
    return (
      <Select
        styles={SelectStyle}
        options={selectProps.options}
        value={selectProps.value}
        isDisabled={props.isLoading || props.disabled}
        placeholder={props.placeholder || ''}
        onChange={(e: any) => selectProps.onChange(e)}
        menuPortalTarget={document.querySelector("body")}
      />
    );
  }

  return (
    <XrInputAvWrapper inline={props.inline} hasError={hasErrors()} disabled={props.disabled || props.isLoading}>
      <div className={`input--field ${isUsingAdvancedSelect() ? 'select' : ''}`}>
        <label style={{ width: isUsingAdvancedSelect() ? '85%' : '85%' }}>{props.label} {renderRequired()}</label>
        {renderInputType()}
      </div>
      {renderErrorMessage()}
    </XrInputAvWrapper>
  );
});

interface XrInputAvWrapperProps {
  inline?: boolean;
  noSpace?: boolean;
  disabled?: boolean;
  hasError?: boolean
}

const XrInputAvWrapper = styled.div<XrInputAvWrapperProps>`
  width: 100%;

  & > .input--field {
    z-index: 0;
    width: 100%;
    height: ${props => {
      if (props.inline) return '45px';
      else return '70px';
    }};;
    padding: 10px;
    display: ${props => {
      if (props.inline) return 'flex';
      else return 'block';
    }};
    margin-top: -1px;
    padding-top: 10px;
    border-radius: 5px;
    position: relative;
    background-color: ${props => {
      if (props.disabled) {
        return COLOR.INPUT_DISABLED;
      }
      else if (props.hasError) {
        return COLOR.DANGER_INPUT_BACKGROUND;
      } else {
        return '#fff';
      }
    }};
    border: 1px solid ${props => {
      if (props.hasError) {
        return COLOR.DANGER_BORDER;
      } else {
        return '#e2e2e2';
      }
    }};

    & > * {
      width: 100%;
      display: block;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
    }

    & > input {
      background-color: inherit;
    }

    & > .react-datepicker-wrapper {
      & > .react-datepicker__input-container {
        & > input {
          width: 100%;
          border: none;
        }
      }
    }

    & > label {
      z-index: 1;
      display: flex;
      font-size: 16px;
      padding-left: 1px;
      position: relative;
      align-items: center;
      font-weight: 600;
      white-space: nowrap;
      padding-bottom: ${props => {
        if (props.inline) return '0px';
        else return '10px';
      }};
      width: ${props => {
        if (props.inline) return 'auto';
        else return '100%';
      }};
      color: ${props => {
        if (props.hasError) {
          return COLOR.DANGER_BORDER;
        } else {
          return COLOR.TEXT_DESCRIPTIVE;
        }
      }};

      & > .required {
        color: red;
        margin-left: 5px;
        font-weight: 900;
        font-size: 10px;
      }

      & .label--radio {
        padding-top: 4px;
        margin-right: 5px;

        & > input { 
          margin: 0;
        }
      }
    }

    & > *:last-child {
      position: ${props => {
        if (props.inline) return 'relative';
        else return 'absolute';
      }};
      z-index: 0;
      left: 0;
      top: 0%;
      border: none;
      font-size: 16px;
      padding-top: ${props => {
        if (props.inline) return '5px';
        else return '44px';
      }};
      padding-left: 11px;
      padding-right: 10px;
      padding-bottom: 5px;
    }

    &.select {
      & > *:last-child {
        padding: 0;
      }
    }

    &.no--label {
      height: 40px;
      & > input {
        padding-top: 8px;
      }
    }
  }

  & > .input--errors {
    font-size: 16px;
    line-height: 125%;
    margin-bottom: 15px;
    color: ${COLOR.DANGER};
    display: ${props => {
      if (props.hasError) {
        return 'block';
      } else {
        return 'none';
      }
    }};

    & > ul {
      margin: 0;
      padding-left: 15px;
      font-size: inherit;
      & > li {
        font-size: inherit;
      }
    }
  }
`;

const SelectStyle: StylesConfig<{}, false> = {
  input: (provided, state) => ({
    ...provided,
    margin: 0,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#535353",
    fontWeight: "normal",
    padding: "30px 10px 20px 12px",
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#535353",
    fontWeight: "normal",
    padding: "37px 10px 20px 9px",
  }),
  placeholder: (provided, state) => ({
    ...provided,
    fontSize: "16px",
    lineHeight: "16px",
    color: "#8f8f8f",
    fontWeight: "normal",
    padding: "37px 10px 20px 9px",
  }),
  control: (provided, state) => ({
    ...provided,
    height: "67px",
    border: "none",
    borderRadius: "5px",
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    marginTop: "0px",
    padding: "0px 5px 0px 5px",
    opacity: state.isDisabled ? 0 : 1,
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    margin: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "16px",
  }),
  menu: (provided, state) => ({
    ...provided
  }),
  menuPortal: (provided) => ({
    ...provided,
    zIndex: LAYER.SELECT_OPTIONS
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    padding: 0,
    height: "100%",
  })
};


export default XrInputAv;