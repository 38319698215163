import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../components/layouts/Card";
import { RootState } from "../../../redux/rootReducers";
import Pagination from "../../../components/data/Pagination";
import { DataTableWrapper } from "../../../components/data/DataTable";
import XrTextAv from "../../../components/XrTextAv";
import XrAvatarAv from "../../../components/XrAvatarAv";
import XrLoadingPlaceholder from "../../../components/XrLoadingPlaceholder";
import XrButtonAv from "../../../components/XrButtonAv";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { getMedicalTreatmentAsync, setMedicalRecord } from "../../../redux/reducers/models/patients/patients.actions";
import { MedicalRecord } from "../../../interfaces/MedicalRecord";

const PatientsManageMedicalRecord = () => {
  const dispatch = useDispatch();
  
  const meta = useSelector((state: RootState) => state.medicalRecord.meta);
  const isLoading = useSelector((state: RootState) => state.medicalRecord.isLoading);
  const selectedPatient = useSelector((state: RootState) => state.patient.selectedPatient);
  const selectedMedicalRecord = useSelector((state: RootState) => state.patient.selectedMedicalRecord);
  const medicalRecords = useSelector((state: RootState) => state.medicalRecord.medicalRecords);

  const actionGetMedicalTreatment = (record: MedicalRecord | null) => {
    if (record && record.id) {
      dispatch(setMedicalRecord(record));
      dispatch(getMedicalTreatmentAsync.request({ id: record.id }));
    }
  }

  const renderPatientMedicalRecord = () => {
    if (isLoading) {
      return (
        <tr>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
          <td>
            <XrLoadingPlaceholder width={'100%'} height={26} isLoading={isLoading}><div /></XrLoadingPlaceholder>
          </td>
        </tr>
      );
    }
    
    if (medicalRecords.length > 0) {
      return medicalRecords.map(record => {
        return (
          <tr key={record.id}>
            <td>
              <div style={{ display: 'flex' }}>
                <XrAvatarAv name={record.doctor_detail?.name || ''} size='small' />&nbsp;
                {record.doctor_detail?.name}
              </div>
            </td>
            <td>{moment(record.visited_at).format('dddd, DD MMMM YYYY, HH:mm')}</td>
            <td>
              <XrButtonAv
                noBlock
                size='small'
                // disabled={isLoadingSelection}
                // href={`/appointments/${appointment.id}`}
                onClick={() => actionGetMedicalTreatment(record)}
                // color={selectedAppointmentId === appointment.id ? 'primary' : 'default'}
                // isLoading={isLoadingSelection && selectedAppointmentId === appointment.id}
                icon={faEllipsisH}
              />
            </td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td colSpan={4}>Tidak ada data rekam jejak medis ditemukan</td>
        </tr>
      );
    }
  }

  if (selectedMedicalRecord) return <div />;

  return (
    <Card>
      <div className='card--header'>
        <div className='card--header--title'>
          <XrTextAv tag='h2' variant='title'>
            Rekam Jejak Medis
          </XrTextAv>
        </div>
      </div>
      <div className="card--body">
        <DataTableWrapper style={{ marginTop: 0 }}>
          <div className="tablewrapper--title">
            { selectedPatient?.name }
          </div>
          <div className="tablewrapper--table">
            <table>
              <thead>
                <tr>
                  <th>Dokter</th>
                  <th>Tanggal Kunjungan</th>
                  <th>Tindakan</th>
                </tr>
              </thead>
              <tbody>
                { renderPatientMedicalRecord() }
              </tbody>
            </table>
          </div>
        </DataTableWrapper>
        <Pagination
          isLoading={isLoading}
          totalItem={meta.total}
          listPerPage={meta.per_page}
          currentIndex={meta.current_page}
          maxIndex={meta.last_page}
          url={`patients/${selectedPatient?.id}?page=`}
        />
      </div>
    </Card>
  );
}

export default PatientsManageMedicalRecord;