import { searchTreatmentAsyncResponse } from "./types";
import * as treatmentActions from "./treatments.actions";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../../interfaces/Response";
import { apiGet, API_URL_TREATMENT, retrieveToken } from "../../../../helpers/api";

export function* searchTreatments(action: ReturnType<typeof treatmentActions.searchTreatmentAsync.request>) {
  try {
    const token: string = yield retrieveToken();
    const page = action.payload.page ? action.payload.page : 1;
    const name = action.payload.name ? action.payload.name : '';
    const result: Response = yield call(apiGet, API_URL_TREATMENT.SEARCH_TREATMENTS(page, name), token);
    if (result.status === 200) {
      const parsed: searchTreatmentAsyncResponse = yield call(result.json.bind(result));
      yield put(treatmentActions.searchTreatmentAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(treatmentActions.searchTreatmentAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(treatmentActions.searchTreatmentAsync.failure(parsed));
  }
}

export default function* treatmentsSagaSearchTreatment() {
  yield takeEvery(treatmentActions.searchTreatmentAsync.request, searchTreatments);
}
