import * as actions from "./actions";
import * as types from "./actionTypes";
import { ActionType, createReducer } from "typesafe-actions";
import { AppointmentReducerType } from "./types";
import moment from "moment";

export type AppointmentActions = ActionType<typeof actions>;

export const initialState: AppointmentReducerType = {
  errors: null,
  appointments: [],
  isLoading: false,
  availableSchedule: [],
  currentStep: 'schedule',
  createdAppointment: null,
  isLoadingSelection: false,
  selectedAppointment: null,
  selectedAppointmentId: null,
  showAppointmentForm: false,
  meta: {
    total: 0,
    per_page: 0,
    last_page: 0,
    current_page: 0,
  },
  selectedDate: moment(new Date()).format('YYYY-MM-DD')
};

export const AppointmentReducer = createReducer<
  AppointmentReducerType,
  AppointmentActions
>(initialState)
  /**
   * Handles get appointment
   */
  .handleAction(actions.getAppointmentAsync.request, (state) => ({
    ...state,
    errors: null,
    isLoading: true,
    appointments: []
  }))
  .handleAction(actions.getAppointmentAsync.success, (state, action) => ({
    ...state,
    errors: null,
    isLoading: false,
    meta: action.payload.meta,
    appointments: action.payload.data,
  }))
  .handleAction(actions.getAppointmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    errors: action.payload,
  }))
  /**
   * Handles select appointment
   */
  .handleAction(actions.selectAppointmentAsync.request, (state, action) => ({
    ...state,
    errors: null,
    isLoading: false,
    isLoadingSelection: true,
    availableSchedule: [],
    currentStep: 'schedule',
    createdAppointment: null,
    selectedAppointment: null, 
    selectedAppointmentId: action.payload.id
  }))
  .handleAction(actions.selectAppointmentAsync.success, (state, action) => ({
    ...state,
    errors: null,
    isLoadingSelection: false,
    selectedAppointment: action.payload.data,
  }))
  .handleAction(actions.selectAppointmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    errors: action.payload,
    isLoadingSelection: false,
  }))
  /**
   * Handles update appointment
   */
  .handleAction(actions.updateAppointmentAsync.request, (state) => ({
    ...state,
    errors: null,
    isLoadingSelection: true,
  }))
  .handleAction(actions.updateAppointmentAsync.success, (state, action) => ({
    ...state,
    errors: null,
    isLoadingSelection: false,
    createdAppointment: action.payload.data,
  }))
  .handleAction(actions.updateAppointmentAsync.failure, (state, action) => ({
    ...state,
    isLoadingSelection: false,
    errors: action.payload,
  }))
  /**
   * Handles create appointment
   */
  .handleAction(actions.createAppointmentAsync.request, (state) => ({
    ...state,
    errors: null,
    isLoading: true,
    isLoadingSelection: true,
  }))
  .handleAction(actions.createAppointmentAsync.success, (state, action) => ({
    ...state,
    errors: null,
    isLoading: false,
    isLoadingSelection: false,
    showAppointmentForm: false,
    createdAppointment: action.payload.data
  }))
  .handleAction(actions.createAppointmentAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    isLoadingSelection: false,
    errors: action.payload,
  }))
  /**
   * Handles get doctor schedule
   */
  .handleAction(actions.searchDoctorScheduleAsync.request, (state) => ({
    ...state,
    errors: null,
    isLoading: true,
    availableSchedule: []
  }))
  .handleAction(actions.searchDoctorScheduleAsync.success, (state, action) => ({
    ...state,
    errors: null,
    isLoading: false,
    isLoadingSelection: false,
    availableSchedule: action.payload.data
  }))
  .handleAction(actions.searchDoctorScheduleAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    isLoadingSelection: false,
    errors: action.payload,
  }))
  /**
   * Handles other reducer setters
   */
  .handleAction(types.SET_ERROR as any, (state, action) => ({
    ...state,
    errors: action.payload.value
  }))
  .handleAction(types.SHOW_APPOINTMENT_FORM as any, (state) => ({
    ...state,
    showAppointmentForm: true
  }))
  .handleAction(types.HIDE_APPOINTMENT_FORM as any, (state) => ({
    ...state,
    showAppointmentForm: false
  }))
  .handleAction(types.SET_SELECTED_DATE as any, (state, action) => ({
    ...state,
    selectedDate: action.payload.value
  }))
  .handleAction(types.RESET_STATE as any, () => ({
    errors: null,
    appointments: [],
    isLoading: false,
    availableSchedule: [],
    currentStep: 'schedule',
    createdAppointment: null,
    selectedAppointment: null,
    isLoadingSelection: false,
    selectedAppointmentId: null,
    showAppointmentForm: false,
    meta: {
      total: 0,
      per_page: 0,
      last_page: 0,
      current_page: 0,
    },
    selectedDate: moment(new Date()).format('YYYY-MM-DD')
  }))
  .handleAction(types.CLEAR_SELECTED_APPOINTMENT as any, (state, action) => ({
    ...state,
    errors: null,
    isLoading: false,
    availableSchedule: [],
    currentStep: 'schedule',
    createdAppointment: null,
    selectedAppointment: null,
    selectedAppointmentId: null,
  }))
  .handleAction(types.SET_CURRENT_STEP as any, (state, action) => ({
    ...state,
    currentStep: action.payload.value
  }));
