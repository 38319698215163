import { fork } from "redux-saga/effects";
import sagaGetTransactions from "./saga.getTransactions";
import sagaGetTreatment from "./saga.getTreatment";
import sagaSelectTransaction from "./saga.selectTransaction";
import sagaUpdateTransaction from "./saga.updateTransaction";

const paymentSaga = [
  fork(sagaGetTransactions),
  fork(sagaSelectTransaction),
  fork(sagaUpdateTransaction),
  fork(sagaGetTreatment),
];

export default paymentSaga;