import moment from "moment";
import { OptionsStatusAppointment } from "../interfaces/Appointment";
import { EnumPaymentMethod } from "../interfaces/Enums";
import { BASE_URL } from "./api";

export const absoluteUrl = (url: string): string => {
  return `${BASE_URL}${url}`;
}

export const parsePaymentMethod = (paymentMethod: number | undefined) => {
  switch(paymentMethod) {
    case EnumPaymentMethod.PAYMENT_METHOD_BANK_TRANSFER:
      return 'TRANSFER BANK';
    case EnumPaymentMethod.PAYMENT_METHOD_CASH:
      return 'UANG TUNAI';
    case EnumPaymentMethod.PAYMENT_METHOD_EDC:
      return 'MESIN EDC';
    default:
      return 'METODE PEMBAYARAN BELUM DIPILIH';
  }
}

export const parseAge = (date: string) => {
  var today = new Date();
  var birthDate = moment(date).toDate();
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
  }
  return age;
}

export const parseGender = (value: number) => {
  if (value === 1) return 'Laki-laki';
  else if (value === 2) return 'Perempuan';
  return '—';
}

export const parseStatusAppointment = (value: number) => {
  const val = OptionsStatusAppointment.find(i => i.value === value);
  if (val) return val.label;
  return '—';
}

export const delimiter = (val: number) => {
  const str = `${val}`; // convert to string
  const delimited = str.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return `${val < 0 ? '-' : ''}${delimited}`;
}

export const parseMoney = (val: number) => {
  const int = delimiter(parseInt(val.toString(), 10) || 0);
  const fraction = Math.abs(val % Math.floor(Math.abs(val))) || 0;
  const fractionStr =
    fraction > 0
      ? fraction
          .toFixed(2)
          .slice(1)
          .replace('.', ',')
      : '';
  if (int.charAt(0) === '-') {
    return int.replace(/^-/g, '-IDR ') + fractionStr;
  }
  return `IDR ${int}${fractionStr}`;
}

export const parseStringToSeries = (value: string | null | undefined, series: number) => {
  let finalString = '';
  if (!value) return '';
  for (let ctr = 0; ctr < value.length; ctr++) {
    if (ctr % series === 0 && ctr > 0) {
      finalString = finalString + '-';
    }
    finalString = finalString + value[ctr];
  }
  return finalString;
}