import { FunctionComponent } from "react";
import styled from "styled-components";

interface XrLoadingPlaceholderProps {
  width: number | string;
  height: number | string;
  isLoading: boolean;
  children: JSX.Element;
}

const XrLoadingPlaceholder: FunctionComponent<XrLoadingPlaceholderProps> = (props: XrLoadingPlaceholderProps) => {
  return (
    <XrLoadingPlaceholderWrapper style={{ width: props.width || '100%' }}>
      { props.isLoading ? <div className='placeholder' style={{ width: props.width, height: props.height }} /> : props.children }
    </XrLoadingPlaceholderWrapper>
  );
}

const XrLoadingPlaceholderWrapper = styled.div`
  display: block;
  
  & > .placeholder {
    border-radius: 5px;
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: darkgray;
    background: linear-gradient(to right, #eeeeee 10%, #dddddd 18%, #eeeeee 33%);
    background-size: 800px 104px;
    position: relative;
    margin-bottom: 2px;

    @keyframes placeHolderShimmer{
      0%{
        background-position: -468px 0
      }
      100%{
        background-position: 468px 0
      }
    }
  }
`;

export default XrLoadingPlaceholder;