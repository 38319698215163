import * as types from "./patients.actionTypes";
import { ErrorResponse } from "../../../../interfaces/Response";
import { action, createAsyncAction } from "typesafe-actions";
import {
  createPatientAsyncRequest, 
  createPatientAsyncResponse, 
  getPatientAsyncRequest, 
  getPatientAsyncResponse, 
  selectPatientAsyncRequest, 
  selectPatientAsyncResponse, 
  searchPatientAsyncRequest, 
  searchPatientAsyncResponse,
  getMedicalTreatmentAsyncRequest,
  getMedicalTreatmentAsyncResponse
} from "./types";
import { MedicalRecord } from "../../../../interfaces/MedicalRecord";

export const setError = (value: ErrorResponse) => action(types.SET_ERROR, { value });

export const setMedicalRecord = (value: MedicalRecord | null) => action(types.SET_MEDICAL_RECORD, { value });

export const getPatientAsync = createAsyncAction(
  types.GET_PATIENT_REQUEST,
  types.GET_PATIENT_SUCCESS,
  types.GET_PATIENT_FAILURE
)<getPatientAsyncRequest, getPatientAsyncResponse, ErrorResponse>();

export const getMedicalTreatmentAsync = createAsyncAction(
  types.GET_MEDICAL_TREATMENT_REQUEST,
  types.GET_MEDICAL_TREATMENT_SUCCESS,
  types.GET_MEDICAL_TREATMENT_FAILURE
)<getMedicalTreatmentAsyncRequest, getMedicalTreatmentAsyncResponse, ErrorResponse>();

export const createPatientAsync = createAsyncAction(
  types.CREATE_PATIENT_REQUEST,
  types.CREATE_PATIENT_SUCCESS,
  types.CREATE_PATIENT_FAILURE
)<createPatientAsyncRequest, createPatientAsyncResponse, ErrorResponse>();

export const selectPatientAsync = createAsyncAction(
  types.SELECT_PATIENT_REQUEST,
  types.SELECT_PATIENT_SUCCESS,
  types.SELECT_PATIENT_FAILURE
)<selectPatientAsyncRequest, selectPatientAsyncResponse, ErrorResponse>();

export const searchPatientAsync = createAsyncAction(
  types.SEARCH_PATIENT_REQUEST,
  types.SEARCH_PATIENT_SUCCESS,
  types.SEARCH_PATIENT_FAILURE
)<searchPatientAsyncRequest, searchPatientAsyncResponse, ErrorResponse>();