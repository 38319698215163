export const GET_USER_REQUEST = "@authorization/GET_USER_REQUEST";
export const GET_USER_SUCCESS = "@authorization/GET_USER_SUCCESS";
export const GET_USER_FAILURE = "@authorization/GET_USER_FAILURE";

export const AUTHENTICATE_REQUEST = "@authorization/AUTHENTICATE_REQUEST";
export const AUTHENTICATE_SUCCESS = "@authorization/AUTHENTICATE_SUCCESS";
export const AUTHENTICATE_FAILURE = "@authorization/AUTHENTICATE_FAILURE";

export const SET_USER = "@authorization/SET_USER";
export const SET_ERROR = "@authorization/SET_ERROR";
export const SET_TOKEN = "@authorization/SET_TOKEN";
export const SET_MESSAGE = "@authorization/SET_MESSAGE";