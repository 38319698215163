import { useState } from "react";
import styled from "styled-components";
import Card from "../../../components/layouts/Card";
import { useHistory } from "react-router-dom";
import { API_URL_AUTH } from "../../../helpers/api";
import XrButtonAv from "../../../components/XrButtonAv";
import parseBouncer from "../../../helpers/parseBouncer";
import PageWrapper from "../../../components/layouts/PageWrapper";
import { InputWrapper } from "../../../components/forms/FormComponents";
import { authenticateAsyncResponse } from "../../../redux/reducers/models/authorization/types";
import XrTextAv from "../../../components/XrTextAv";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/rootReducers";

const AddNewSessionPage = () => {
  const history = useHistory();
  
  const authorizedUser = useSelector((state: RootState) => state.authorization.authorizedUser);

  const [isLoading, setLoading] = useState<boolean>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [form, setForm] = useState<{email: string; password: string;}>({ email: '', password: ''});

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      email: e.target.value
    });
  }

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm({
      ...form,
      password: e.target.value
    });
  }

  const actionAuthenticate = () => {
    if (isLoading) return;
    setLoading(true);
    const payload: any = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({ email: form.email, password: form.password }),
    };
    fetch(API_URL_AUTH.BY_CREDENTIALS, payload).then(response => {
      if (response.ok) {
        response.json().then((result: authenticateAsyncResponse) => {
          const bouncer = parseBouncer();
          const existingToken = bouncer.tokens.findIndex(i => i === result.data.token);
          if (existingToken <= 0) {
            bouncer.tokens.push(result.data.token);
          }
          localStorage.setItem('bouncer', JSON.stringify(bouncer));
          history.push('/change-user');
        }).catch(error => {
          throw new Error('Email atau kata sandi tidak terdaftar dalam sistem. Mohon coba kombinasi lain.');
        })
      } else {
        throw new Error('Email atau kata sandi tidak terdaftar dalam sistem. Mohon coba kombinasi lain.');
      }
    }).catch(error => {
      setErrorMessage(error.toString());
    }).finally(() => {
      setLoading(false);
    });
  }

  return (
    <PageWrapper>
      <AddNewSessionPageWrapper>
        <Card className="user-selector">
          <div className='card--header'>
            <div className='card--header--title'>
              <XrTextAv tag='h1' variant='title'>
                Ganti Akun
              </XrTextAv>
              <XrTextAv tag='p' variant='description'>
                <span>Anda sedang masuk sebagai <b>{authorizedUser?.name}</b>. Silakan login ke Akun Anda.</span>
              </XrTextAv>
            </div>
          </div>
          <div className="card--body">
            <div className="form-group">
              <InputWrapper hasErrors={Boolean(errorMessage)}>
                <div className="input--field">
                  <label>Alamat Email <span className="required">*</span></label>
                  <input
                    type="text"
                    disabled={isLoading}
                    value={form.email}
                    onChange={onChangeEmail}
                    placeholder="Masukkan email"
                  />
                </div>
                <div className="input--errors">
                  { errorMessage }
                </div>
              </InputWrapper>
            </div>
            <div className="form-group">
              <InputWrapper>
                <div className="input--field">
                  <label>Kata Sandi <span className="required">*</span></label>
                  <input
                    type="password"
                    value={form.password}
                    disabled={isLoading}
                    onChange={onChangePassword}
                    placeholder="Masukkan kata sandi"
                  />
                </div>
              </InputWrapper>
            </div>
            <div className="action">
              <XrButtonAv
                size='medium'
                color='primary'
                caption="Masuk"
                disabled={isLoading}
                isLoading={isLoading}
                onClick={actionAuthenticate}
              />
              <div style={{ marginTop: '5px'}}>
                <XrButtonAv
                  size='medium'
                  caption='Saya Sudah Masuk'
                  href='/change-user'
                  disabled={isLoading}
                />
              </div>
            </div>
          </div>
        </Card>
      </AddNewSessionPageWrapper>
    </PageWrapper>
  ); 
}

const AddNewSessionPageWrapper = styled.div`
  padding: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  & > .user-selector {
    width: 400px;
  }

  & .action {
    text-align: center;
  }

`;

export default AddNewSessionPage;