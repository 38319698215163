import { History } from "history";
import { combineReducers } from "redux";
import { StateType } from "typesafe-actions";
import { connectRouter } from "connected-react-router";
import { UserReducer } from "./reducers/models/users/users.reducers";
import { PatientReducer } from "./reducers/models/patients/patients.reducers";
import { ScheduleReducer } from "./reducers/schedules/reducers";
import { TreatmentReducer } from "./reducers/models/treatments/treatments.reducers";
import { AppointmentReducer } from "./reducers/appointments/reducers";
import { AuthorizationReducer } from "./reducers/models/authorization/authorization.reducers";
import { MedicalRecordReducer } from "./reducers/models/medical-records/medicalRecords.reducers";
import { NotificationReducer } from "./reducers/notifications/reducers";
import { ConsultationReducer } from "./reducers/consultation/reducers";
import { PaymentReducer } from "./reducers/payment/reducers";

export const makeRootReducer = (history: History) => {
  return combineReducers({
    router: connectRouter(history),
    user: UserReducer,
    patient: PatientReducer,
    payment: PaymentReducer,
    schedule: ScheduleReducer,
    treatment: TreatmentReducer,
    consultation: ConsultationReducer,
    appointment: AppointmentReducer,
    notification: NotificationReducer,
    authorization: AuthorizationReducer,
    medicalRecord: MedicalRecordReducer
  });
};

export type RootState = StateType<ReturnType<typeof makeRootReducer>>;
