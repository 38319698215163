import Spinner from "../../components/Spinner";
import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/rootReducers";
import { useDispatch, useSelector } from "react-redux";
import paramsToObject from "../../helpers/paramsToObject";
import Pagination from "../../components/data/Pagination";
import PageWrapper from "../../components/layouts/PageWrapper";
import { parseStringToSeries } from "../../helpers/fieldHelper";
import { Link, useHistory, useLocation } from "react-router-dom";
import { DataTableWrapper } from "../../components/data/DataTable";
import { InputWrapper } from "../../components/forms/FormComponents";
import { BootstrapGrid } from "../../components/layouts/BootstrapGrid";
import { getUserAsync } from "../../redux/reducers/models/users/users.actions";
import styled from "styled-components";
import { resetState } from "../../redux/reducers/schedules/actions";

const SchedulesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  
  const meta = useSelector((state: RootState) => state.user.meta);
  const isLoading = useSelector((state: RootState) => state.user.isLoading);
  const users = useSelector((state: RootState) => state.user.users);

  const [txtSearch , setTxtSearch] = useState<string>('');
  const [searchQuery, setSearchQuery] = useState<string>('');

  const populateData = () => {
    const params = paramsToObject(location.search);
    const pageNumber = parseInt(params && params.page ? params.page : 1);
    if (pageNumber && !isNaN(pageNumber)) {
      dispatch(getUserAsync.request({ page: pageNumber }));
    } else {
      history.push(`/schedules`);
    }
  };

  const actionOpenScheduleDetail = () => {
    dispatch(resetState());
  }

  useEffect(() => {
    populateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, searchQuery]);

  const onChangeTxtSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTxtSearch(e.target.value);
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchQuery(txtSearch);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [txtSearch]);

  const renderUserList = () => {
    if (isLoading) {
      return (
        <tr>
          <td colSpan={6} className="tablewrapper--loading">
            <div className="tablewrapper--loading--caption">
              <Spinner size="small" />
              <span>Memuat data...</span>
            </div>
          </td>
        </tr>
      );
    }

    if (users.length > 0) {
      return users.map(user => {
        return (
          <tr key={user.id}>
            <td><Link onClick={actionOpenScheduleDetail} to={`/schedules/${user.id}`}>{user.name}</Link></td>
            <td>{user.email}</td>
            <td>{parseStringToSeries(user.phone_number, 4)}</td>
          </tr>
        );
      })
    } else {
      return (
        <tr>
          <td colSpan={7}>No user data was found</td>
        </tr>
      );
    }
  }

  return (
    <PageWrapper>
      <SchedulePageWrapper>
        <BootstrapGrid>
          <div className="row">
            <div className="col-lg-8">
              <DataTableWrapper>
                <div className="tablewrapper--title">
                  Saring Pengguna
                </div>
                <div className="tablewrapper--table">
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <InputWrapper disabled={isLoading} noSpace>
                            <div className="input--field no--label">
                              <input
                                type="text"
                                value={txtSearch}
                                disabled={isLoading}
                                onChange={onChangeTxtSearch}
                                placeholder="Masukan nama pengguna disini"
                              />
                            </div>
                          </InputWrapper>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </DataTableWrapper>
              <DataTableWrapper>
                <div className="tablewrapper--title">
                  Daftar Pengguna
                </div>
                <div className="tablewrapper--table">
                  <table>
                    <thead>
                      <tr>
                        <th>Nama</th>
                        <th>Email</th>
                        <th>Nomor Telepon</th>
                      </tr>
                    </thead>
                    <tbody>
                      {renderUserList()}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  isLoading={isLoading}
                  totalItem={meta.total}
                  listPerPage={meta.per_page}
                  currentIndex={meta.current_page}
                  maxIndex={meta.last_page}
                  url='users?page='
                />
              </DataTableWrapper>
            </div>
            <div className="col-lg-4">
            </div>
          </div>
        </BootstrapGrid>
      </SchedulePageWrapper>
    </PageWrapper>
  );
}

const SchedulePageWrapper = styled.div`
  padding: 20px;
`;

export default SchedulesPage;