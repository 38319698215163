import { getMedicalRecordAsyncResponse } from "./types";
import * as medicalRecordActions from "./medicalRecords.actions";
import { put, call, takeEvery } from "redux-saga/effects";
import { ErrorResponse } from "../../../../interfaces/Response";
import { apiGet, API_URL_MEDICAL_RECORD, retrieveToken } from "../../../../helpers/api";

export function* getMedicalRecords(action: ReturnType<typeof medicalRecordActions.getMedicalRecordAsync.request>) {
  try {
    const page = action.payload.page ? action.payload.page : 1;
    const token: string = yield retrieveToken();
    const result: Response = yield call(apiGet, API_URL_MEDICAL_RECORD.GET_MEDICAL_RECORDS(page, action.payload.patient_id), token);
    if (result.status === 200) {
      const parsed: getMedicalRecordAsyncResponse = yield call(result.json.bind(result));
      yield put(medicalRecordActions.getMedicalRecordAsync.success(parsed));
      return;
    } else {
      const parsed: ErrorResponse = yield call(result.json.bind(result));
      yield put(medicalRecordActions.getMedicalRecordAsync.failure(parsed));
      return;
    }
  } catch (err) {
    const parsed: ErrorResponse = {
      status: 500,
      errors: {},
      message: 'Terjadi kesalahan pada server'
    }
    yield put(medicalRecordActions.getMedicalRecordAsync.failure(parsed));
  }
}

export default function* medicalRecordsSagaGetRecord() {
  yield takeEvery(medicalRecordActions.getMedicalRecordAsync.request, getMedicalRecords);
}
