export const SET_CURRENT_STEP = "@consultation/SET_CURRENT_STEP";
export const RESET_STATE = "@consultations/RESET_STATE";
export const SET_ERROR = "@consultations/SET_ERROR";
export const CLEAR_SELECTED_APPOINTMENT = "@consultations/CLEAR_SELECTED_APPOINTMENT";

export const GET_APPOINTMENT_REQUEST = "@consultations/GET_APPOINTMENT_REQUEST";
export const GET_APPOINTMENT_SUCCESS = "@consultations/GET_APPOINTMENT_SUCCESS";
export const GET_APPOINTMENT_FAILURE = "@consultations/GET_APPOINTMENT_FAILURE";

export const UPDATE_APPOINTMENT_REQUEST = "@consultations/UPDATE_APPOINTMENT_REQUEST";
export const UPDATE_APPOINTMENT_SUCCESS = "@consultations/UPDATE_APPOINTMENT_SUCCESS";
export const UPDATE_APPOINTMENT_FAILURE = "@consultations/UPDATE_APPOINTMENT_FAILURE";

export const SELECT_APPOINTMENT_REQUEST = "@consultations/SELECT_APPOINTMENT_REQUEST";
export const SELECT_APPOINTMENT_SUCCESS = "@consultations/SELECT_APPOINTMENT_SUCCESS";
export const SELECT_APPOINTMENT_FAILURE = "@consultations/SELECT_APPOINTMENT_FAILURE";

export const FINISH_APPOINTMENT_REQUEST = "@consultations/FINISH_APPOINTMENT_REQUEST";
export const FINISH_APPOINTMENT_SUCCESS = "@consultations/FINISH_APPOINTMENT_SUCCESS";
export const FINISH_APPOINTMENT_FAILURE = "@consultations/FINISH_APPOINTMENT_FAILURE";

export const CREATE_TRANSACTION_REQUEST = "@consultations/CREATE_TRANSACTION_REQUEST";
export const CREATE_TRANSACTION_SUCCESS = "@consultations/CREATE_TRANSACTION_SUCCESS";
export const CREATE_TRANSACTION_FAILURE = "@consultations/CREATE_TRANSACTION_FAILURE";

export const GET_TREATMENT_REQUEST = "@consultations/GET_TREATMENT_REQUEST";
export const GET_TREATMENT_SUCCESS = "@consultations/GET_TREATMENT_SUCCESS";
export const GET_TREATMENT_FAILURE = "@consultations/GET_TREATMENT_FAILURE";