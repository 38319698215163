import styled from "styled-components";
import { parseMoney } from "../helpers/fieldHelper";
import XrTextAv from "./XrTextAv";

interface Props {
  subtotal: number;
  productDiscount?: number;
  voucherDiscount?: number;
  otherDiscount?: number;
}

const XrAccountingMv = (props: Props) => {
  const priceDetails = () => {
    const details = [
      {
        title: 'Sub Total',
        amount: props.subtotal
      },
      {
        title: 'Diskon Produk',
        amount: props.productDiscount || 0
      },
      {
        title: 'Diskon Voucher',
        amount: props.voucherDiscount || 0
      },
      {
        title: 'Diskon Lainnya',
        amount: props.otherDiscount || 0
      },
    ]
    return details.map(i => {
      return (
        <div className='XrAccountingMv__item'>
          <div className='XrAccountingMv__item-left'>
            <XrTextAv tag='p' variant='body'>
              {i.title}
            </XrTextAv>
          </div>
          <div className='XrAccountingMv__item-left'>
            <XrTextAv tag='p' variant='body'>
              {parseMoney(i.amount)}
            </XrTextAv>
          </div>
        </div>
      );
    });
  }

  const priceTotal = () => {
    return (
      <div className='XrAccountingMv__item grand-total'>
        <div className='XrAccountingMv__item-left'>
          <XrTextAv tag='p' variant='title'>
            <b>Grand Total</b>
          </XrTextAv>
        </div>
        <div className='XrAccountingMv__item-left'>
          <XrTextAv tag='p' variant='title'>
            <b>{parseMoney(props.subtotal - (props.productDiscount || 0) - (props.voucherDiscount || 0) - (props.otherDiscount || 0))}</b>
          </XrTextAv>
        </div>
      </div>
    )
  }

  return (
    <Wrapper>
      {priceDetails()}
      {priceTotal()}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  & .XrAccountingMv {
    &__item {
      display: flex;
      margin-top: 8px;
      justify-content: space-between;

      &.grand-total {
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }
  }
`;

export default XrAccountingMv;