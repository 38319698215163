import * as actionTypes from "./actionTypes";
import { action, createAsyncAction } from "typesafe-actions";
import { ErrorResponse } from "../../../interfaces/Response";
import types from "./types";

export const setCurrentStep = (value: types.AppointmentSteps) => action(actionTypes.SET_CURRENT_STEP, { value });

export const resetState = () => action(actionTypes.RESET_STATE, {});

export const clearSelectedAppointment = () => action(actionTypes.CLEAR_SELECTED_APPOINTMENT, {});

export const setError = (value: ErrorResponse) => action(actionTypes.SET_ERROR, { value });

export const setSelectedDate = (value: string) => action(actionTypes.SET_SELECTED_DATE, { value });

export const showAppointmentForm = () => action(actionTypes.SHOW_APPOINTMENT_FORM, {});

export const hideAppointmentForm = () => action(actionTypes.HIDE_APPOINTMENT_FORM, {});

export const getAppointmentAsync = createAsyncAction(
  actionTypes.GET_APPOINTMENT_REQUEST,
  actionTypes.GET_APPOINTMENT_SUCCESS,
  actionTypes.GET_APPOINTMENT_FAILURE
)<types.getAppointmentAsyncRequest, types.getAppointmentAsyncResponse, ErrorResponse>();

export const updateAppointmentAsync = createAsyncAction(
  actionTypes.UPDATE_APPOINTMENT_REQUEST,
  actionTypes.UPDATE_APPOINTMENT_SUCCESS,
  actionTypes.UPDATE_APPOINTMENT_FAILURE
)<types.updateAppointmentAsyncRequest, types.updateAppointmentAsyncResponse, ErrorResponse>();

export const createAppointmentAsync = createAsyncAction(
  actionTypes.CREATE_APPOINTMENT_REQUEST,
  actionTypes.CREATE_APPOINTMENT_SUCCESS,
  actionTypes.CREATE_APPOINTMENT_FAILURE
)<types.createAppointmentAsyncRequest, types.createAppointmentAsyncResponse, ErrorResponse>();

export const selectAppointmentAsync = createAsyncAction(
  actionTypes.SELECT_APPOINTMENT_REQUEST,
  actionTypes.SELECT_APPOINTMENT_SUCCESS,
  actionTypes.SELECT_APPOINTMENT_FAILURE
)<types.selectAppointmentAsyncRequest, types.selectAppointmentAsyncResponse, ErrorResponse>();

export const searchDoctorScheduleAsync = createAsyncAction(
  actionTypes.SEARCH_DOCTOR_SCHEDULE_REQUEST,
  actionTypes.SEARCH_DOCTOR_SCHEDULE_SUCCESS,
  actionTypes.SEARCH_DOCTOR_SCHEDULE_FAILURE
)<types.searchDoctorScheduleAsyncRequest, types.searchDoctorScheduleAsyncResponse, ErrorResponse>();