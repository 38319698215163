import { fork } from "redux-saga/effects";
import sagaCreateAppointment from "./saga.createAppointment";
import sagaGetAppointment from "./saga.getAppointments";
import sagaSearchDoctorSchedule from "./saga.searchDoctorSchedule";
import sagaSelectAppointment from "./saga.selectAppointment";
import sagaUpdateAppointment from "./saga.updateAppointment";

const appointmentSaga = [
  fork(sagaGetAppointment),
  fork(sagaCreateAppointment),
  fork(sagaSelectAppointment),
  fork(sagaUpdateAppointment),
  fork(sagaSearchDoctorSchedule)
];

export default appointmentSaga;