import * as actions from "./actions";
import * as types from "./actionTypes";
import {Option} from '../../../interfaces/Options';
import { ActionType, createReducer } from "typesafe-actions";
import { ErrorResponse } from "../../../interfaces/Response";
import { Schedule, ScheduleDayTime } from "../../../interfaces/Schedule";
import { ManageScheduleStep } from "./types";
import { User } from "../../../interfaces/User";

export type ScheduleActions = ActionType<typeof actions>;

export interface ScheduleReducerType {
  /** True when processing data and data is being loaded */
  isLoading: boolean;
  /** Currently active doctor for schedule */
  selectedDoctor: User | null;
  /** Daily schedule */
  scheduleDaily: ScheduleDayTime[];
  /** Current schedule's selected year */
  selectedYear: Option | null;
  /** Current schedule's selected month */
  selectedMonth: Option | null;
  /** Current schedule according to selected month and year */
  scheduleMonthly: ScheduleDayTime[];
  /** Will only exist if user successfully created / saved recent schedule */
  managedSchedule: Schedule[];
  /** Current step */
  step: ManageScheduleStep;
  /** Errors if exist */
  error: ErrorResponse | null;
}

export const initialState: ScheduleReducerType = {
  error: null,
  isLoading: false,
  scheduleDaily: [], 
  selectedYear: null,
  selectedMonth: null,
  scheduleMonthly: [],
  selectedDoctor: null,
  managedSchedule: [],
  step: 'STEP_1_CHOOSE_MONTH',
};

export const ScheduleReducer = createReducer<
  ScheduleReducerType,
  ScheduleActions
>(initialState)
  /**
   * Reducer handler for creatae schedule async
   **/  
  .handleAction(actions.createScheduleAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.createScheduleAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    managedSchedule: action.payload.data
  }))
  .handleAction(actions.createScheduleAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Reducer handler for get doctor async
   **/  
   .handleAction(actions.getDoctorAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.getDoctorAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    selectedDoctor: action.payload.data
  }))
  .handleAction(actions.getDoctorAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Reducer handler for get doctor schedule async
   **/  
   .handleAction(actions.getDoctorScheduleAsync.request, (state) => ({
    ...state,
    error: null,
    isLoading: true,
  }))
  .handleAction(actions.getDoctorScheduleAsync.success, (state, action) => ({
    ...state,
    error: null,
    isLoading: false,
    scheduleDaily: [],
    scheduleMonthly: action.payload.data
  }))
  .handleAction(actions.getDoctorScheduleAsync.failure, (state, action) => ({
    ...state,
    isLoading: false,
    error: action.payload,
  }))
  /**
   * Other reducer handlers
   **/  
  .handleAction(types.SET_ERROR as any, (state, action) => ({
    ...state,
    error: action.payload.value
  }))
  .handleAction(types.RESET_STATE as any, (state, action) => ({
    isLoading: false,
    selectedDoctor: null,
    scheduleDaily: [],
    selectedYear: null,
    selectedMonth: null,
    scheduleMonthly: [],
    managedSchedule: [],
    step: 'STEP_1_CHOOSE_MONTH',
    error: null,
  }))
  .handleAction(types.SET_SELECTED_YEAR as any, (state, action) => ({
    ...state,
    selectedYear: action.payload.value
  }))
  .handleAction(types.SET_SELECTED_MONTH as any, (state, action) => ({
    ...state,
    selectedMonth: action.payload.value
  }))
  .handleAction(types.SET_SCHEDULE_MONTHLY as any, (state, action) => ({
    ...state,
    scheduleMonthly: action.payload.value
  }))
  .handleAction(types.SET_SCHEDULE_DAILY as any, (state, action) => ({
    ...state,
    scheduleDaily: action.payload.value
  }))
  .handleAction(types.SET_MANAGE_SCHEDULE_STEP as any, (state, action) => ({
    ...state,
    step: action.payload.value
  }))
